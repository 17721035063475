import MarkdownPreview from '@uiw/react-markdown-preview'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Collapse, Group, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/Elements'
import { useAuth } from '@/features/auth/hooks'

export const AnswerItem = ({
  item,
  handleActiveBlockId,
  activeSummaryId,
  promptsData,
  handleSearch,
}: any) => {
  const { t } = useTranslation()
  const [opened, { toggle }] = useDisclosure(false)

  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState<any>([])

  const authData = useAuth()

  const handleActiveBlock = (item: any, type: any) => {
    if (activeSummaryId === item?.id) return

    handleActiveBlockId(item, type)
  }

  const handleLiveAnswer = (prompt: any) => {
    if (loading || answers.find((el: any) => el.id === prompt.id)) return

    let partialData = ''

    const xhr = new XMLHttpRequest()

    xhr.open(
      'GET',
      `${process.env.REACT_APP_API_URL}v1/ai/ask-summary/${item?.id}?prompt_id=${prompt?.id}`,
      true
    )
    xhr.setRequestHeader('Authorization', `Bearer ${authData.oauth?.access_token}`)

    let newAnswer = ''

    setAnswers([
      ...answers,
      {
        id: prompt?.id,
        answer: newAnswer,
      },
    ])

    xhr.onprogress = function (event: any) {
      const newData = event?.currentTarget?.responseText?.substring(partialData.length)
      partialData = event?.currentTarget?.responseText

      newData.split('\n').forEach(function (item: any) {
        if (item.trim() !== '') {
          const data = item

          if (data === 'event: update') return
          if (data === 'data: <END_STREAMING_SSE>') {
            setLoading(false)
            return
          }

          newAnswer = data.slice(6)

          if (answers.find((el: any) => el.id === prompt?.id)) {
            setAnswers([
              ...answers.filter((el: any) => el.id !== prompt?.id),
              {
                id: prompt?.id,
                answer: newAnswer,
              },
            ])
          } else {
            setAnswers([
              ...answers,
              {
                id: prompt?.id,
                answer: newAnswer,
              },
            ])
          }
        }
      })
    }

    xhr.onreadystatechange = function (oEvent) {
      if (xhr.readyState === 4) {
        if (xhr.status === 422) {
          const resError =
            JSON.parse(xhr?.response)?.message ||
            'The monthly limit for request documents has been exceeded.'

          setAnswers([
            ...answers,
            {
              id: prompt?.id,
              answer: resError,
            },
          ])

          setLoading(false)
        }
      }
    }

    xhr.send()
  }

  const handleAnswer = (prompt: any) => {
    return answers.find((el: any) => el.id === prompt.id) || null
  }

  return (
    <div
      key={item?.id}
      id={`answer-block-id-${item?.id}`}
      onClick={() => handleActiveBlock(item, 'answer')}
      className={`${item?.summary_text ? 'hovered' : ''} ${
        activeSummaryId === item?.id ? 'active' : ''
      } answer-block`}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <MarkdownPreview
            source={item?.summary_text}
            style={{
              backgroundColor: 'transparent',
              color: '#000',
              fontSize: 14,
              fontFamily: 'Open Sans',
            }}
          />
        </div>
        <Button px={2} variant="transparent" color="rgba(0, 0, 0, 1)" onClick={toggle}>
          {opened ? (
            <div style={{ border: '1px #000 solid', padding: 5, borderRadius: 20 }}>
              <Icon name={'x'} />
            </div>
          ) : (
            <div style={{ border: '1px #000 solid', padding: 5, borderRadius: 20 }}>
              <Icon name={'plus'} />
            </div>
          )}
        </Button>
      </div>
      <Collapse in={opened && activeSummaryId === item?.id}>
        <ul>
          {promptsData?.map((prompt: any, idx: number) => {
            return (
              <li key={idx}>
                <div className="answer-block-promt">
                  <Text onClick={() => handleLiveAnswer(prompt)}>{prompt?.name}</Text>
                </div>

                {handleAnswer(prompt) && (
                  <div id={`html-answer-${prompt?.id}`} className={`html-summary-answer-block`}>
                    <MarkdownPreview
                      source={handleAnswer(prompt)?.answer}
                      style={{
                        backgroundColor: '#fff',
                        color: '#000',
                        fontSize: 14,
                        fontFamily: 'Open Sans',
                      }}
                    />
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

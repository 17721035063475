import React from 'react'
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Group, Space, Text } from '@mantine/core'
import { Alert, Anchor, Button } from '@/components/Elements'
import { PasswordInputController, TextInputController } from '@/components/FormElements'

interface IProps {
  onSubmit: SubmitHandler<FieldValues>
  submitError?: any
  topBar?: React.ReactElement
}

export const LoginForm: React.FC<IProps> = ({ onSubmit, submitError, topBar, ...props }) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useFormContext()

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        {topBar}

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
        />

        <Text fw={600} align={'right'}>
          <Anchor component={Link} to={'/forgot-password'}>
            {t('forgot_password')}?
          </Anchor>
        </Text>

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} loading={isSubmitting}>
            {t('login')}
          </Button>
        </Group>
      </form>
    </>
  )
}

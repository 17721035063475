import React from 'react'
import { useUsersModel } from '@/features/users/hooks'
import { ExtendedUserType } from '@/features/users/types'

export const useUsersPermissions = () => {
  const { isDeleted } = useUsersModel()

  const isEditable = (user: ExtendedUserType) => {
    return !isDeleted(user)
  }

  return {
    isEditable,
  }
}

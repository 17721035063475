import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import qs from 'qs'
import { authData } from '@/features/auth/store'
import { authApi } from '@/features/auth/store'
import { RootState } from '@/store'

const mutex = new Mutex()

const BASE_API_URL = process.env.REACT_APP_API_URL
const API_VERSION = process.env.REACT_APP_API_VERSION

const baseUrl = `${BASE_API_URL}${API_VERSION}/`

export const baseQuery = fetchBaseQuery({
  baseUrl,
  paramsSerializer: (params: object) => {
    return qs.stringify(params, { encode: false })
  },
  prepareHeaders: (headers, { getState }) => {
    headers.set('Accept', 'application/json')

    const { isLoggedIn, oauth } = (getState() as RootState).auth.data

    if (isLoggedIn && oauth) {
      const { access_token } = oauth
      headers.set('Authorization', `Bearer ${access_token}`)
    }

    return headers
  },
})

export const baseQueryRequest: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions: {
    withMutex?: boolean
  } = {
    withMutex: true,
  }
) => {
  const { withMutex } = extraOptions
  if (withMutex) {
    await mutex.waitForUnlock()
  }

  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status) {
    const { dispatch, getState } = api
    const { isLoggedIn, oauth } = (getState() as RootState).auth.data

    switch (result.error.status) {
      case 401:
        if (isLoggedIn && oauth) {
          if (!mutex.isLocked() && withMutex) {
            const release = await mutex.acquire()

            const handleError = () => {
              dispatch(authData.cleanData())
            }

            try {
              const { refresh_token } = oauth

              const refreshDispatch = dispatch(
                authApi.endpoints.refreshToken.initiate({ refresh_token })
              )

              try {
                const refreshResult = await refreshDispatch.unwrap()

                if (refreshResult) {
                  // retry the initial query
                  result = await baseQuery(args, api, extraOptions)

                  if (result.error && result.error.status === 401) {
                    handleError()
                  }
                }
              } catch (e: any) {
                handleError()
              } finally {
                refreshDispatch.unsubscribe()
              }
            } finally {
              withMutex && release()
            }
          } else {
            if (withMutex) {
              await mutex.waitForUnlock()
            }
            result = await baseQuery(args, api, extraOptions)
          }
        }
        break

      default:
        break
    }
  }

  return result
}

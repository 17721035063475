import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PASSWORD_MIN_LENGTH } from '@/consts/form'
import { PasswordFormSection } from '@/features/auth/components/FormSections'
import { useFormSubmit } from '@/hooks'
import { SCORE_STRONG } from '@/packages/password-strength-meter'

export type FormValues = {
  password: string
  password_confirmation: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const ResetPasswordForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [passScore, setPassScore] = useState(0)

  const methods = useForm<FormValues>({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(
      Yup.object({
        password: Yup.string()
          .required()
          .min(PASSWORD_MIN_LENGTH)
          .test('score', { key: 'field.error.password.npt_strong' }, (value, context) => {
            return context?.options?.context?.passScore >= SCORE_STRONG
          }),
        password_confirmation: Yup.string()
          .required()
          .oneOf([Yup.ref('password')], { key: 'field.error.password.no_match' })
          .min(PASSWORD_MIN_LENGTH),
      })
    ),
    context: { passScore },
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {submitError && (
            <Alert type={'error'} mb={'sm'}>
              {submitError?.data?.message || t('error')}
            </Alert>
          )}

          <PasswordFormSection passScore={passScore} onPassScoreChange={setPassScore} />

          <Space h={'xl'} />

          <Group mt={'xl'} position={'right'}>
            <Button type={'submit'} loading={isSubmitting}>
              {t('set_new_password')}
            </Button>
          </Group>
        </form>
      </FormProvider>
    </>
  )
}

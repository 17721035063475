import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { UserDataQuery } from '@/features/users/components/User'

export const UserDataLayout = () => {
  const { id: paramsId } = useParams()

  const userId = paramsId as string

  return (
    <>
      <UserDataQuery id={userId}>
        <Outlet />
      </UserDataQuery>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'
import { BillingAddressUpdateContainer } from './BillingAddressUpdateContainer'

interface IProps {
  disabledMode?: boolean
}

export const BillingAddressUpdate = (props: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Alert mb={'lg'} type={'info'}>
        {t('billing_info.editable-alert')}
      </Alert>

      <BillingAddressUpdateContainer {...props} />
    </>
  )
}

import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { BasicLayout } from '../BasicLayout'
import { Header } from './Header'

interface IProps {
  children?: any
  header?: boolean
  colorScheme?: boolean
}

export const PublicLayout = ({ children, header = true, colorScheme = false }: IProps) => {
  return (
    <BasicLayout header={header ? <Header colorScheme={colorScheme} /> : undefined}>
      {children ? children : <Outlet />}
    </BasicLayout>
  )
}

import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCalendar } from 'react-icons/bs'
import { Button } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useGeneralUsersQueryParams } from '@/features/users/hooks'
import { useExportUsersEntriesMutation, useUsersTotalStatsDataQuery } from '@/features/users/store'
import { useNotify } from '@/hooks'
import styles from './styles.module.scss'

type IStat = {
  label: string
  key: string
}

export const UserStats = () => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const [exportUsersEntries] = useExportUsersEntriesMutation()

  const { date_from, date_to, onDateFromChange, onDateToChange, onPageChange } =
    useGeneralUsersQueryParams()

  const queryParams = useMemo(() => {
    const from = date_from ? dayjs(date_from).format('YYYY-MM-DD') : '2023-01-01'
    const to = date_to ? dayjs(date_to).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')

    return {
      date_from: from,
      date_to: to,
    }
  }, [date_from, date_to])

  const { data, isLoading, isFetching, error, isError, isSuccess } = useUsersTotalStatsDataQuery({
    params: queryParams,
  })

  const stats: IStat[] = [
    {
      label: t('total_accounts'),
      key: 'accounts',
    },
    {
      label: t('total_documents'),
      key: 'documents',
    },
    {
      label: t('total_input_tokens'),
      key: 'tokens_input',
    },
    {
      label: t('total_output_tokens'),
      key: 'tokens_output',
    },
    {
      label: t('total_requests'),
      key: 'requests',
    },
  ]

  const onExport = async () => {
    try {
      const queryParams = {
        page: 0,
        limit: 1000000,
        date_from: dayjs(date_from).format('YYYY-MM-DD'),
        date_to: dayjs(date_to).format('YYYY-MM-DD'),
      }

      await exportUsersEntries({
        params: queryParams,
      })

      showNotification({
        type: 'success',
        message: t('success_export'),
      })
    } catch (error: any) {}
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.statsWrap}>
        {stats.map((stat) => (
          <div className={styles.stat}>
            <p className={styles.label}>{stat.label}</p>
            <p className={styles.value}>{data?.total?.[stat.key]}</p>
          </div>
        ))}
      </div>
      <div className={styles.filter}>
        <DatePickerInput
          value={date_from}
          onChange={(date: Date) => {
            onPageChange(1)
            onDateFromChange(date)
          }}
          maxDate={date_to || undefined}
          placeholder={t('from')}
          icon={<BsCalendar />}
        />
        <DatePickerInput
          value={date_to}
          minDate={date_from || undefined}
          onChange={(date: Date) => {
            onPageChange(1)
            onDateToChange(date)
          }}
          placeholder={t('to')}
          icon={<BsCalendar />}
        />
        <Button onClick={onExport}>{t('export_users_csv')}</Button>
      </div>
    </div>
  )
}

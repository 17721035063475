import React from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAppConfirmModal } from '@/features/app/hooks'
import { ExtendedUserType } from '@/features/users/types'

interface IProps {
  data: ExtendedUserType
  onRemove?: (data: ExtendedUserType) => void
}

export const RemoveControl = ({ data, onRemove }: IProps) => {
  const { t } = useTranslation()

  const { open } = useAppConfirmModal({ onConfirm: () => onRemove?.(data) })

  return (
    <Menu.Item icon={<Icon name={'trash'} />} onClick={() => open()}>
      <Text>{t('delete')}</Text>
    </Menu.Item>
  )
}

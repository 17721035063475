import * as React from 'react'
import { Drawer, DrawerProps } from '@mantine/core'
import { NAVBAR_WIDTH } from '@/consts/layout'
import useStyles from './Component.styles'
import { default as useDrawerStyles } from './Drawer.styles'
import { Header } from './Header'

interface IProps extends DrawerProps {
  children: React.ReactNode
  opened: boolean
  onClose: () => void
  indexRoutePath?: string
}

export const NavbarDrawer = ({ children, opened, onClose, indexRoutePath, ...props }: IProps) => {
  const { classes: drawerClasses } = useDrawerStyles()
  const { classes } = useStyles()

  return (
    <Drawer
      padding={0}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size={NAVBAR_WIDTH}
      classNames={drawerClasses}
      {...props}
    >
      <div className={classes.wrap}>
        <Header onNavLinkClick={onClose} indexRoutePath={indexRoutePath} />

        <div className={classes.content}>{children}</div>
      </div>
    </Drawer>
  )
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Group, Space, Stack, Tabs, Text, Title } from '@mantine/core'
import { AvatarProvider, Icon } from '@/components/Elements'
import { UserActiveBadge, UserDeletedBadge } from '@/features/users/components/Elements'
import { useUsersData, useUsersModel, useUsersStatsData } from '@/features/users/hooks'
import { useLazyUsersStatsDataQuery } from '@/features/users/store'
import { useStaticOptions } from '@/hooks'
import { FormatUtils } from '@/utils'
import useStyles from './InfoBlock.styles'

export const InfoBlock = () => {
  const [usersStatsData] = useLazyUsersStatsDataQuery()
  const [stats, setStats] = useState<any>(null)

  const { t } = useTranslation()

  const { classes } = useStyles()

  const { getGender } = useStaticOptions()

  const { user } = useUsersData({})

  const { getName, isActive, isDeleted } = useUsersModel()

  const { avatar, email, company, active } = user

  const items = [
    {
      label: <Icon name={'person'} />,
      value: company || '-',
    },
    {
      label: <Icon name={'email'} />,
      value: email || '-',
    },
  ]

  const loadStats = async () => {
    if (!user.id) return

    try {
      const userDataStats = await usersStatsData(user.id).unwrap()

      setStats(userDataStats)

      console.log(userDataStats)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadStats()
  }, [])

  const statsSets = ['month', 'total']

  const statsKeys = [
    'documents',
    'requests',
    'tokens_input',
    'tokens_output',
    'query_tab_requests',
    'query_tab_input_tokens',
    'query_tab_output_tokens',
    'summary_tab_requests',
    'summary_tab_input_tokens',
    'summary_tab_output_tokens',
    'summary_processing_input_tokens',
    'summary_processing_output_tokens',
    'overview_input_tokens',
    'overview_output_tokens',
  ]

  const Stats = () => (
    <Group w={'100%'}>
      {statsSets.map((set) => (
        <Card shadow="sm" padding="lg" radius="md" withBorder mb={'lg'}>
          <Title order={3}>{t(`${set}_usage_title`)}</Title>
          {statsKeys.map((stat) => (
            <div className={classes.infoItem}>
              <div>
                {t(`${set}_pre_label`)} {t(`usage_${stat}_label`)}:
              </div>
              <Text size={'sm'} className={'ml-2 truncate'} weight={700}>
                {stats?.[set]?.[stat]}
              </Text>
            </div>
          ))}
        </Card>
      ))}
    </Group>
  )

  return (
    <div>
      <Tabs defaultValue={'1'}>
        <Box mb={'lg'}>
          <Tabs.List>
            <Tabs.Tab value={'1'}>{t('info')}</Tabs.Tab>
          </Tabs.List>
        </Box>
        <Tabs.Panel value={`1`}>
          <Group>
            <div className={classes.block}>
              <div className={classes.avatarWrap}>
                <AvatarProvider size={124} radius={62} type={'thumb'} avatar={avatar?.data} />
              </div>

              <div className={'h-full w-full'}>
                <Stack spacing="xs" justify="flex-end">
                  {getName(user) && <Title order={3}>{getName(user)}</Title>}

                  {user && (!isActive(user) || isDeleted(user)) && (
                    <Group mt={'xs'}>
                      {!isActive(user) && <UserActiveBadge value={active} />}

                      {isDeleted(user) && <UserDeletedBadge />}
                    </Group>
                  )}

                  {items.map(({ label, value }, index) => (
                    <div key={index} className={classes.infoItem}>
                      <div>{label}</div>
                      <Text size={'sm'} className={'ml-2 truncate'}>
                        {value}
                      </Text>
                    </div>
                  ))}
                </Stack>
              </div>
            </div>
            <Stats />
          </Group>
        </Tabs.Panel>
      </Tabs>
    </div>
  )
}

import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { PublicLayout, PublicLayoutPage } from '@/components/Layouts'
import { AuthLayoutPage } from '../AuthLayoutPage'

export const AuthLayout = () => {
  return (
    <PublicLayout colorScheme>
      <PublicLayoutPage>
        <AuthLayoutPage>
          <Outlet />
        </AuthLayoutPage>
      </PublicLayoutPage>
    </PublicLayout>
  )
}

import { rootApi } from '@/store/api'
import { RTKQueryUtils, RequestUtils } from '@/utils'

export const dashboardApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadDocument: builder.mutation<any, any>({
      query: (arg) => ({
        url: `documents/${arg.get('language')}`,
        method: 'POST',
        body: arg,
      }),
    }),
    updateDocument: builder.mutation<any, any>({
      query: (arg) => ({
        url: `documents/${arg.documentId}?language_id=${arg.languageId}`,
        method: 'PUT',
      }),
    }),
    loadDocumentLangs: builder.query<any, any>({
      query: () => ({
        url: `document-languages`,
      }),
    }),
    loadDocuments: builder.query<any, any>({
      query: (arg) => ({
        url: `documents`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
    }),
    loadDocument: builder.query<any, any>({
      query: (arg) => ({
        url: `documents/${arg.id}`,
      }),
    }),
    loadDocumentOverview: builder.query<any, any>({
      query: (arg) => ({
        url: `documents/${arg.id}/overview`,
      }),
      transformResponse: (response: any) => {
        return response?.data?.[0]
      },
    }),
    loadDocumentSummary: builder.query<any, any>({
      query: (arg) => ({
        url: `documents/${arg.id}/summarize?page=${arg.page}`,
      }),
    }),
    deleteDocument: builder.mutation<any, any>({
      query: (id) => ({
        method: 'DELETE',
        url: `documents/${id}`,
      }),
    }),
    updateDocumentData: builder.mutation<any, any>({
      query: (arg) => ({
        method: 'PUT',
        url: `documents/${arg?.id}`,
        body: arg,
      }),
    }),
    loadAnswer: builder.mutation<any, any>({
      query: (arg: any) => {
        return {
          url: `ai/ask-document/${arg.id}`,
          params: RequestUtils.getMapRequestParams(arg.params),
          method: 'GET',
        }
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    loadHistory: builder.mutation<any, any>({
      query: (arg: any) => {
        return {
          url: `ai/ask-document/${arg.id}/history`,
          params: RequestUtils.getMapRequestParams(arg.params),
          method: 'GET',
        }
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    clearHistory: builder.mutation<any, any>({
      query: (arg: any) => {
        return {
          url: `ai/ask-document/${arg.id}/history`,
          params: RequestUtils.getMapRequestParams(arg.params),
          method: 'DELETE',
        }
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    viewDocument: builder.mutation<any, any>({
      query: (arg) => ({
        url: `documents/${arg.id}/view`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useUploadDocumentMutation,
  useViewDocumentMutation,
  useLoadDocumentLangsQuery,
  useLoadDocumentsQuery,
  useLoadDocumentQuery,
  useLoadDocumentOverviewQuery,
  useLoadDocumentSummaryQuery,
  useLoadAnswerMutation,
  useLoadHistoryMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useClearHistoryMutation,
  useUpdateDocumentDataMutation,
} = dashboardApi

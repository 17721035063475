import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralNavbar } from '@/features/panel/components/Layouts/Navbars'
import { NavbarContent } from './NavbarContent'

interface IProps {
  drawerOpened: boolean
  onDrawerClose: () => void
}

export const Navbar = ({ drawerOpened, onDrawerClose }: IProps) => {
  const { t } = useTranslation()

  return (
    <GeneralNavbar drawerOpened={drawerOpened} onDrawerClose={onDrawerClose}>
      <NavbarContent onNavLinkClick={onDrawerClose} />
    </GeneralNavbar>
  )
}

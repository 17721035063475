import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/consts/layout'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: HEADER_HEIGHT,
    display: 'flex',
    borderBottom:
      theme.colorScheme === 'dark'
        ? `1px solid ${theme.colors.dark[5]}`
        : `1px solid ${theme.colors.gray[2]}`,
    padding: `0 ${theme.spacing.md}`,
  },
}))

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@mantine/core'
import { BackButton, MegaTagTitle } from '@/components/Elements'
import { InvoiceDetailsContainer } from '@/features/billing/components/Invoice/Invoice'

export const Invoice = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'invoice'} />

      <Flex mb={'lg'} align={'flex-start'}>
        <BackButton />
      </Flex>

      <InvoiceDetailsContainer />
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { useAppConfirmModal } from '@/features/app/hooks'
import { useBillingSubscriptionResumeMutation } from '@/features/billing/store'
import { useNotify } from '@/hooks'

export const ResumeControl = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [resume, { isLoading }] = useBillingSubscriptionResumeMutation()

  const onConfirm = async () => {
    try {
      await resume().unwrap()

      showNotification({
        type: 'success',
        message: t('subscribed_resumed'),
      })
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  const { open } = useAppConfirmModal({ onConfirm })

  return (
    <>
      <Button size={'xs'} compact onClick={() => open()} loading={isLoading}>
        {t('resume')}
      </Button>
    </>
  )
}

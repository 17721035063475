import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppModalsManager } from '@/features/app/hooks'

interface IProps {
  onConfirm?: () => void
}

export const useAppConfirmModal = ({ onConfirm }: IProps) => {
  const { t } = useTranslation()

  const { openConfirm } = useAppModalsManager()

  const open = (params?: any) => {
    openConfirm({ onConfirm, ...params })
  }

  return {
    open,
  }
}

import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useFormSubmit } from '@/hooks'
import { GeneralSection } from './Sections'
import { validationSchema } from './validation'

type FormValues = {
  gender?: string
  name: string
  last_name: string
  email: string
  phone?: string
}

interface IProps {
  onSubmit: (values: object) => Promise<void>
  initialValues?: FormValues
  simplified?: boolean
  topBar?: React.ReactElement
}

export const ProfileForm = ({ initialValues, simplified = false, topBar, ...props }: IProps) => {
  const { t } = useTranslation()

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      last_name: '',
      email: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema as any),
    context: { simplified },
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const { phone, ...resetValues } = data

      let values = {
        ...resetValues,
      }

      if (phone) {
        values = {
          ...values,
          phone: phone ? phone.replace(/[^0-9]/g, '') : '',
        }
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          {topBar}

          <GeneralSection simplified={simplified} />

          <Space h={'xl'} />

          <Group mt={'xl'} position={'right'}>
            <Button type={'submit'} loading={isSubmitting}>
              {t('save')}
            </Button>
          </Group>
        </form>
      </FormProvider>
    </>
  )
}

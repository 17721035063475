import React, { useMemo } from 'react'
import { QueryDataShell } from '@/components/Elements'
import { useUsersDataQuery } from '@/features/users/store'
import useStyles from './Style.styles'

interface IProps {
  children: React.ReactNode
  id?: string
}

export const UserDataQuery = ({ children, id }: IProps) => {
  const { classes } = useStyles()

  if (!id) {
    return null
  }

  const { currentData, isLoading, isFetching, error, isError, isSuccess } = useUsersDataQuery(id)

  const loading = useMemo(() => {
    return isLoading || (isFetching && !currentData)
  }, [isLoading, isFetching, currentData])

  return (
    <QueryDataShell
      isLoading={loading}
      isFetching={isFetching}
      error={error}
      isError={isError}
      isSuccess={isSuccess}
      loaderProps={{
        className: classes.loaderBlock,
      }}
      overlayLoadingProps={{
        wrapClassName: classes.overlayBlock,
      }}
    >
      {children}
    </QueryDataShell>
  )
}

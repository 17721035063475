import { rootApi } from '@/store/api'
import { InvoiceModel } from '@/features/billing/types'
import { ModelTypes } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'
import { tempData } from './tempData'

const { cacher } = RTKQueryUtils

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingInvoices'] })

export const billingInvoiceApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingInvoiceList: builder.query<ModelTypes.EntriesResponse<InvoiceModel>, any>({
      queryFn: (): { data: any } => ({ data: { data: tempData } }),
      // query: (arg) => ({
      //   url: `billing/invoices`,
      //   params: RequestUtils.getMapRequestParams(arg.params),
      // }),
      providesTags: cacher.providesNestedList('BillingInvoices'),
    }),
    billingInvoiceData: builder.query<InvoiceModel, number>({
      queryFn: (id): { data: any } => ({
        data: tempData.find((item) => item.id === +id) || false,
      }),
      // query: (id) => ({
      //   url: `billing/invoices/${id}`,
      // }),
      // transformResponse: (response: any) => {
      //   return response?.data
      // },
      providesTags: cacher.cacheByIdArg('BillingInvoices'),
    }),
    billingInvoiceLatestList: builder.query<ModelTypes.EntriesResponse<InvoiceModel>, any>({
      query: (arg) => ({
        url: `billing/invoices`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
      providesTags: cacher.providesNestedList('BillingInvoices'),
    }),
  }),
  overrideExisting: false,
})

export const {
  useBillingInvoiceListQuery,
  useBillingInvoiceDataQuery,
  useBillingInvoiceLatestListQuery,
} = billingInvoiceApi

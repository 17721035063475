import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SettingsShell } from '@/features/settings/components/Layouts'
import { PATH_SETTINGS } from '@/routes/consts'
import { Profile, Security, Usage } from '../panel'

export const PanelRoot = () => {
  return (
    <Routes>
      <Route element={<SettingsShell />}>
        <Route path={PATH_SETTINGS.PROFILE} element={<Profile />} />
        <Route path={PATH_SETTINGS.SECURITY} element={<Security />} />
        <Route path={PATH_SETTINGS.USAGE} element={<Usage />} />
      </Route>

      <Route path={'*'} element={<Navigate to={PATH_SETTINGS.PROFILE} replace={true} />} />
    </Routes>
  )
}

import React from 'react'
import useStyles from './BasicLayout.styles'

interface IProps {
  children?: any
  header?: React.ReactElement
}

export const BasicLayout = ({ children, header }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      {header}

      <div className={classes.shell}>
        <div className={classes.main}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Group, Input, Textarea } from '@mantine/core'
import { Button, Icon, Modal } from '@/components/Elements'
import { usePromptsUpdateMutation } from '@/features/prompts/store'
import { useNotify } from '@/hooks'
import useStyles from './Style.styles'

export const UpdatePromptsModal = ({ prompt, refetch }: { prompt: any; refetch: any }) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [name, setName] = useState('')

  const { classes } = useStyles()

  const [promptsUpdate] = usePromptsUpdateMutation()

  useEffect(() => {
    setText(prompt?.text)
    setName(prompt?.name)
  }, [prompt])

  const modalLabelType = () => {
    switch (prompt?.type?.id) {
      case 1:
        return 'Query'
      case 2:
        return 'Clause'
      case 3:
        return 'Main summary'
      case 4:
        return 'Main question prompt'
      case 5:
        return 'Summarization prompt'
      case 6:
        return 'Overview prompt'
      default:
        return ''
    }
  }

  async function handleName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
  }

  const handleUpdate = async () => {
    try {
      await promptsUpdate({
        ...prompt,
        text,
        name,
      })

      showNotification({
        type: 'success',
        message: t('prompts_updated_success'),
      })
    } catch (e) {
      console.log(e)
    } finally {
      if (refetch) refetch()
      setOpen(false)
    }
  }

  const onCopyValue = (value: string) => {
    if (navigator) {
      navigator?.clipboard?.writeText(value)

      showNotification({
        type: 'success',
        message: t(`${value} successfully_copied`),
      })
    } else {
      showNotification({
        type: 'error',
        message: t('copy_error'),
      })
    }
  }

  const isExistUserValue = () => {
    const skipTypes = [1, 2, 5, 6]

    if (skipTypes.includes(prompt?.type?.id)) {
      return true
    }

    return text.indexOf('[questionUser]') !== -1
  }

  const isExistDocValue = () => {
    const skipTypes = [1, 2, 3, 5, 6]

    if (skipTypes.includes(prompt?.type?.id)) {
      return true
    }

    return text.indexOf('[dataBasedoc]') !== -1
  }

  const isUserDefinedLanguge = () => {
    const skipTypes = [1, 2, 6]

    if (skipTypes.includes(prompt?.type?.id)) {
      return true
    }

    return text.indexOf('[userDefinedLanguge]') !== -1
  }

  const isSummarizeParagraph = () => {
    const skipTypes = [1, 2, 4, 6]

    if (skipTypes.includes(prompt?.type?.id)) {
      return true
    }

    return text.indexOf('[summarizeParagraph]') !== -1
  }

  const isDataBase = () => {
    if (prompt?.type?.id !== 6) {
      return true
    }

    return text.indexOf('[dataBase]') !== -1
  }

  const isLang = () => {
    if (prompt?.type?.id !== 6) {
      return true
    }

    return text.indexOf('[lang]') !== -1
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>
        <Icon name={'pencil'} />
      </Button>
      <Modal
        title={`${t('update')} ${modalLabelType()}`}
        opened={open}
        onClose={() => setOpen(false)}
      >
        {(prompt?.type?.id === 4 ||
          prompt?.type?.id === 3 ||
          prompt?.type?.id === 5 ||
          prompt?.type?.id === 6) && (
          <Box mb={'xs'}>
            {(!isExistUserValue() ||
              !isExistDocValue() ||
              !isUserDefinedLanguge() ||
              !isDataBase() ||
              !isLang() ||
              !isSummarizeParagraph()) && (
              <Alert variant="light" color="red" title={t('required_variables')}>
                {!isExistUserValue() && <b>{`[questionUser] `}</b>}
                {!isExistDocValue() && <b>{`[dataBasedoc]`}</b>}
                {!isDataBase() && <b>{`[dataBase] `}</b>}
                {!isLang() && <b>{`[lang]`}</b>}
                {!isUserDefinedLanguge() && <b>{`[userDefinedLanguge]`}</b>}
                {!isSummarizeParagraph() && <b>{`[summarizeParagraph]`}</b>}
              </Alert>
            )}
            <Box mt={'xs'} className={'flex self-start'}>
              <Group spacing={'sm'}>
                {!isExistUserValue() && (
                  <Button onClick={() => onCopyValue('[questionUser]')}>
                    {t('copy')} {`[questionUser]`}
                  </Button>
                )}
                {!isDataBase() && (
                  <Button onClick={() => onCopyValue('[dataBase]')}>
                    {t('copy')} {`[dataBase]`}
                  </Button>
                )}
                {!isLang() && (
                  <Button onClick={() => onCopyValue('[lang]')}>
                    {t('copy')} {`[lang]`}
                  </Button>
                )}
                {!isExistDocValue() && (
                  <Button onClick={() => onCopyValue('[dataBasedoc]')}>
                    {t('copy')}
                    {`[dataBasedoc]`}
                  </Button>
                )}
                {!isUserDefinedLanguge() && (
                  <Button onClick={() => onCopyValue('[userDefinedLanguge]')}>
                    {t('copy')}
                    {`[userDefinedLanguge]`}
                  </Button>
                )}
                {!isSummarizeParagraph() && (
                  <Button onClick={() => onCopyValue('[summarizeParagraph]')}>
                    {t('copy')}
                    {`[summarizeParagraph]`}
                  </Button>
                )}
              </Group>
            </Box>
          </Box>
        )}
        <Input.Wrapper label={t('admin_prompt_name')}>
          <Input
            name={'create_new_prompt'}
            id={'create_new_prompt'}
            placeholder={modalLabelType()}
            mb={'md'}
            onChange={handleName}
            value={name}
          />
        </Input.Wrapper>
        <Textarea
          label={t('admin_prompt_text')}
          name={'create_new_prompt'}
          id={'create_new_prompt'}
          placeholder={modalLabelType()}
          mb={'md'}
          onChange={(event) => setText(event?.currentTarget?.value || '')}
          value={text}
          autosize={false}
          minRows={6}
          maxRows={10}
        />
        <Button
          disabled={
            text === '' ||
            name === '' ||
            !isExistDocValue() ||
            !isExistUserValue() ||
            !isUserDefinedLanguge() ||
            !isDataBase() ||
            !isLang() ||
            !isSummarizeParagraph()
          }
          onClick={handleUpdate}
        >
          {t('update')}
        </Button>
      </Modal>
    </div>
  )
}

import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AppShell } from '@mantine/core'
import useStyles from './Component.styles'

interface IProps {
  children?: React.ReactNode
  header?: React.ReactElement
  navbar?: React.ReactElement
  classNames?: any
}

export const VerticalPanelShell = ({ children, navbar, header, classNames }: IProps) => {
  const { classes } = useStyles({}, { name: 'VerticalPanelShell', classNames })
  const { id } = useParams()

  return (
    <>
      <AppShell
        navbarOffsetBreakpoint={id ? 3000 : 'md'}
        padding="md"
        navbar={navbar}
        header={header}
        classNames={classes}
      >
        {children}
      </AppShell>
    </>
  )
}

import { useTranslation } from 'react-i18next'
import { Button, Collapse, Popover, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/Elements'

export const QueryPrompts = ({ promptsData, handleClick, loading }: any) => {
  const maxItems = 10

  const { t } = useTranslation()
  const [opened, { toggle }] = useDisclosure(false)

  return (
    <div style={{ marginTop: 50, marginBottom: 10 }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <Popover width={'35%'} position={'right-end'} shadow="md" onChange={toggle} opened={opened}>
          <Popover.Target>
            <Button px={2} variant="transparent" color="rgba(0, 0, 0, 1)" onClick={toggle}>
              {!opened && <span style={{ marginRight: 5 }}>{t('suggestions')}</span>}
              {opened ? (
                <div style={{ border: '1px #000 solid', padding: 5, borderRadius: 20 }}>
                  <Icon name={'x'} />
                </div>
              ) : (
                <div style={{ border: '1px #000 solid', padding: 5, borderRadius: 20 }}>
                  <Icon name={'plus'} />
                </div>
              )}
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <ul className={`answer-block-promts-list`}>
              {promptsData?.slice(0, maxItems)?.map((prompt: any, idx: number) => {
                return (
                  <li
                    className={`${loading ? 'disabled' : 'active'}`}
                    key={idx}
                    onClick={() => {
                      toggle()
                      handleClick(prompt?.text, prompt?.name)
                    }}
                  >
                    <Text>{prompt.name}</Text>
                  </li>
                )
              })}
            </ul>
          </Popover.Dropdown>
        </Popover>
      </div>
    </div>
  )
}

import React from 'react'
import { LimitsRoot } from '@/features/limits/routes'
import { PromptsRoot } from '@/features/prompts/routes'
import { UsersRoot } from '@/features/users/routes'
import { PATH_ADMIN } from '@/routes/consts'

type AdminRouteType = {
  path?: string
  element: React.ReactNode
}

const adminRoutes: AdminRouteType[] = [
  {
    path: `${PATH_ADMIN.USERS}/*`,
    element: <UsersRoot />,
  },
  {
    path: `${PATH_ADMIN.PROMPTS}/*`,
    element: <PromptsRoot />,
  },
  {
    path: `${PATH_ADMIN.LIMITS}/*`,
    element: <LimitsRoot />,
  },
]

export const getAdminRoutes = () => {
  return [...adminRoutes]
}

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineArrowUpward } from 'react-icons/md'
import { TableView } from '@/components/Elements'
import { useGeneralUsersQueryParams } from '@/features/users/hooks'
import { DateUtils } from '@/utils'
import { Action } from './Action'
import { User } from './User'
import styles from './styles.module.scss'

type Key =
  | 'user'
  | 'email'
  | 'createdAt'
  | 'action'
  | 'company'
  | 'documentUploaded'
  | 'input_tokens'
  | 'output_tokens'
  | 'overview_input_tokens'
  | 'overview_output_tokens'
  | 'query_tab_input_tokens'
  | 'query_tab_output_tokens'
  | 'query_tab_requests'
  | 'requests'
  | 'summary_processing_input_tokens'
  | 'summary_processing_output_tokens'
  | 'summary_tab_input_tokens'
  | 'summary_tab_output_tokens'
  | 'summary_tab_requests'

interface IProps {
  keys: Key[]
  items: any[]
  loading?: boolean
  actionProps?: any
}

const SortedLabel = ({ sortKey, title }: { sortKey: string; title: string }) => {
  const { t } = useTranslation()
  const { sortedBy, orderBy, onOrderByChange, onSortByChange } = useGeneralUsersQueryParams()

  const handleSort = (key: string) => {
    if (key === sortedBy) {
      const order = orderBy === 'asc' ? 'desc' : 'asc'

      onOrderByChange(order)
      return
    }

    onSortByChange(key)
  }

  const isActive = sortKey === sortedBy

  return (
    <div
      className={classNames({
        [styles.activeRow]: isActive,
      })}
      onClick={() => handleSort(sortKey)}
    >
      <div className={styles.rowName}>
        <span>{t(title)}</span>
        {isActive && (
          <div
            className={classNames(styles.orderIcon, {
              [styles.rotate]: orderBy === 'asc',
            })}
          >
            <MdOutlineArrowUpward />
          </div>
        )}
      </div>
    </div>
  )
}

export const GeneralUsersTable = ({ keys = [], items, loading, actionProps, ...props }: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    user: {
      accessorKey: 'user',
      id: 'user',
      header: <SortedLabel sortKey={'name'} title={'user'} />,
      cell: (info: any) => <User user={info.row.original} />,
    },
    email: {
      accessorKey: 'email',
      id: 'email',
      header: <SortedLabel sortKey={'email'} title={'email'} />,
      cell: (info: any) => info.getValue() || '-',
    },
    company: {
      accessorKey: 'company',
      id: 'company',
      header: <SortedLabel sortKey={'company'} title={'company'} />,
      cell: (info: any) => <div style={{ minWidth: 200 }}>{info.getValue() || '-'}</div>,
    },
    documentUploaded: {
      accessorKey: 'documentUploaded',
      id: 'documentUploaded',
      header: <SortedLabel sortKey={'documents_count'} title={'documentUploaded'} />,
      cell: (info: any) => info.getValue() || '-',
    },
    input_tokens: {
      accessorKey: 'input_tokens',
      id: 'input_tokens',
      header: t('input_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    output_tokens: {
      accessorKey: 'output_tokens',
      id: 'output_tokens',
      header: t('output_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    overview_input_tokens: {
      accessorKey: 'overview_input_tokens',
      id: 'overview_input_tokens',
      header: t('overview_input_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    overview_output_tokens: {
      accessorKey: 'overview_output_tokens',
      id: 'overview_output_tokens',
      header: t('overview_output_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    query_tab_input_tokens: {
      accessorKey: 'query_tab_input_tokens',
      id: 'query_tab_input_tokens',
      header: t('query_tab_input_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    query_tab_output_tokens: {
      accessorKey: 'query_tab_output_tokens',
      id: 'query_tab_output_tokens',
      header: t('query_tab_output_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    query_tab_requests: {
      accessorKey: 'query_tab_requests',
      id: 'query_tab_requests',
      header: t('query_tab_requests'),
      cell: (info: any) => info.getValue() || '-',
    },
    requests: {
      accessorKey: 'requests',
      id: 'requests',
      header: t('requests'),
      cell: (info: any) => info.getValue() || '-',
    },
    summary_processing_input_tokens: {
      accessorKey: 'summary_processing_input_tokens',
      id: 'summary_processing_input_tokens',
      header: t('summary_processing_input_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    summary_processing_output_tokens: {
      accessorKey: 'summary_processing_output_tokens',
      id: 'summary_processing_output_tokens',
      header: t('summary_processing_output_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    summary_tab_input_tokens: {
      accessorKey: 'summary_tab_input_tokens',
      id: 'summary_tab_input_tokens',
      header: t('summary_tab_input_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    summary_tab_output_tokens: {
      accessorKey: 'summary_tab_output_tokens',
      id: 'summary_tab_output_tokens',
      header: t('summary_tab_output_tokens'),
      cell: (info: any) => info.getValue() || '-',
    },
    summary_tab_requests: {
      accessorKey: 'summary_tab_requests',
      id: 'summary_tab_requests',
      header: t('summary_tab_requests'),
      cell: (info: any) => info.getValue() || '-',
    },
    createdAt: {
      accessorKey: 'created_at',
      id: 'created_at',
      header: <SortedLabel sortKey={'created_at'} title={'registration_date'} />,
      cell: (info: any) => (info.getValue() ? DateUtils.formatDate(info.getValue()) : '-'),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: (info: any) => (
        <div className={'flex justify-end'}>
          <Action {...actionProps} user={info.row.original} />
        </div>
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}

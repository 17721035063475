import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useUsersActiveUpdateMutation,
  useUsersAvatarDeleteMutation,
  useUsersAvatarUploadMutation,
  useUsersDeleteMutation,
  useUsersRestoreMutation,
  useUsersUpdateMutation,
} from '@/features/users/store'
import { UpdateUserActiveDTO, UpdateUserDTO, UploadUserAvatarDTO } from '@/features/users/types'
import { useNotify } from '@/hooks'

export const useUsersQueryMutations = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const notifySuccess = (message?: string) => {
    showNotification({
      type: 'success',
      message: message || t('saved_successfully'),
    })
  }

  const notifyError = (message?: string) => {
    showNotification({ type: 'error', message: message || t('error') })
  }

  // === Update === //
  const [update] = useUsersUpdateMutation()

  const onUpdate = async (args: {
    id: string
    params: UpdateUserDTO
    options?: { invalidatesTagsById: boolean }
  }) => {
    const data = await update(args).unwrap()

    notifySuccess()

    return data
  }
  // === //

  // === Restore === //
  const [restore, { isLoading: restoring }] = useUsersRestoreMutation()

  const onRestore = async (id: string) => {
    try {
      await restore(id).unwrap()

      notifySuccess()
    } catch (error: any) {
      notifyError(error?.message)
    }
  }
  // === //

  // === Delete === //
  const [remove, { isLoading: removing }] = useUsersDeleteMutation()

  const onRemove = async (id: string) => {
    try {
      await remove(id).unwrap()

      notifySuccess()
    } catch (error: any) {
      notifyError(error?.message)
    }
  }
  // === //

  // === Avatar === //
  const [avatarUpload, { isLoading: avatarUploading }] = useUsersAvatarUploadMutation()

  const onAvatarUpload = async (args: { id: string; params: UploadUserAvatarDTO }) => {
    try {
      const data = await avatarUpload(args).unwrap()

      notifySuccess()

      return data
    } catch (error: any) {
      notifyError(error?.message)
    }
  }

  const [avatarRemove, { isLoading: avatarRemoving }] = useUsersAvatarDeleteMutation()

  const onAvatarRemove = async (id: string) => {
    try {
      await avatarRemove(id).unwrap()

      notifySuccess(t('deleted_successfully'))
    } catch (error: any) {
      notifyError(error?.message)
    }
  }
  // === //

  // === Active === //
  const [active, { isLoading: activating }] = useUsersActiveUpdateMutation()

  const onActiveUpdate = async (args: {
    id: string
    params: UpdateUserActiveDTO
    options?: { invalidatesTagsById: boolean }
  }) => {
    try {
      const data = await active(args).unwrap()

      notifySuccess()

      return data
    } catch (error: any) {
      notifyError(error?.message)
    }
  }
  // === //

  return {
    onUpdate,
    avatar: {
      onUpload: onAvatarUpload,
      onRemove: onAvatarRemove,
      uploading: avatarUploading,
      removing: avatarRemoving,
    },
    restore: {
      onRestore,
      isLoading: restoring,
    },
    active: {
      onActiveUpdate,
      isLoading: activating,
    },
    remove: {
      onRemove,
      isLoading: removing,
    },
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useLogout } from '@/features/auth/hooks'

interface IProps {
  error: any
}

export const ErrorFallback = ({ error }: IProps) => {
  const { t } = useTranslation()

  const { logout, loading } = useLogout()

  return (
    <Box m={'xl'}>
      <Alert type={'error'}>{(error.data as any)?.message || t('error')}</Alert>

      <Button mt={'lg'} loading={loading} onClick={logout}>
        {t('logout')}
      </Button>
    </Box>
  )
}

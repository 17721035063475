import React from 'react'
import { ActionIconProps, ActionIcon as MantineActionIcon } from '@mantine/core'
import { Icon } from '@/components/Elements'

interface IProps extends ActionIconProps {
  className?: string
  name: 'remove' | 'edit' | 'download' | 'print'
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ActionIconButton: React.FC<IProps> = ({ name, children, className, ...props }) => {
  const config = () => {
    let data: any = {
      icon: '',
      color: '',
      bgColor: '',
    }
    switch (name) {
      case 'remove':
        data = {
          icon: <Icon name={'trash'} />,
        }
        break

      case 'edit':
        data = {
          icon: <Icon name={'pencil'} />,
        }
        break

      case 'download':
        data = {
          icon: <Icon name={'download'} />,
        }
        break

      case 'print':
        data = {
          icon: <Icon name={'printer'} />,
        }
        break

      default:
        data = { ...data }
        break
    }
    return data
  }

  return (
    <MantineActionIcon variant="transparent" color={config().color || 'gray.9'} {...props}>
      {config().icon}
    </MantineActionIcon>
  )
}

import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'

type FormValues = {
  name: string
  last_name: string
  email: string
  company: string
}

interface IProps {
  simplified?: boolean
  emailProps?: any
}

export const GeneralFormSection = ({ simplified, emailProps }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'name'}
            id={'name'}
            label={t('first_name')}
            placeholder={t('first_name')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'last_name'}
            id={'last_name'}
            label={t('last_name')}
            placeholder={t('last_name')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            {...emailProps}
          />
        </Grid.Col>
      </Grid>

      {!simplified && (
        <Grid>
          <Grid.Col>
            <TextInputController
              control={control}
              name={'company'}
              id={'company'}
              label={t('company')}
              placeholder={t('company')}
              mb={'md'}
            />
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

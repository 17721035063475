import React from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItemProps, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'

export const EditControl = (props: MenuItemProps) => {
  const { t } = useTranslation()

  return (
    <Menu.Item icon={<Icon name={'pencil'} />} {...props}>
      <Text>{t('edit')}</Text>
    </Menu.Item>
  )
}

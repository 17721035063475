import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { GeneralUsersContainer } from '@/features/users/components/Users/GeneralUsers/GeneralUsersContainer'

export const Users = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'users'} />

      <GeneralUsersContainer />
    </>
  )
}

import * as Yup from 'yup'
import { PASSWORD_MIN_LENGTH, PHONE_DIGITS_LENGTH } from '@/consts/form'
import { SCORE_STRONG } from '@/packages/password-strength-meter'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string()
    .required()
    .min(PASSWORD_MIN_LENGTH)
    .test('score', { key: 'field.error.password.npt_strong' }, (value, context) => {
      return context?.options?.context?.passScore >= SCORE_STRONG
    }),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], { key: 'field.error.password.no_match' })
    .min(PASSWORD_MIN_LENGTH),
  terms: Yup.bool().oneOf([true], 'required'),
})

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@mantine/core'
import { MainHeader, MainHeaderProps } from '@/components/Layouts/Elements'
import { useUser } from '@/features/user/hooks'
import { getPrivateInitialRoute } from '@/routes/helpers'
import useStyles from './Component.styles'
import { ProfileMenu, ProfileMenuProps } from './ProfileMenu'

interface IProps extends MainHeaderProps {
  profileMenuProps?: ProfileMenuProps
}

export const GeneralHeader = ({ children, profileMenuProps, ...props }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { isAdmin } = useUser()

  const initialRoute = getPrivateInitialRoute({ isAdmin: isAdmin() })

  return (
    <MainHeader
      rightSection={
        <Flex align={'center'} gap={{ base: 'xl', md: 80 }}>
          <ProfileMenu {...profileMenuProps} />
        </Flex>
      }
      indexRoutePath={initialRoute}
      fluid
      {...props}
    >
      {children}
    </MainHeader>
  )
}

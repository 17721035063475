export const tempData = [
  {
    id: 'pm_1N7wfsJprFEQHAzSltqxbb2f',
    type: 'card',
    default: false,
    brand: 'visa',
    exp_month: 12,
    exp_year: 2024,
    last4: '4242',
  },
  {
    id: 'pm_1N7D8uJprFEQHAzSfNbzy72E',
    type: 'card',
    default: false,
    brand: 'visa',
    exp_month: 12,
    exp_year: 2023,
    last4: '3155',
  },
  {
    id: 'pm_1N8PPQJprFEQHAzSj5gUIkwL',
    type: 'sepa_debit',
    default: true,
    last4: '3201',
    bank_code: '19043',
    country: 'AT',
  },
  {
    id: 'pm_1N8PNLJprFEQHAzSfCFgsonX',
    type: 'sepa_debit',
    default: false,
    last4: '3000',
    bank_code: '37040044',
    country: 'DE',
  },
  {
    id: 'pm_1N7x6KJprFEQHAzSfig5j8vy',
    type: 'sepa_debit',
    default: false,
    last4: '3000',
    bank_code: '37040044',
    country: 'DE',
  },
  {
    id: 'pm_1N7DJaJprFEQHAzSrh2beuhk',
    type: 'sepa_debit',
    default: false,
    last4: '3201',
    bank_code: '19043',
    country: 'AT',
  },
]

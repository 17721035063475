import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useFormSubmit } from '@/hooks'
import { CommonUtils, FormatUtils } from '@/utils'
import { GeneralFormSection, NoteFormSection, VatFormSection } from './FormSections'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  isDisabled?: boolean
  isEditable?: boolean
  initialValues?: any
  disabledMode?: boolean
  isFormActive?: boolean
  toggleActiveForm: () => void
}

export const BillingAddressForm = ({
  isDisabled,
  isEditable,
  initialValues,
  disabledMode,
  isFormActive,
  toggleActiveForm,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      company_name: '',
      phone: '',
      address: '',
      city: '',
      zip: '',
      note: '',
      billing_address_country_id: null,
      tax_id: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
    reset,
  } = methods

  const onReset = () => {
    const { phone, country, ...rest } = initialValues

    let values = {
      phone: FormatUtils.formatPhone(phone) || '',
      billing_address_country_id: country?.id ? country?.id.toString() : null,
    }

    Object.entries(rest).forEach((entry) => {
      const [key, value] = entry

      values = {
        ...values,
        [key]: CommonUtils.isNil(value) ? '' : value,
      }
    })

    reset(values)
  }

  // set value after fetch data
  useDeepCompareEffect(() => {
    onReset()
  }, [initialValues])

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const { billing_address_country_id, tax_id, phone, ...restValues } = data

      let values = {
        ...restValues,
        phone: phone ? phone.replace(/[^0-9]/g, '') : '',
      }

      if (isEditable) {
        values = {
          ...values,
          billing_address_country_id,
          tax_id,
        }
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const onCancel = () => {
    toggleActiveForm()
    onReset()
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralFormSection isDisabled={isDisabled} />

          <VatFormSection isDisabled={isDisabled} isEditable={isEditable} />

          <NoteFormSection isDisabled={isDisabled} />

          <Space h={'xl'} />

          <Group mt={'xl'} position={'right'}>
            {disabledMode && (
              <>
                {isFormActive ? (
                  <Button variant="outline" color="dark" onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                ) : (
                  <Button variant="outline" color="dark" onClick={toggleActiveForm}>
                    {t('edit')}
                  </Button>
                )}
              </>
            )}

            {isFormActive && (
              <Button loading={isSubmitting} disabled={isDisabled} type={'submit'}>
                {t('save')}
              </Button>
            )}
          </Group>
        </form>
      </FormProvider>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack } from '@mantine/core'
import { Avatar, Button } from '@/components/Elements'
import { FILE_MAX_SIZE } from '@/consts/form'
import { useNotify } from '@/hooks'
import { FileUtils, ValidationsUtils } from '@/utils'

export interface AvatarManagerControlProps {
  image: string | null | undefined
  onUpload: (file: File) => void
  onRemove: () => void
  uploading?: boolean
  removing?: boolean
  uploadLabel?: React.ReactNode
  deleteLabel?: React.ReactNode
  placeholder?: string
}

export const AvatarManagerControl = ({
  image,
  onUpload,
  onRemove,
  uploading = false,
  removing = false,
  uploadLabel,
  deleteLabel,
  placeholder,
}: AvatarManagerControlProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  const selectPhoto = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  useEffect(() => {
    if (image) {
      setSelectedPhoto(null)
    }
  }, [image])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const file = event.target.files[0]

    const isValid = ValidationsUtils.fileMaxSize(file, FILE_MAX_SIZE)

    if (!isValid) {
      const message = t('file.max_size.error', {
        text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
      })
      showNotification({ type: 'error', message: message })

      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedPhoto(reader.result as string)
      }
    }
    reader.readAsDataURL(file)

    onUpload(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  uploadLabel = uploadLabel || t('upload_photo')
  deleteLabel = deleteLabel || t('delete_photo')

  return (
    <>
      <Group>
        <Avatar
          size={100}
          radius={50}
          src={selectedPhoto ? selectedPhoto : image ? image : placeholder}
        />

        <input
          type="file"
          onChange={onInputChange}
          ref={inputRef}
          accept="image/png, image/jpeg"
          className={'hidden'}
        />

        <Stack spacing="xs">
          <Button
            onClick={selectPhoto}
            variant={'default'}
            size={'xs'}
            disabled={removing}
            loading={uploading}
          >
            {uploadLabel}
          </Button>

          {image && (
            <Button
              onClick={onRemove}
              variant={'default'}
              size={'xs'}
              disabled={uploading}
              loading={removing}
            >
              {deleteLabel}
            </Button>
          )}
        </Stack>
      </Group>
    </>
  )
}

import * as React from 'react'
import { Container, ContainerProps } from '@mantine/core'

interface IProps extends ContainerProps {
  width?: 'sm'
}

export const ContentContainer = ({ width, ...props }: IProps) => {
  let size: string | number = 'sm'

  switch (width) {
    case 'sm':
      size = 600
      break

    default:
      break
  }

  return (
    <Container maw={1000} size={size}>
      {props.children}
    </Container>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'

interface IProps {
  onPrint: () => void
}

export const PrintControl = ({ onPrint }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ActionIconButton
        size={'lg'}
        color={'primary'}
        variant={'filled'}
        name={'print'}
        onClick={onPrint}
      />
    </>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileForm } from '@/features/users/components/Forms'
import { useUsersData, useUsersDataQueryMutations } from '@/features/users/hooks'
import { FormatUtils } from '@/utils'
import { TopBarFormSection } from './TopBarFormSection'

export const ProfileContainer = () => {
  const { t } = useTranslation()

  const { user } = useUsersData({})

  const { onUpdate } = useUsersDataQueryMutations()

  const { name, last_name, company, email } = user || {}

  const onSubmit = async (values: any) => {
    await onUpdate(values)
  }

  const initialValues = useMemo(() => {
    return {
      name: name || '',
      last_name: last_name || '',
      email,
      company,
    }
  }, [])

  return (
    <>
      <ProfileForm
        onSubmit={onSubmit}
        initialValues={{
          ...initialValues,
        }}
        topBar={<TopBarFormSection />}
      />
    </>
  )
}

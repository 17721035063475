import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { RegisterContainer } from '@/features/auth/components/Register'

export const Register = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'registration'} />

      <RegisterContainer />
    </>
  )
}

import * as Yup from 'yup'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object({
  first_name: Yup.string().nullable().required(),
  last_name: Yup.string().nullable().required(),
  email: Yup.string().email().nullable().required(),
  company_name: Yup.string().nullable().required(),
  phone: Yup.string()
    .test('phone', { key: 'field.error.invalid' }, ValidationsUtils.phoneFormat)
    .required(),
  address: Yup.string().nullable().required(),
  city: Yup.string().nullable().required(),
  zip: Yup.string().nullable().required(),
  note: Yup.string().nullable(),
  billing_address_country_id: Yup.mixed()
    .nullable()
    .when('tax_id', ([tax_id], schema) => (tax_id ? schema.required() : schema)),
  tax_id: Yup.string().nullable(),
})

import { rootApi } from '@/store/api'
import {
  UpdateUserActiveDTO,
  UpdateUserDTO,
  UpdateUserPasswordDTO,
  UserDTO,
} from '@/features/users/types'
import { CollectionDTO } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const UsersTag = 'Users'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: [UsersTag] })

export const usersApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    usersEntries: builder.query<CollectionDTO<UserDTO>, { params: any }>({
      query: (arg) => ({
        url: `users`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
      providesTags: cacher.providesNestedList(UsersTag),
    }),
    exportUsersEntries: builder.mutation<any, any>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url: `users_data/exports`,
          params: RequestUtils.getMapRequestParams(arg.params),
          responseHandler: (response) => response.blob(),
        })
        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        const blobPDF = url.createObjectURL(result.data)
        hiddenElement.href = blobPDF
        hiddenElement.target = '_blank'
        hiddenElement.download = `Review Legal | USERS | from ${arg.params?.date_from} to ${arg.params?.date_to}.xlsx`
        hiddenElement.click()
        return { data: null }
      },
    }),
    usersData: builder.query<UserDTO, string>({
      query: (id) => ({
        url: `users/${id}`,
        params: RequestUtils.getMapRequestParams({
          include: ['avatar'],
        }),
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      providesTags: cacher.cacheByIdArg(UsersTag),
    }),
    usersTotalStatsData: builder.query<any, any>({
      query: (arg: any) => ({
        url: `users_total`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
      transformResponse: (response: any) => {
        return response
      },
    }),
    usersStatsData: builder.query<any, any>({
      query: (id) => ({
        url: `users/${id}/statistic`,
      }),
      transformResponse: (response: any) => {
        return response
      },
    }),
    usersUpdate: builder.mutation<
      UserDTO,
      { id: string; params: UpdateUserDTO; options?: { invalidatesTagsById: boolean } }
    >({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `users/${id}`,
          method: 'PATCH',
          body: params,
        }
      },
      invalidatesTags: (result, error, arg) => {
        const projectList = cacher.invalidatesList(UsersTag)()
        const { options } = arg

        const { invalidatesTagsById } = options ?? { invalidatesTagsById: true }

        const usersData = invalidatesTagsById
          ? cacher.cacheByIdArg(UsersTag)(result, error, arg.id)
          : []
        return [...projectList, ...usersData]
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    usersDelete: builder.mutation<null, string>({
      query: (id) => {
        return {
          url: `users/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => {
        const usersList = cacher.invalidatesList(UsersTag)()
        const usersData = cacher.cacheByIdArg(UsersTag)(result, error, arg)
        return [...usersList, ...usersData]
      },
    }),
    usersRestore: builder.mutation<null, string>({
      query: (id) => ({
        url: `users/${id}/restore`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        const usersList = cacher.invalidatesList(UsersTag)()
        const usersData = cacher.cacheByIdArg(UsersTag)(result, error, arg)
        return [...usersList, ...usersData]
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    usersUpdatePassword: builder.mutation<any, { id: string; params: UpdateUserPasswordDTO }>({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `users/${id}/password`,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    usersActiveUpdate: builder.mutation<
      UserDTO,
      { id: string; params: UpdateUserActiveDTO; options?: { invalidatesTagsById: boolean } }
    >({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `users/${id}`,
          method: 'PATCH',
          body: params,
        }
      },
      invalidatesTags: (result, error, arg) => {
        const projectList = cacher.invalidatesList(UsersTag)()
        const { options } = arg

        const { invalidatesTagsById } = options ?? { invalidatesTagsById: true }

        const usersData = invalidatesTagsById
          ? cacher.cacheByIdArg(UsersTag)(result, error, arg.id)
          : []
        return [...projectList, ...usersData]
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useUsersEntriesQuery,
  useUsersTotalStatsDataQuery,
  useExportUsersEntriesMutation,
  useUsersDataQuery,
  useUsersStatsDataQuery,
  useLazyUsersStatsDataQuery,
  useUsersUpdateMutation,
  useUsersDeleteMutation,
  useUsersRestoreMutation,
  useUsersUpdatePasswordMutation,
  useUsersActiveUpdateMutation,
} = usersApi

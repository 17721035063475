import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box } from '@mantine/core'
import { Icon, NavLink } from '@/components/Elements'
import { PATH } from '@/routes/consts'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  const locationPathname = location.pathname

  const isRouteActive = useCallback(
    (toPathname: string) => {
      return (
        locationPathname === toPathname ||
        (locationPathname.startsWith(toPathname) &&
          locationPathname.charAt(toPathname.length) === '/')
      )
    },
    [locationPathname]
  )

  const menu = [
    {
      label: t('dashboard'),
      to: `/${PATH.DASHBOARD}`,
      icon: <Icon name={'bar-chart'} />,
    },
    {
      label: t('settings'),
      to: `/${PATH.SETTINGS}`,
      icon: <Icon name={'gear'} />,
    },
  ]

  return (
    <>
      <Box>
        {menu.map(({ label, to, icon }, index) => (
          <NavLink
            active={isRouteActive(to)}
            key={index}
            label={label}
            component={Link}
            to={to}
            onClick={onLinkClick}
            icon={icon}
          />
        ))}
      </Box>
    </>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Navbar as MantineNavbar, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { NavbarDrawer } from '@/components/Layouts/Elements'
import { NAVBAR_WIDTH } from '@/consts/layout'
import { useUser } from '@/features/user/hooks'
import { getPrivateInitialRoute } from '@/routes/helpers'

interface IProps {
  indexRoute?: string
  children?: React.ReactNode
  drawerOpened: boolean
  onDrawerClose: () => void
}

export const GeneralNavbar = ({ children, drawerOpened, onDrawerClose }: IProps) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const theme = useMantineTheme()

  const isSmallerThenMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)

  const { isAdmin } = useUser()

  const initialRoute = getPrivateInitialRoute({ isAdmin: isAdmin() })

  return (
    <>
      <MantineNavbar
        width={{ base: isSmallerThenMd ? 0 : NAVBAR_WIDTH }}
        hiddenBreakpoint={id ? 3000 : 'md'}
        hidden={true}
        withBorder={theme.colorScheme === 'dark'}
        style={{
          boxShadow: theme.shadows.xs,
        }}
      >
        {children}
      </MantineNavbar>

      <NavbarDrawer indexRoutePath={initialRoute} opened={drawerOpened} onClose={onDrawerClose}>
        {children}
      </NavbarDrawer>
    </>
  )
}

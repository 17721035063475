import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Group, Progress } from '@mantine/core'

interface IProps {
  score?: number
  mb?: string | number
}

export const PasswordStrengthBar = ({ score = 0, mb, ...props }: IProps) => {
  const { t } = useTranslation()

  const scores = [
    {
      value: 0,
      color: 'red',
    },
    {
      value: 3,
      color: 'orange',
    },
    {
      value: 8,
      color: 'green',
    },
  ]

  return (
    <Box mb={mb}>
      <Group grow noWrap spacing={4}>
        {scores.map(({ value, color }) => (
          <Progress key={value} value={score >= value ? 100 : 0} color={color} size={4} />
        ))}
      </Group>
    </Box>
  )
}

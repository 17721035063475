import React from 'react'
import {
  BsBarChart,
  BsBoxArrowRight,
  BsChatText,
  BsCheckCircle,
  BsChevronDown,
  BsChevronLeft,
  BsDownload,
  BsEnvelope,
  BsGear,
  BsGrid3X3,
  BsListUl,
  BsPencil,
  BsPerson,
  BsPrinter,
  BsTelephone,
  BsTrash,
  BsXCircle,
} from 'react-icons/bs'
import { LiaTrashRestoreAltSolid } from 'react-icons/lia'
import { TbArrowNarrowRight, TbCheck, TbDots, TbPlus, TbSearch, TbX } from 'react-icons/tb'
import { useMantineTheme } from '@mantine/core'

export type IconNameType =
  | 'chat'
  | 'logout'
  | 'gear'
  | 'chevron-down'
  | 'chevron-left'
  | 'check'
  | 'x'
  | 'printer'
  | 'trash'
  | 'pencil'
  | 'search'
  | 'download'
  | 'grid-3x3'
  | 'list-ul'
  | 'dots'
  | 'plus'
  | 'arrow-right'
  | 'bar-chart'
  | 'person'
  | 'phone'
  | 'email'
  | 'check-circle'
  | 'x-circle'
  | 'trash-restore'

interface IProps {
  name: IconNameType
  size?: number
  color?: string
  className?: string

  [x: string]: any
}

export const Icon = ({ name, ...props }: IProps) => {
  const theme = useMantineTheme()

  const color = props?.color || theme.black

  switch (name) {
    case 'logout':
      return <BsBoxArrowRight {...props} />

    case 'chat':
      return <BsChatText {...props} />

    case 'gear':
      return <BsGear {...props} />

    case 'chevron-down':
      return <BsChevronDown {...props} />

    case 'chevron-left':
      return <BsChevronLeft {...props} />

    case 'check':
      return <TbCheck {...props} />

    case 'x':
      return <TbX {...props} />

    case 'trash':
      return <BsTrash {...props} />

    case 'printer':
      return <BsPrinter {...props} />

    case 'pencil':
      return <BsPencil {...props} />

    case 'search':
      return <TbSearch {...props} />

    case 'download':
      return <BsDownload {...props} />

    case 'list-ul':
      return <BsListUl {...props} />

    case 'grid-3x3':
      return <BsGrid3X3 {...props} />

    case 'dots':
      return <TbDots {...props} />

    case 'plus':
      return <TbPlus {...props} />

    case 'arrow-right':
      return <TbArrowNarrowRight {...props} />

    case 'bar-chart':
      return <BsBarChart {...props} />

    case 'person':
      return <BsPerson {...props} />

    case 'phone':
      return <BsTelephone {...props} />

    case 'email':
      return <BsEnvelope {...props} />

    case 'check-circle':
      return <BsCheckCircle {...props} />

    case 'x-circle':
      return <BsXCircle {...props} />

    case 'trash-restore':
      return <LiaTrashRestoreAltSolid {...props} />

    default:
      return null
  }
}

import React from 'react'
import { PublicLayout } from '@/components/Layouts'
import { LegalRoot } from '@/features/legal/routes'
import { PATH } from '@/routes/consts'

//config
export const getPublicRoutes = (isLoggedIn: boolean) => {
  return [
    {
      path: '/*',
      element: <PublicLayout colorScheme />,
      children: [
        {
          path: `${PATH.LEGAL}/*`,
          element: <LegalRoot />,
        },
      ],
    },
  ]
}

import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/consts/layout'

export default createStyles((theme, _params, getRef) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows.xs,
    minHeight: 0,
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    minHeight: 0,
  },
}))

import { BILLING_TARIFF_ALIAS, BILLING_TARIFF_PERIOD } from '@/features/billing/consts/tariff'
import { TariffModel } from '@/features/billing/types'

const { FREE, TEST, STATICA } = BILLING_TARIFF_ALIAS
const { MONTHLY, ANNUALLY } = BILLING_TARIFF_PERIOD

export const isStatica = (alias: BILLING_TARIFF_ALIAS): boolean => {
  return alias === STATICA
}

export const isTest = (alias: BILLING_TARIFF_ALIAS): boolean => {
  return alias === TEST
}

export const compareTariffLevel = (target: TariffModel, tariff: TariffModel) => {
  let result = {
    isDowngrade: false,
    isUpgrade: false,
  }

  if (!tariff) {
    result = {
      ...result,
      isUpgrade: true,
    }

    return result
  }

  if (tariff?.id === target?.id) {
    return result
  }

  // if is upgrade
  if (
    (tariff.period === MONTHLY && target.period === ANNUALLY) ||
    (tariff.period === target.period &&
      [FREE, TEST].includes(tariff.alias) &&
      ![FREE, TEST].includes(target.alias))
  ) {
    result = {
      isDowngrade: false,
      isUpgrade: true,
    }
  } else {
    // is downgrade
    result = {
      isDowngrade: true,
      isUpgrade: false,
    }
  }

  return result
}

export const isDowngradeTariffLevel = (target: TariffModel, tariff: TariffModel): boolean => {
  return compareTariffLevel(target, tariff)?.isDowngrade
}

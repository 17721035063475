export const tempData = [
  {
    id: 13,
    number: '64DB2BD0-0020',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 12,
    number: '64DB2BD0-0018',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 11,
    number: '64DB2BD0-0017',
    total: 90,
    subtotal: 90,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: -10,
        quantity: 1,
        description: 'Unused time on Month plan after 16 May 2023',
        tax_amount: null,
      },
      {
        amount: 100,
        quantity: 1,
        description: '1 × Month plan (at $100.00 / year)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 10,
    number: '64DB2BD0-0016',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 9,
    number: '64DB2BD0-0015',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 8,
    number: '64DB2BD0-0014',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 7,
    number: '64DB2BD0-0013',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: 'Company name',
    customer_address_city: 'Main Town text',
    customer_address_line: 'Main Street text',
    customer_address_zip: '12345',
    customer_note: 'Some note info',
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-16',
    customer_tax_code: 'AU ABN',
    customer_tax_id: '12345678912',
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 6,
    number: '64DB2BD0-0012',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: null,
    customer_address_city: null,
    customer_address_line: null,
    customer_address_zip: null,
    customer_note: null,
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-15',
    customer_tax_code: '',
    customer_tax_id: null,
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 5,
    number: '64DB2BD0-0011',
    total: 10,
    subtotal: 10,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: null,
    customer_address_city: null,
    customer_address_line: null,
    customer_address_zip: null,
    customer_note: null,
    card_brand: 'visa',
    card_last4: '3155',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-15',
    customer_tax_code: '',
    customer_tax_id: null,
    lines: [
      {
        amount: 10,
        quantity: 1,
        description: '1 × Month plan (at $10.00 / month)',
        tax_amount: null,
      },
    ],
  },
  {
    id: 4,
    number: '64DB2BD0-0010',
    total: 90.01,
    subtotal: 90.01,
    tax: null,
    customer_name: 'John Doe',
    customer_email: 'test@test.cc',
    customer_phone: '123',
    customer_company: null,
    customer_address_city: null,
    customer_address_line: null,
    customer_address_zip: null,
    customer_note: null,
    card_brand: 'visa',
    card_last4: '4242',
    sepa_last4: null,
    sepa_bank_code: null,
    sepa_country: null,
    created_at: '2023-05-15',
    customer_tax_code: '',
    customer_tax_id: null,
    lines: [
      {
        amount: -9.99,
        quantity: 1,
        description: 'Unused time on Month plan after 15 May 2023',
        tax_amount: null,
      },
      {
        amount: 100,
        quantity: 1,
        description: '1 × Month plan (at $100.00 / year)',
        tax_amount: null,
      },
    ],
  },
]

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAppConfirmModal } from '@/features/app/hooks'
import { useUsersModel } from '@/features/users/hooks'
import { ExtendedUserType } from '@/features/users/types'

interface IProps {
  data: ExtendedUserType
  onActiveUpdate?: (arg: { active: boolean }, data: ExtendedUserType) => void
}

export const ActiveControl = ({ data, onActiveUpdate }: IProps) => {
  const { t } = useTranslation()

  const { isActive } = useUsersModel()

  const activeStatus = isActive(data)

  const { open } = useAppConfirmModal({
    onConfirm: () => onActiveUpdate?.({ active: !activeStatus }, data),
  })

  return (
    <Menu.Item
      icon={<Icon name={activeStatus ? 'x-circle' : 'check-circle'} />}
      onClick={() => open()}
    >
      <Text>{activeStatus ? t('deactivate') : t('activate')}</Text>
    </Menu.Item>
  )
}

import MarkdownPreview from '@uiw/react-markdown-preview'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLoadDocumentOverviewQuery } from '@/features/dashboard/store/api/slice'
import styles from './styles.module.scss'

type IStat = {
  label: string
  key: string
}

export const Overview = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } =
    useLoadDocumentOverviewQuery({
      id,
    })

  const stats: IStat[] = [
    {
      label: t('effective_date'),
      key: 'effective_date',
    },
    {
      label: t('duration'),
      key: 'duration',
    },
    {
      label: t('document_type'),
      key: 'document_type',
    },
    {
      label: t('jurisdiction'),
      key: 'jurisdiction',
    },
  ]

  return (
    <div className={styles.wrap}>
      <div className={styles.infoWrap}>
        <p className={styles.title}>{t('document_summary')}</p>
        <div className={styles.statsWrap}>
          {stats.map((stat) => (
            <div className={styles.stat}>
              <p className={styles.label}>{stat.label}</p>
              <div className={styles.value}>
                <p>{data?.[stat.key]}</p>
              </div>
            </div>
          ))}
        </div>
        <MarkdownPreview
          source={data?.summary}
          style={{
            backgroundColor: 'transparent',
            color: '#000',
            fontSize: 14,
            fontFamily: 'Open Sans',
          }}
        />
      </div>
      <div className={styles.keysWrap}>
        <p className={styles.title}>{t('key_provisions')}</p>
        <div className={styles.keysList}>
          <MarkdownPreview
            source={data?.key_provisions}
            style={{
              backgroundColor: 'transparent',
              color: '#000',
              fontSize: 14,
              fontFamily: 'Open Sans',
            }}
          />
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Textarea } from '@mantine/core'
import { Alert, Button, Modal } from '@/components/Elements'
import { usePromptsCreateMutation } from '@/features/prompts/store'
import { useNotify } from '@/hooks'
import useStyles from './Style.styles'

export const CreatePromptsModal = ({
  type,
  refetch,
  count,
}: {
  type: any
  refetch: any
  count: any
}) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [name, setName] = useState('')

  const { classes } = useStyles()

  const [promptsCreate] = usePromptsCreateMutation()

  const modalLabelType = () => {
    switch (type) {
      case '1':
        return 'Query'
      case '2':
        return 'Clause'
      case '3':
        return 'Main summary'
      case '4':
        return 'Main question'
      default:
        return ''
    }
  }

  async function handleName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
  }

  const handleCreate = async () => {
    try {
      await promptsCreate({
        name,
        text,
        type_id: type,
      })

      showNotification({
        type: 'success',
        message: t('prompts_create_success'),
      })
    } catch (e) {
      console.log(e)
    } finally {
      if (refetch) refetch()
      setOpen(false)
      setText('')
      setName('')
    }
  }

  return (
    <div>
      {type === '1' && count && count > 9 && (
        <Alert style={{ marginBottom: 10 }} type={'error'}>
          {t('prompts_limit')}
        </Alert>
      )}
      <Button onClick={() => setOpen(true)} disabled={type === '1' && count && count > 9}>
        {t('create_new_prompt')} {modalLabelType()}
      </Button>
      <Modal
        title={`${t('create_new_prompt')} ${modalLabelType()}`}
        opened={open}
        onClose={() => setOpen(false)}
      >
        <Input.Wrapper label={t('admin_prompt_name')}>
          <Input
            name={'create_new_prompt'}
            id={'create_new_prompt'}
            placeholder={modalLabelType()}
            mb={'md'}
            onChange={handleName}
            value={name}
          />
        </Input.Wrapper>
        <Textarea
          label={t('admin_prompt_text')}
          name={'create_new_prompt'}
          id={'create_new_prompt'}
          placeholder={modalLabelType()}
          mb={'md'}
          onChange={(event) => setText(event?.currentTarget?.value || '')}
          value={text}
          autosize={false}
          minRows={2}
          maxRows={4}
        />
        <Button disabled={text === '' || name === ''} onClick={handleCreate}>
          {t('create')}
        </Button>
      </Modal>
    </div>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Space, Stack, Text } from '@mantine/core'
import { Alert, Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useRegisterMutation } from '@/features/auth/store'
import { useLang } from '@/hooks'
import { PATH } from '@/routes/consts'
import { FormValues, RegisterForm } from './RegisterForm'

export const RegisterContainer = () => {
  const { t } = useTranslation()

  const [register] = useRegisterMutation()

  const navigate = useNavigate()

  const { lang } = useLang()

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(false)

    const params = {
      ...values,
      // role,
      // lang,
    }

    await register(params).unwrap()

    navigate(`/${PATH.THANK_PAGE}`)
    setSuccess(true)
  }

  return (
    <AuthCard title={t('registration')}>
      <Stack spacing="xs" align={'flex-start'}>
        <Text>{t('already_have_account')}?</Text>

        <Anchor component={Link} to={`/${PATH.LOGIN}`} weight={600}>
          {t('login')}
        </Anchor>
      </Stack>

      <Space h={'xl'} />

      {success && (
        <Alert type={'success'} mb={'sm'}>
          {t('confirmation-email-sent')}!
        </Alert>
      )}

      <RegisterForm onSubmit={handleSubmit} />
    </AuthCard>
  )
}

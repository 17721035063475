import { UploadUserAvatarDTO } from '@/features/users/types'
import { RTKQueryUtils } from '@/utils'
import { UsersTag, usersApi } from '../../api/slice'

const { cacher } = RTKQueryUtils

export const usersAvatarApi = usersApi.injectEndpoints({
  endpoints: (builder) => ({
    usersAvatarUpload: builder.mutation<any, { id: string; params: UploadUserAvatarDTO }>({
      query: ({ id, params }) => {
        const formData = new FormData()
        formData.append('avatar', params.avatar)

        return {
          url: `users/${id}/avatar`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: cacher.invalidatesList(UsersTag),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    usersAvatarDelete: builder.mutation<any, string>({
      query: (id) => ({
        url: `users/${id}/avatar`,
        method: 'DELETE',
      }),
      invalidatesTags: cacher.invalidatesList(UsersTag),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useUsersAvatarUploadMutation, useUsersAvatarDeleteMutation } = usersAvatarApi

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PATH_ADMIN_USERS } from '@/routes/consts'
import { UserEditRoot } from '../edit'
import { User } from '../user'

const { EDIT } = PATH_ADMIN_USERS

export const UserRoot = () => {
  return (
    <Routes>
      <Route index element={<User />} />

      <Route path={`${EDIT}/*`} element={<UserEditRoot />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useProfileUpdateProfileMutation } from '@/features/profile/store'
import { useUser } from '@/features/user/hooks'
import { ProfileForm } from '@/features/users/components/Forms'
import { useNotify } from '@/hooks'
import { FormatUtils } from '@/utils'
import { TopBarFormSection } from './TopBarFormSection'

export const ProfileContainer = () => {
  const { t } = useTranslation()

  const { user, isAdmin } = useUser()

  const { company, name, last_name, email } = user || {}

  const { showNotification } = useNotify()

  const [updateProfile] = useProfileUpdateProfileMutation()

  const onSubmit = async (values: any) => {
    await updateProfile(values).unwrap()

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
  }

  const simplifiedForm = isAdmin()

  const initialValues = useMemo(() => {
    let values: any = {
      name: name || '',
      last_name: last_name || '',
      email,
    }

    if (!simplifiedForm) {
      values = {
        ...values,
        company,
      }
    }

    return values
  }, [])

  return (
    <>
      <ProfileForm
        onSubmit={onSubmit}
        initialValues={{
          ...initialValues,
        }}
        simplified={simplifiedForm}
        topBar={<TopBarFormSection />}
      />
    </>
  )
}

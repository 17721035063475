import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { modals } from '@mantine/modals'

export const useAppModalsManager = () => {
  const { t } = useTranslation()

  const renderModalTitle = (title: React.ReactNode) =>
    title ? (
      <div className={'flex'}>
        <Title order={3}>{title}</Title>
      </div>
    ) : null

  const openConfirm = (params: any) =>
    modals.openConfirmModal({
      title: renderModalTitle(t('confirmation')),
      children: t('please_confirm'),
      labels: { confirm: t('confirm'), cancel: t('cancel') },
      size: 'md',
      centered: true,
      ...params,
    })

  return {
    openConfirm,
  }
}

import React from 'react'
import { DateParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

export const useGeneralUsersQueryParams = () => {
  const [query, setQuery] = useQueryParams(
    {
      page: NumberParam,
      search: StringParam,
      date_from: DateParam,
      date_to: DateParam,
      keywords: withDefault(StringParam, ''),
      orderBy: StringParam,
      sortedBy: StringParam,
    },
    { removeDefaultsFromUrl: true }
  )

  const { page, search, date_from, date_to, sortedBy, orderBy, ...restQuery } = query

  const filters = {
    ...restQuery,
  }

  const onPageChange = (page?: number) => {
    setQuery({ page })
  }

  const onOrderByChange = (orderBy?: string) => {
    setQuery({ orderBy })
  }

  const onSortByChange = (sortedBy?: string) => {
    setQuery({ sortedBy })
  }

  const onDateFromChange = (date_from: Date) => {
    setQuery({ date_from })
  }

  const onDateToChange = (date_to: Date) => {
    setQuery({ date_to })
  }

  const onSearchChange = (search?: string) => {
    setQuery({ search })
  }

  const onFiltersChange = (data?: object) => {
    const params = {
      ...data,
    }

    setQuery({ page: undefined, ...params })
  }

  return {
    query,
    setQuery,
    page,
    onPageChange,
    search,
    onSearchChange,
    filters,
    onFiltersChange,
    date_from,
    date_to,
    onDateFromChange,
    onDateToChange,
    onOrderByChange,
    onSortByChange,
    orderBy,
    sortedBy,
  }
}

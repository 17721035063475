import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import WebViewer from '@pdftron/webviewer'
import MarkdownPreview from '@uiw/react-markdown-preview'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import { useNavigate, useParams } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import { v4 as uuidv4 } from 'uuid'
import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  Group,
  Input,
  Loader,
  Button as MantineButton,
  Modal,
  Select,
  Tabs,
} from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import { Button, Icon, LogoIcon, MegaTagTitle } from '@/components/Elements'
import { useAuth } from '@/features/auth/hooks'
import {
  useClearHistoryMutation,
  useLoadAnswerMutation,
  useLoadDocumentLangsQuery,
  useLoadDocumentQuery,
  useLoadDocumentSummaryQuery,
  useLoadHistoryMutation,
  useUpdateDocumentMutation,
  useViewDocumentMutation,
} from '@/features/dashboard/store/api/slice'
import { PromptTypes } from '@/features/prompts/components/GeneralPrompts/GeneralPromptsContainer'
import { usePromptsEntriesQuery } from '@/features/prompts/store'
import { useNotify, useOnViewport } from '@/hooks'
import { AnswerItem } from './AnswerItem'
import { Overview } from './Overview'
import { QueryPrompts } from './QueryPrompts'
import './styles.scss'

const QueryTabCol = ({ handleSearch }: any) => {
  const { showNotification } = useNotify()
  const { t } = useTranslation()

  const [activteSummaryPage, setActiveSummaryPage] = useState(0)

  const { id } = useParams()
  const [question, setQuestion] = useState('')
  const authData = useAuth()
  const [answerIsLoading, setIsLoadingAnswer] = useState(false)
  const [reqQuestion, setReqQuestion] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [chatData, setChatData] = useState<any>([])
  const [isOpen, setIsOpen] = useState(false)
  const [loadedSummaryItems, setLoadedSummaryItems] = useState<any>([])

  const [language, setLanguage] = useState<any>('')
  const langData = useLoadDocumentLangsQuery({})
  const { data: summarizeData, refetch: refetchSumatize } = useLoadDocumentSummaryQuery({
    id,
    page: activteSummaryPage,
  })

  const refSummaryAnswer = useRef<HTMLDivElement>(null)
  const refSummaryHtml = useRef<HTMLDivElement>(null)
  const isVisibleSummaryAnswer = useOnViewport(refSummaryAnswer)
  const isVisibleSummaryHtml = useOnViewport(refSummaryHtml)

  const queryParams = useMemo(() => {
    return {
      type: '1',
    }
  }, [])

  const promptsData = usePromptsEntriesQuery({
    params: queryParams,
  })

  useEffect(() => {
    const newItems = summarizeData?.data || []

    if (newItems) {
      setLoadedSummaryItems([...loadedSummaryItems, ...newItems])
    }
  }, [summarizeData])

  useEffect(() => {
    if (
      (isVisibleSummaryAnswer || isVisibleSummaryHtml) &&
      summarizeData?.total_pages > activteSummaryPage
    ) {
      const newPage = activteSummaryPage + 1
      setActiveSummaryPage(newPage)
    }
  }, [isVisibleSummaryAnswer, isVisibleSummaryHtml])

  useEffect(() => {
    refetchSumatize()
  }, [activteSummaryPage])

  const [viewDocument] = useViewDocumentMutation()
  const [loadAnswer] = useLoadAnswerMutation()
  const [loadHistory] = useLoadHistoryMutation()
  const [clearHistory] = useClearHistoryMutation()
  const [updateDocument] = useUpdateDocumentMutation()

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } = useLoadDocumentQuery({
    id,
  })

  const handleLiveAnswer = (questionData: any, title: any) => {
    let partialData = ''

    const queryQuestion = encodeURIComponent(questionData)

    const xhr = new XMLHttpRequest()
    xhr.open(
      'GET',
      `${process.env.REACT_APP_API_URL}v1/ai/ask-document/${id}?input=${queryQuestion}`,
      true
    )
    xhr.setRequestHeader('Authorization', `Bearer ${authData.oauth?.access_token}`)

    const currentId = uuidv4()
    let newAnswer = ''

    setChatData([
      ...chatData,
      {
        id: currentId,
        question: title,
        answer: newAnswer,
      },
    ])

    xhr.onprogress = function (event: any) {
      const newData = event?.currentTarget?.responseText?.substring(partialData.length)
      partialData = event?.currentTarget?.responseText

      newData.split('\n').forEach(function (item: any) {
        if (item.trim() !== '') {
          const data = item

          if (data === 'event: update') return
          if (data === 'data: <END_STREAMING_SSE>') {
            setIsLoadingAnswer(false)
            setQuestion('')
            return
          }

          newAnswer = data.slice(6)

          if (chatData.find((item: any) => item.id === currentId)) {
            setChatData([
              ...chatData.filter((item: any) => item.id !== currentId),
              {
                id: currentId,
                question: title,
                answer: newAnswer,
              },
            ])
          } else {
            setChatData([
              ...chatData,
              {
                id: currentId,
                question: title,
                answer: newAnswer,
              },
            ])
          }
        }
      })
    }

    xhr.onreadystatechange = function (oEvent) {
      if (xhr.readyState === 4) {
        if (xhr.status === 422) {
          const resError =
            JSON.parse(xhr?.response)?.message ||
            'The monthly limit for request documents has been exceeded.'

          setIsLoadingAnswer(false)
          setQuestion('')
          setChatData([
            ...chatData,
            {
              id: currentId,
              question: title,
              answer: resError,
            },
          ])
        }
      }
    }

    xhr.send()
  }

  const askQuestion = async () => {
    if (isTyping || answerIsLoading) return

    try {
      setIsLoadingAnswer(true)
      handleLiveAnswer(question, question)
    } catch (e) {
      console.log('ERROR: ', e)
    }
  }

  const handleAskQuestion = async (question: string, title: string) => {
    if (isTyping || answerIsLoading) return

    try {
      setIsLoadingAnswer(true)
      handleLiveAnswer(question, title)
    } catch (e) {
      console.log('ERROR: ', e)
    }
  }

  const handleHistory = async () => {
    try {
      setIsLoadingAnswer(true)
      const res = await loadHistory({ id, params: { input: question } }).unwrap()

      setChatData([
        ...chatData,
        ...res.messages_history.map((item: any) => {
          return { question: item.question, answer: item.answer, isHistory: true }
        }),
      ])
    } catch (e) {
      console.log('ERROR: ', e)
    } finally {
      setIsLoadingAnswer(false)
    }
  }

  const handleView = async () => {
    await handleHistory()
    await viewDocument({ id })
    // await refetch()
  }

  useEffect(() => {
    handleView()
  }, [id])

  const removeAnimation = () => setIsTyping(false)

  const isLastAnswer = (idx: number) => chatData?.length === idx

  useEffect(() => {
    window.addEventListener('blur', removeAnimation)

    return () => {
      window.removeEventListener('blur', removeAnimation)
    }
  }, [])

  useEffect(() => {
    const archor = document.getElementById('scroll-archor')
    if (archor) archor.scrollIntoView({ behavior: 'smooth' })
  }, [answerIsLoading])

  const sortLanguages = () => {
    const languages = langData?.data?.data || []

    if (languages?.length === 0) return []

    const sortedLanguages = languages.slice()

    return sortedLanguages
      .sort(function (a: any, b: any) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      .map((item: any) => {
        return { label: item.name, value: item.id }
      })
  }

  useEffect(() => {
    const currentLang = langData?.data?.data?.find(
      (lang: any) => lang?.name === data?.data?.language
    )

    if (currentLang?.id) setLanguage(currentLang?.id)
  }, [data, langData])

  const handleUpdateLanguage = async () => {
    try {
      await updateDocument({ documentId: id, languageId: language })
      showNotification({
        type: 'success',
        message: t('language_updated'),
      })
    } catch (e) {
      console.log('ERROR: ', e)
    }
  }

  const handleClearHistory = async () => {
    try {
      await clearHistory({ id })
      setChatData([])
      showNotification({
        type: 'success',
        message: t('history_deleted'),
      })
    } catch (e) {
      console.log('ERROR: ', e)
    } finally {
      setIsOpen(false)
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingBottom: 0,
        }}
      >
        <Modal opened={isOpen} onClose={() => setIsOpen(false)} title={t('clear_history')}>
          <Group>
            <MantineButton onClick={() => setIsOpen(false)}>{t('cancel')}</MantineButton>
            <MantineButton variant="outline" onClick={handleClearHistory}>
              {t('clear_history')}
            </MantineButton>
          </Group>
        </Modal>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginRight: 20,
          }}
        >
          <Select
            label={t('select_language')}
            placeholder={t('select_language')}
            value={language}
            onChange={(e: any) => setLanguage(e)}
            data={sortLanguages()}
          />
          <MantineButton style={{ marginLeft: 5 }} onClick={handleUpdateLanguage}>
            {t('update_language')}
          </MantineButton>
        </div> */}
      </div>
      <div className="chat-view__wrapper" id="ai-chat">
        {chatData &&
          chatData.map((chatItem: any, idx: number) => (
            <div className="chat-item" key={idx}>
              <div className="chat-question">{chatItem?.question}</div>
              <div className="chat-answer">
                <MarkdownPreview
                  source={chatItem?.answer}
                  style={{ backgroundColor: '#F74F32', color: '#fff' }}
                />
              </div>
            </div>
          ))}
        <div style={{ marginTop: 10 }}>
          <div id="scroll-archor"></div>
        </div>
      </div>
      <QueryPrompts
        handleClick={handleAskQuestion}
        promptsData={promptsData?.data?.data || []}
        loading={answerIsLoading || isTyping}
      />
      <Group spacing={'lg'} noWrap>
        <Input
          size="md"
          style={{ width: '100%' }}
          onChange={(e) => setQuestion(e.target.value)}
          value={question}
          disabled={answerIsLoading || isTyping}
          placeholder={`${t('ask_question')}...`}
          onKeyDown={getHotkeyHandler([['Enter', askQuestion]])}
        />
        <Button onClick={askQuestion} disabled={answerIsLoading || isTyping}>
          <Icon name={'arrow-right'} />
        </Button>
        <MantineButton variant="outline" onClick={() => setIsOpen(true)}>
          {t('clear_history')}
        </MantineButton>
      </Group>
    </>
  )
}

export const DocumentView = () => {
  const { showNotification } = useNotify()
  const { t } = useTranslation()

  const [activteSummaryPage, setActiveSummaryPage] = useState(0)

  const navigate = useNavigate()

  const { id } = useParams()
  const [question, setQuestion] = useState('')
  const [answerIsLoading, setIsLoadingAnswer] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [chatData, setChatData] = useState<any>([])
  const [isOpen, setIsOpen] = useState(false)
  const [loadedSummaryItems, setLoadedSummaryItems] = useState<any>([])

  const [activiveTab, setActiveTab] = useState<string | null>('overview_tab')
  const [activeSummaryId, setActiveSummaryId] = useState('')
  const [language, setLanguage] = useState<any>('')
  const langData = useLoadDocumentLangsQuery({})

  type ISearchStatus = {
    text: string
    searching: boolean
    hasResults: boolean
  }

  const [searchStatus, setSearchStatus] = useState<ISearchStatus>({
    text: '',
    searching: false,
    hasResults: false,
  })

  const [debounceSearching, setDebounceSearching] = useState(false)

  const { data: summarizeData, refetch: refetchSumatize } = useLoadDocumentSummaryQuery({
    id,
    page: activteSummaryPage,
  })

  const refSummaryAnswer = useRef<HTMLDivElement>(null)
  const refSummaryHtml = useRef<HTMLDivElement>(null)
  const isVisibleSummaryAnswer = useOnViewport(refSummaryAnswer)
  const isVisibleSummaryHtml = useOnViewport(refSummaryHtml)

  const [type, setType] = useState<PromptTypes | string | null>('1')

  useEffect(() => {
    if (activiveTab === 'summary_tab') setType('2')
    if (activiveTab === 'query_tab') setType('1')
  }, [activiveTab])

  const queryParams = useMemo(() => {
    return {
      type: type,
    }
  }, [type])

  const promptsData = usePromptsEntriesQuery({
    params: queryParams,
  })

  useEffect(() => {
    const newItems = summarizeData?.data || []

    const stylesMeta = summarizeData?.header

    const startIdx = stylesMeta?.indexOf('<style>') || -1
    const endIdx = stylesMeta?.indexOf('</style>') || -1

    if (startIdx !== -1 && endIdx !== -1) {
      const result = stylesMeta.substring(startIdx + 8, endIdx)
      const style = document.createElement('style')
      style.textContent = result
      style.classList.add('html-document-styles')
      document.head.appendChild(style)
    }

    if (newItems) {
      setLoadedSummaryItems([...loadedSummaryItems, ...newItems])
    }
  }, [summarizeData])

  useEffect(() => {
    return () => {
      const allStyles = document.getElementsByClassName('html-document-styles')

      if (allStyles) {
        while (allStyles?.[0]) {
          allStyles?.[0]?.parentNode?.removeChild(allStyles?.[0])
        }
      }
    }
  }, [])

  useEffect(() => {
    if (
      (isVisibleSummaryAnswer || isVisibleSummaryHtml) &&
      summarizeData?.total_pages > activteSummaryPage
    ) {
      const newPage = activteSummaryPage + 1
      setActiveSummaryPage(newPage)
    }
  }, [isVisibleSummaryAnswer, isVisibleSummaryHtml, answerIsLoading])

  useEffect(() => {
    refetchSumatize()
  }, [activteSummaryPage])

  const [viewDocument] = useViewDocumentMutation()
  const [loadAnswer] = useLoadAnswerMutation()
  const [loadHistory] = useLoadHistoryMutation()
  const [clearHistory] = useClearHistoryMutation()
  const [updateDocument] = useUpdateDocumentMutation()

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } = useLoadDocumentQuery({
    id,
  })

  const docRef = useRef(null)

  const [instance, setInstance] = useState<any>(null)

  /*To Prevent right click on screen*/
  document.addEventListener('contextmenu', (event) => {
    event.preventDefault()
  })

  const searchOptions = {
    caseSensitive: false, // match case
    wholeWord: false, // match whole words only
    wildcard: false, // allow using '*' as a wildcard value
    regex: false, // string is treated as a regular expression
    searchUp: false, // search from the end of the document upwards
    ambientString: true, // return ambient string as part of the result
  }

  const nextSearchString = (text: string) => {
    const newSearchString = text || ''
    const lastIndexOfPoint = newSearchString?.lastIndexOf(' ')

    return newSearchString.slice(0, lastIndexOfPoint)
  }

  const debouncedSearch = debounce(async (status: boolean) => {
    setDebounceSearching(status)
  }, 1000)

  async function handleSearchDebounce(status: boolean) {
    debouncedSearch(status)
  }

  const handleSearchResults = () => {
    const data = JSON.parse(JSON.stringify(searchStatusRef.current))

    if (!data.hasResults) {
      let newSearchString = data?.text || ''

      const splitCount = newSearchString.split(' ').length - 1
      const loopCount = splitCount / 2

      for (let idx = 0; idx < loopCount; idx++) {
        newSearchString = nextSearchString(newSearchString)
      }

      handleSearch(newSearchString)
    }
  }

  const stopSearch = () => {
    const data = JSON.parse(JSON.stringify(searchStatusRef.current))

    setSearchStatusState({
      ...data,
      searching: false,
    })
  }

  const handleSearch = (value: string) => {
    if (instance && !searchStatus.searching) {
      setSearchStatusState({
        searching: true,
        text: value.trim(),
        hasResults: false,
      })

      setTimeout(() => {
        stopSearch()
      }, 1000)
    }
  }

  useEffect(() => {
    if (searchStatus.searching && searchStatus.text) {
      instance?.UI?.searchText(searchStatus.text, searchOptions)
    }

    if (!searchStatus.searching && searchStatus.text && !searchStatus.hasResults) {
      handleSearchResults()
    }
  }, [searchStatus])

  const searchStatusRef = useRef(searchStatus)
  const setSearchStatusState = (data: ISearchStatus) => {
    searchStatusRef.current = data
    setSearchStatus(data)
  }

  useEffect(() => {
    if (data?.data?.status === 'processing') {
      navigate('/dashboard')
    }

    if (docRef.current && data?.data?.url) {
      WebViewer(
        {
          path: '/lib',
          initialDoc: data?.data?.url,
          licenseKey:
            'demo:1706715844773:7f763879030000000021eb77ba25eaa40fd02949ef036f237a8a3e36be',
        },
        docRef.current
      ).then((instance) => {
        const { annotationManager, documentViewer, Annotations } = instance.Core

        const searchListener = (searchPattern: any, options: any, results: any) => {
          const data = JSON.parse(JSON.stringify(searchStatusRef.current))

          setSearchStatusState({
            ...data,
            searching: false,
            hasResults: true,
          })
        }

        documentViewer.addEventListener('documentLoaded', () => {
          setInstance(instance)

          instance.UI.addSearchListener(searchListener)
          instance.UI.setFitMode('FitWidth')

          if (!localStorage.getItem('testMode')) {
            instance.UI.disableElements(['searchPanel', 'searchButton', 'toolsHeader'])
          }
        })
      })
    }
  }, [data])

  const handleHistory = async () => {
    try {
      setIsLoadingAnswer(true)
      const res = await loadHistory({ id, params: { input: question } }).unwrap()

      setChatData([
        ...chatData,
        ...res.messages_history.map((item: any) => {
          return { question: item.question, answer: item.answer, isHistory: true }
        }),
      ])
    } catch (e) {
      console.log('ERROR: ', e)
    } finally {
      setIsLoadingAnswer(false)
    }
  }

  const handleView = async () => {
    await handleHistory()
    await viewDocument({ id })
    // await refetch()
  }

  useEffect(() => {
    handleView()
  }, [id])

  const removeAnimation = () => setIsTyping(false)

  const isLastAnswer = (idx: number) => chatData?.length === idx

  useEffect(() => {
    window.addEventListener('blur', removeAnimation)

    return () => {
      window.removeEventListener('blur', removeAnimation)
    }
  }, [])

  useEffect(() => {
    const archor = document.getElementById('scroll-archor')
    if (archor) archor.scrollIntoView({ behavior: 'smooth' })
  }, [answerIsLoading, activiveTab])

  useEffect(() => {
    const currentLang = langData?.data?.data?.find(
      (lang: any) => lang?.name === data?.data?.language
    )

    if (currentLang?.id) setLanguage(currentLang?.id)
  }, [data, langData])

  const handleActiveBlockId = (item: any, type: any) => {
    if (searchStatus.searching) return

    if (!item?.original_text || !item.clear_original_text) return
    handleSearch(item?.clear_original_text)
    setActiveSummaryId(item?.id)

    const element = document.getElementById(`answer-block-id-${item?.id}`)
    const htmlElement = document.getElementById(`html-block-id-${item?.id}`)

    if (element && type === 'html') {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (htmlElement && type === 'answer') {
      htmlElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Tabs defaultValue={activiveTab || 'overview_tab'} onTabChange={setActiveTab}>
        <Grid>
          <Grid.Col span={6}>
            <MegaTagTitle title={'documentView'} />
            <h4>{data?.data?.name}</h4>
          </Grid.Col>
          <Grid.Col span={6}>
            <div style={{ marginTop: 15 }}>
              <Tabs.List>
                <Tabs.Tab value="overview_tab">{t('overview_tab')}</Tabs.Tab>
                <Tabs.Tab value="summary_tab">{t('summary_tab')}</Tabs.Tab>
                <Tabs.Tab value="query_tab">{t('query_tab')}</Tabs.Tab>
              </Tabs.List>
            </div>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <div style={{ height: 'calc(100vh - 180px)', position: 'relative' }}>
              {searchStatus.searching && !searchStatus.hasResults && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: '0.7',
                    zIndex: 3,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Loading...
                </div>
              )}
              <div style={{ height: '100%' }} ref={docRef}></div>
            </div>
          </Grid.Col>
          <Grid.Col span={6}>
            <Tabs.Panel value="query_tab">
              <div style={{ marginTop: 0 }}>
                <QueryTabCol handleSearch={handleSearch} />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="overview_tab">
              <Overview />
            </Tabs.Panel>
            <Tabs.Panel value="summary_tab">
              <div style={{ marginTop: 0 }}>
                <div className="html-document-view__wrapper document-view__wrapper">
                  <div style={{ padding: '10px 25px', backgroundColor: '#fff' }}>
                    {loadedSummaryItems?.length > 0
                      ? loadedSummaryItems
                          .filter((item: any) => item?.summary_text)
                          .map((item: any, idx: number) => (
                            <AnswerItem
                              item={item}
                              key={idx}
                              handleActiveBlockId={handleActiveBlockId}
                              searching={debounceSearching}
                              handleSearch={handleSearch}
                              activeSummaryId={activeSummaryId}
                              promptsData={promptsData?.data?.data || []}
                            />
                          ))
                      : null}
                    <div style={{ marginTop: 10 }}>
                      <div ref={refSummaryAnswer}></div>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Panel>
          </Grid.Col>
        </Grid>
      </Tabs>
    </div>
  )
}

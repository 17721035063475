import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController, TextInputController } from '@/components/FormElements'
import { TaxIdStatusBadge } from '@/features/billing/components/Elements'
import { BILLING_BILLING_ADDRESS_TAX_ID_STATUS } from '@/features/billing/consts/billing-address'
import { useBillingBillingAddressCountriesListQuery } from '@/features/billing/store'

export type VatFormSectionValues = {
  billing_address_country_id: string
  tax_id: string
  tax_id_status: BILLING_BILLING_ADDRESS_TAX_ID_STATUS | null
}

interface IProps {
  isDisabled?: boolean
  isEditable?: boolean
}

export const VatFormSection = ({ isDisabled, isEditable }: IProps) => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext<VatFormSectionValues>()

  // const { data: countries } = useBillingBillingAddressCountriesListQuery()

  const countries: any[] = []

  const watchTaxIdStatus = watch('tax_id_status')

  const countryOptions = useMemo(() => {
    if (countries) {
      return countries.map(({ id, label }) => ({
        label,
        value: id.toString(),
      }))
    }

    return []
  }, [countries])

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <SelectController
            control={control}
            name={'billing_address_country_id'}
            id={'billing_address_country_id'}
            label={t('country')}
            placeholder={t('country')}
            mb={'md'}
            data={countryOptions}
            clearable
            disabled={isDisabled || !isEditable}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'tax_id'}
            id={'tax_id'}
            placeholder={t('vat_id')}
            mb={'md'}
            disabled={isDisabled || !isEditable}
            label={
              <>
                {t('vat_id')}
                {watchTaxIdStatus && (
                  <span className={'ml-2'}>
                    <TaxIdStatusBadge value={watchTaxIdStatus} />
                  </span>
                )}
              </>
            }
          />
        </Grid.Col>
      </Grid>
    </>
  )
}

import { rootApi } from '@/store/api'
import { UpdateProfileDTO } from '@/features/profile/types'
import { UserDTO } from '@/features/user/types'

type IStatisticType = {
  total: {
    documents: number
    tokens_output: number
    tokens_input: number
    requests: number
  }
  month: {
    documents: number
    tokens_output: number
    tokens_input: number
    requests: number
  }
}

export const profileApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    profileUpdateProfile: builder.mutation<UserDTO, UpdateProfileDTO>({
      query: (arg) => ({
        url: 'profile',
        method: 'POST',
        body: arg,
      }),
      // async onQueryStarted(id, { dispatch, queryFulfilled }) {
      //   const data = await queryFulfilled
      //   dispatch(userData.updateData(data.data))
      // },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    profileUpdatePassword: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'profile/password',
        method: 'POST',
        body: arg,
      }),
    }),
    profileDelete: builder.mutation<void, void>({
      query: () => ({
        url: 'profile/delete',
        method: 'POST',
      }),
    }),

    statistic: builder.query<any, void>({
      query: () => ({
        url: `statistics`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useProfileUpdateProfileMutation,
  useProfileUpdatePasswordMutation,
  useProfileDeleteMutation,
  useStatisticQuery,
} = profileApi

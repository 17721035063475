import { MantineThemeColorsOverride } from '@mantine/core'

const colors = {
  turquoise: [
    '#F8FCFC',
    '#EDF8F6',
    '#DBF0EE',
    '#C9E9E5',
    '#B3E0DA',
    '#9FD7D0',
    '#76C6BD',
    '#49B0A4',
    '#3D948A',
    '#2D6C65',
  ],
  orange: [
    '#f9c6c7',
    '#f9b5b6',
    '#f3aaac',
    '#f5a8aa',
    '#f9a0a3',
    '#F74F32',
    '#F74F32',
    '#cf6467',
    '#b4595c',
    '#92474a',
  ],
}

export const overrideColors: MantineThemeColorsOverride = {
  turquoise: colors.turquoise,
  orange: colors.orange,
}

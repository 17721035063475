import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Burger, Divider, MediaQuery } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import {
  MainHeader,
  MainHeaderProps,
  NavLinkType,
  NavbarContent as NavbarContentWrapper,
  NavbarDrawer,
} from '@/components/Layouts/Elements'
import { PATH } from '@/routes/consts'
import { NavMenu, NavbarContent } from './Navbar'

// eslint-disable-next-line
interface IProps extends MainHeaderProps {}

export const PublicHeader = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  const menu: Array<NavLinkType> = [
    {
      path: `/`,
      label: t('home'),
    },
    {
      path: `/${PATH.SUBSCRIPTIONS}`,
      label: t('subscriptions'),
    },
  ]

  return (
    <>
      <MainHeader
        leftSection={
          <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
            <Burger opened={false} onClick={() => toggleNavbarDrawerOpened()} size="sm" mr={'lg'} />
          </MediaQuery>
        }
        zIndex={1}
        {...props}
      >
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Box>
            <Box className={'flex'}>
              {/* <Divider mx={'xl'} orientation="vertical" />
              <NavMenu menu={menu} /> */}
            </Box>
          </Box>
        </MediaQuery>
      </MainHeader>

      <NavbarDrawer zIndex={1001} opened={isNavbarDrawerOpened} onClose={toggleNavbarDrawerOpened}>
        {menu && (
          <NavbarContentWrapper>
            <NavbarContent menu={menu} onLinkClick={toggleNavbarDrawerOpened} />
          </NavbarContentWrapper>
        )}
      </NavbarDrawer>
    </>
  )
}

import classNames from 'classnames'
import React, { useState } from 'react'
import { flushSync } from 'react-dom'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PasswordInputController, PasswordStrengthBar } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/consts/form'
import { usePasswordStrengthMeter } from '@/packages/password-strength-meter'
import styles from './styles.module.scss'

export type FormValues = {
  password: string
  password_confirmation: string
}

interface IProps {
  onPassScoreChange: (value: number) => void
  passScore: number
}

export const PasswordFormSection: React.FC<IProps> = ({ passScore, onPassScoreChange }) => {
  const { t } = useTranslation()

  const { control, setValue, trigger, watch } = useFormContext<FormValues>()

  type IPassScoreData = {
    symbol: boolean
    number: boolean
    uppercase: boolean
    lowercase: boolean
    length: boolean
  }

  const [passScoreData, setPassScoreData] = useState<IPassScoreData>({
    symbol: false,
    number: false,
    uppercase: false,
    lowercase: false,
    length: false,
  })

  const watchPassword = watch('password')

  const { measure } = usePasswordStrengthMeter()

  const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value

    setValue('password', pass)

    await flushSync(async () => {
      const scoreData = measure(pass)
      let score = scoreData?.score

      const regexSymbol = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\\/|\\]/
      const regexUppercase = /[A-Z]/
      const regexLowercase = /[a-z]/
      const regexNumber = /\d/

      const currentPassScoreData = { ...passScoreData }

      currentPassScoreData.symbol = Boolean(regexSymbol.test(pass))
      if (regexSymbol.test(pass)) score += 1

      currentPassScoreData.uppercase = Boolean(regexUppercase.test(pass))
      if (regexUppercase.test(pass)) score += 1

      currentPassScoreData.lowercase = Boolean(regexLowercase.test(pass))
      if (regexLowercase.test(pass)) score += 1

      currentPassScoreData.number = Boolean(regexNumber.test(pass))
      if (regexNumber.test(pass)) score += 1

      currentPassScoreData.length = Boolean(pass.length >= PASSWORD_MIN_LENGTH)

      onPassScoreChange(score)
      setPassScoreData(currentPassScoreData)
    })

    await trigger('password')
  }

  return (
    <>
      <PasswordInputController
        control={control}
        name={'password'}
        id={'password'}
        label={t('password')}
        placeholder={t('password')}
        mb={'md'}
        translateParams={{ count: PASSWORD_MIN_LENGTH }}
        onChange={onPasswordChange}
      />

      {watchPassword && <PasswordStrengthBar mb={'md'} score={passScore} />}

      <p>Password must contain:</p>
      <ul>
        <li className={classNames({ [styles.valid]: passScoreData.lowercase })}>
          Lowercase character
        </li>
        <li className={classNames({ [styles.valid]: passScoreData.uppercase })}>
          Uppercase character
        </li>
        <li className={classNames({ [styles.valid]: passScoreData.number })}>Number</li>
        <li className={classNames({ [styles.valid]: passScoreData.symbol })}>Symbol</li>
      </ul>

      <PasswordInputController
        control={control}
        name={'password_confirmation'}
        id={'password_confirmation'}
        label={t('confirm_password')}
        placeholder={t('confirm_password')}
        mb={'md'}
        translateParams={{ count: PASSWORD_MIN_LENGTH }}
      />
    </>
  )
}

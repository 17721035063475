import { TFunction } from 'i18next'
import i18n from '@/i18n'
import { DATE } from '@/services'

class LocaleService {
  constructor() {
    this.initLocale()
  }

  initLocale = (t?: TFunction): void => {
    this.setLocale(i18n.language)
  }

  setLocale = (code: string) => {
    DATE.setLocale(code)
  }

  changeLang = async (code: string) => {
    this.setLocale(code)

    await i18n.changeLanguage(code)
  }
}

export const LOCALE = new LocaleService()

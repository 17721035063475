import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Flex, SimpleGrid } from '@mantine/core'
import { Button, DataShower, NoDataTextMessage } from '@/components/Elements'
import { TariffsPeriodSwitch } from '@/features/billing/components/Elements'
import { SubscriptionChange } from '@/features/billing/components/Subscription'
import { GeneralCard } from '@/features/billing/components/Tariff/Tariffs/Cards'
import {
  useTariffStaticOptions,
  useTariffsList,
  useTariffsPeriodGrid,
} from '@/features/billing/hooks'
import {
  useBillingTariffsPayUrlQuery,
  useLazyBillingTariffsPayUrlQuery,
} from '@/features/billing/store'
import { TariffModel } from '@/features/billing/types'
import { useUserSubscription } from '@/features/user/hooks'
import { usePopup } from '@/hooks'
import { PATH, PATH_BILLING } from '@/routes/consts'
import useStyles from './SubscriptionPlans.styles'

export const SubscriptionPlans = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [currentTarriff, setCurrentTarrif] = useState<any>(null)
  const [loadingPayUrl, setLoadingPayUrl] = useState(false)

  const { classes } = useStyles()

  const { list, fetched, loading, error } = useTariffsList({
    options: { skip: false },
  })

  const {
    data,
    refetch,
    error: optionError,
    isError,
    isSuccess,
  } = useBillingTariffsPayUrlQuery({ tariff_id: currentTarriff?.id })

  const { isTest } = useTariffStaticOptions()

  const { subscription, isSubscriptionTariffAllow } = useUserSubscription()

  const { period, onPeriodChange, data: tariffs } = useTariffsPeriodGrid({ tariffs: list })

  const [billingTariffsPayUrl] = useLazyBillingTariffsPayUrlQuery()

  const onSubscribe = async (tariff: TariffModel) => {
    try {
      setLoadingPayUrl(true)
      const payUrl = await billingTariffsPayUrl({ tariff_id: tariff?.id }).unwrap()

      window.open(payUrl, '_blank')
    } catch (e) {
      console.log('PayUrl Error: ', e)
    } finally {
      setLoadingPayUrl(false)
    }
  }

  useEffect(() => {
    const current = list.find((tariff) => tariff?.id === subscription?.tariff?.id)

    setCurrentTarrif(current || list?.[0])
  }, [list])

  useEffect(() => {
    refetch()
  }, [currentTarriff])

  const handlePeriodChange = (e: any) => {
    const current = list.find((tariff) => tariff?.period === e)

    setCurrentTarrif(current)
    onPeriodChange(e)
  }

  const renderTariffAction = (tariff: TariffModel) => {
    const current = tariff?.id === subscription?.tariff?.id

    return (
      <Button
        fullWidth
        disabled={!isSubscriptionTariffAllow(tariff)}
        onClick={() => onSubscribe(tariff)}
      >
        {current ? t('current_plan') : t('get_subscription')}
      </Button>
    )
  }

  const swapPopup = usePopup()

  const onSwapClose = () => {
    swapPopup.close()
  }

  const onSwapSubmitSuccess = () => {
    onSwapClose()
  }

  const onSwap = (tariff: TariffModel) => {
    swapPopup.open(tariff)
  }

  return (
    <>
      <div>
        <Container className={classes.wrapper} size={1400}>
          <div className={classes.inner}>
            <Container py={20} size={800}>
              <DataShower
                isLoading={loading || loadingPayUrl}
                isFetched={fetched}
                isFailed={!!error}
                error={error}
              >
                <Flex direction="column" align="center">
                  <Box mb={'xl'}>
                    <TariffsPeriodSwitch value={period} onChange={handlePeriodChange} />
                  </Box>

                  {tariffs.length > 0 ? (
                    <SimpleGrid
                      className={'w-full'}
                      cols={tariffs.length}
                      spacing={120}
                      breakpoints={[
                        { maxWidth: 900, cols: tariffs.length, spacing: 40 },
                        { maxWidth: 800, cols: 1, verticalSpacing: 10 },
                      ]}
                    >
                      {tariffs.map((item) => (
                        <React.Fragment key={item.id}>
                          <GeneralCard data={item} actionSections={renderTariffAction(item)} />
                        </React.Fragment>
                      ))}
                    </SimpleGrid>
                  ) : (
                    <NoDataTextMessage variant={'data'} />
                  )}
                </Flex>
              </DataShower>
            </Container>
          </div>
        </Container>
      </div>

      <SubscriptionChange
        tariff={swapPopup.data}
        visible={swapPopup.visible}
        onClose={onSwapClose}
        onSuccess={onSwapSubmitSuccess}
      />
    </>
  )
}

export const EN = {
  welcome: 'Welcome',
  login: 'Login',
  sign_up: 'Sign Up',
  register: 'Register',
  registration: 'Registration',
  'message.error-fallback': 'Error occurred',
  refresh: 'Refresh',
  'email_verification.success': 'Verification passed successfully',
  welcome_back: 'Welcome back',
  dont_have_account_yet: 'Don’t have an account yet',
  create_an_account: 'Create an account',
  or: 'or',
  'field.error.required': 'This field is required',
  'field.error.invalid': 'This field is invalid',
  'field.error.password.length': 'The password must be at least {{count}} characters',
  'field.error.password.no_match': 'Passwords do not match',
  'field.error.password.npt_strong': 'The password not strong enough',
  'field.error.min.length': 'The field must be at least {{count}} characters',
  error: 'Error',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm password',
  forgot_password: 'Forgot password',
  already_have_account: 'Already have an account',
  required: 'Required',
  reset_password: 'Reset password',
  'forgot-password.hint': 'Enter your email for a password reset link.',
  back_to_login_page: 'Back to login page',
  send_reset_link: 'Send reset link',
  remember_it: 'Remember it',
  reset: 'Reset',
  'reset-password.hint': 'Enter a new password',
  set_new_password: 'Set new password',
  back_to: 'Back to',
  accept: 'Accept',
  terms_and_conditions: 'Terms and conditions',
  licence_agreement: 'Licence Agreement',
  privacy_policy: 'Privacy Policy',
  logout: 'Logout',
  name: 'Name',
  'confirmation-email-sent': 'A confirmation email has been sent to your email',
  send_confirmation_email_to: 'Send a confirmation email to',
  success: 'Success',
  back: 'Back',
  next: 'Next',
  first_name: 'First name',
  last_name: 'Last name',
  no_options: 'No options',
  description: 'Description',
  start_date: 'Start date',
  end_date: 'End date',
  add: 'Add',
  saved_successfully: 'Saved successfully',
  phone: 'Phone',
  upload_photo: 'Upload photo',
  delete_photo: 'Delete photo',
  delete: 'Delete',
  'file.max_size.error': 'The file must not be greater than {{text}}',
  deleted_successfully: 'Deleted successfully',
  profile: 'Profile',
  yes: 'Yes',
  no: 'No',
  empty: 'Empty',
  not_filled: 'Not filled',
  no_data: 'No data',
  edit_profile: 'Edit profile',
  details: 'Details',
  cancel: 'Cancel',
  save: 'Save',
  skill_already_exist: 'Skill already exist',
  no_info: 'No info',
  ask_question: 'Ask a document a question',
  from: 'From',
  to: 'To',
  'field.error.date.after-or-equal': 'The value must be a date after or equal to {{date}}.',
  today: 'Today',
  'field.error.date.after': 'The value must be a date after {{date}}.',
  status: 'Status',
  pending: 'Pending',
  active: 'Active',
  collapse: 'Collapse',
  expand: 'Expand',
  date: 'Date',
  confirmation: 'Confirmation',
  please_confirm: 'Please confirm',
  view_document: 'View',
  confirm: 'Confirm',
  sent_successfully: 'Sent successfully',
  action: 'Action',
  'field.error.time.after': 'The value must be a time after {{date}}.',
  removed_successfully: 'Removed successfully',
  settings: 'Settings',
  password_changed_successfully: 'Password changed successfully',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  you: 'You',
  attachments: 'Attachments',
  no_messages: 'No messages',
  unread_messages: 'Unread messages',
  write_a_message: 'Write a message',
  load_more: 'Load more',
  new_message: 'New message',
  download: 'Download',
  pay: 'Pay',
  invoice: 'Invoice',
  home: 'Home',
  register_terms_label: 'I have read and accept the',
  register_terms_text: 'terms of service and conditions',
  dashboard: 'Dashboard',
  analyze_document: 'Analyze document',
  your_library: 'Your library',
  general_info: 'General info',
  deactivation: 'Deactivation',
  delete_account: 'Delete account',
  account_deleted_successfully: 'Account deleted successfully',
  dark: 'Dark',
  light: 'Light',
  'color-switch-to': 'Switch to {{ scheme }}',
  pricing: 'Pricing',
  start_now: 'Start now',
  all_rights_reserved: 'All rights reserved',
  monthly: 'Monthly',
  annually: 'Annually',
  clear_history: 'Clear history',
  inactive: 'Inactive',
  select_language: 'Select language',
  select_language_info: 'To help you analyse this document, please select your preferred language',
  rules_description: `By uploading this document you agree that review.legal is not a law firm and as such any information provided should not be considered a replacement for professional legal advice.`,
  incomplete_payment: 'Incomplete payment',
  trialing: 'Trialing',
  document_deleted: 'Document deleted',
  delete_document: 'Delete document',
  success_export: 'Successfully exported',
  query_tab: 'Query',
  query_prompts: 'Query prompts',
  suggestions: 'Suggestions',
  summary_tab: 'Summary',
  admin_prompt_name: 'Prompt name',
  admin_main_tab: 'Main quiestion prompt',
  admin_summarization_prompt_tab: 'Summarization prompt',
  document_uploading: 'Document is uploading!',
  document_rejected: 'Document rejected',
  document_uploaded: 'Document is now processing, this may take a few minutes.',
  successfully_copied: 'Successfully copied!',
  required_variables: 'Required variables*',
  copy: 'Copy',
  copy_error: 'Copy is unavailable',
  admin_prompt_text: 'Prompt text',
  last_viewed_at: 'Last viewed',
  uploaded_at: 'Uploaded',
  on_grace_period: 'On grace period',
  canceled: 'Canceled',
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete expired',
  past_due: 'Past due',
  paused: 'Paused',
  unpaid: 'Unpaid',
  subscription_plans: 'Subscription plans',
  month: 'Month',
  year: 'Year',
  subscribe: 'Subscribe',
  billing: 'Billing',
  current_plan: 'Current plan',
  subscription_plan: 'Subscription plan',
  subscription_status: 'Subscription status',
  billed_period: 'Billed period',
  current_period_ends_at: 'Current period ends at',
  general: 'General',
  prompt_text: 'Prompt Text',
  update_language: 'Update language',
  admin_clause_tab: 'Summary tab',
  admin_query_tab: 'Query tab',
  admin_summary_tab: 'Main summary prompt',
  history_deleted: 'History deleted',
  language_updated: 'Language updated',
  prompts_limit: 'Limit of maximum query prompts. Maximum 10 prompts.',
  prompt_name: 'Prompt Name',
  send_message: 'Send message',
  all_query_prompts: 'Query Prompts',
  prompts: 'Prompts',
  approve_delete_text: 'Are you sure to delete',
  prompts_deleted_success: 'Prompt successfully deleted!',
  'attach-files': 'Attach files',
  message: 'Message',
  create_new_prompt: '+ Add New',
  send: 'Send',
  create: 'Create',
  created_at: 'Created At',
  object: 'Object',
  text: 'Text',
  'field.error.file-size.max': 'The file must not be greater than {{text}}',
  send_successfully: 'Send successfully',
  prompts_create_success: 'New Prompt successfully created!',
  prompts_updated_success: 'New Prompt successfully updated!',
  limits_updated_success: 'Limits successfully updated!',
  view_profile: 'View profile',
  attachment: 'Attachment',
  'attached-file': 'Attached file',
  verified: 'Verified',
  unverified: 'Unverified',
  card: 'Card',
  sepa: 'SEPA',
  your_cards: 'Your cards',
  new_card: 'New card',
  new_sepa: 'New SEPA',
  payment: 'Payment',
  price: 'Price',
  payment_information: 'Payment information',
  billing_information: 'Billing information',
  'billing_info.editable-alert':
    'Important! You cannot edit "Country", "VAT ID" after subscribing.',
  address: 'Address',
  city: 'City',
  zip: 'ZIP',
  country: 'Country',
  vat_id: 'VAT ID',
  note: 'Note',
  edit: 'Edit',
  company: 'Company',
  free: 'Free',
  information: 'Information',
  iban: 'IBAN',
  card_number: 'Card number',
  expiry_date: 'Expiry date',
  payment_system: 'Payment system',
  default: 'Default',
  payment_methods: 'Payment methods',
  payment_method: 'Payment method',
  add_payment_method: 'Add payment method',
  cvc: 'CVC',
  'billing.pay.subscription.success':
    'The payment was successful. Subscription will be issued shortly',
  ends_at: 'Ends at',
  resume: 'Resume',
  change_subscription_plan: 'Change subscription plan',
  'billing.incomplete_payment.message':
    'To activate the subscription, you must complete the payment',
  complete_payment: 'Complete payment',
  payment_was_successful: 'The payment was successful',
  payment_error: 'Payment error',
  vat_id_verified: 'VAT ID verified',
  vat_id_unverified: 'VAT ID unverified',
  subscribed_successfully: 'Subscribed successfully',
  subscription_plan_changed: 'Subscription plan changed',
  subscribed_canceled: 'Subscription canceled',
  subscribed_resumed: 'Subscription resumed',
  invoices: 'Invoices',
  latest_invoices: 'Latest invoices',
  number: 'Number',
  amount: 'Amount',
  billed_to: 'Billed to',
  vat_code: 'VAT code',
  'number-no_abbreviation': 'No.',
  summary: 'Summary',
  quantity: 'Quantity',
  subtotal: 'Subtotal',
  total: 'Total',
  vat: 'VAT',
  profile_settings: 'Profile settings',
  personal_data_settings: 'Personal data settings',
  confidential_info_settings: 'Confidential info settings',
  personal_data: 'Personal data',
  confidential_info: 'Confidential info',
  usage_info: 'My usage',
  month_usage_info: 'Calendar month usage of',
  usage_info_docs: 'Number of documents uploaded',
  usage_info_pages: 'Number of pages uploaded',
  usage_info_reqs: 'Number of requests made',
  usage_info_requests: 'Number of requests',
  usage_info_tokens_input: 'Number of tokens input',
  usage_info_tokens_output: 'Number of tokens output',
  total_usage_info: 'Total usage of',
  male: 'Male',
  female: 'Female',
  document_processed: 'Document processed',
  prefix: 'Prefix',
  documentUploaded: 'Document uploaded',
  input_tokens: 'Input tokens',
  output_tokens: 'Output tokens',
  overview_input_tokens: 'Overview input tokens',
  overview_output_tokens: 'Overview output tokens',
  query_tab_input_tokens: 'Query tab input tokens',
  query_tab_output_tokens: 'Query tab output tokens',
  query_tab_requests: 'Query tab requests',
  requests: 'Requests',
  summary_processing_input_tokens: 'Summary processing input tokens',
  summary_processing_output_tokens: 'Summary processing output tokens',
  summary_tab_input_tokens: 'Summary tab input tokens',
  summary_tab_output_tokens: 'Summary tab output tokens',
  summary_tab_requests: 'Summary tab requests',
  mr: 'Mr',
  mrs: 'Mrs',
  phone_number: 'Phone number',
  upload_logo: 'Upload logo',
  delete_logo: 'Delete logo',
  user: 'User',
  what_included: 'What’s included',
  email_verification: 'Email verification',
  go_to_login_page: 'Go to login page',
  'forgot_password.success': 'We have emailed your password reset link!',
  'reset_password.success': 'Your password has been reset!',
  modified_on: 'Modified on',
  'field.error.digits_between': 'The field must be between {{min}} and {{max}} digits',
  'field.error.company_uid.rule':
    'The field must starts with 2-4 letters and ends with 8-12 numbers',
  'field.error.less_or_equal': 'The field must be less than or equal to {{value}}',
  'field.error.greater_or_equal': 'The field must be greater than or equal to {{value}}',
  update: 'Update',
  'field.error.array.min.length': 'The field must contain at least {{value}} value',
  no_data_found_matching_conditions: 'No data found matching the conditions',
  apply: 'Apply',
  search: 'Search',
  get_subscription: 'Get subscription',
  subscriptions: 'Subscriptions',
  users: 'Users',
  deactivated: 'Deactivated',
  registration_date: 'Registration date',
  edit_user: 'Edit user',
  deactivate: 'Deactivate',
  activate: 'Activate',
  restore: 'Restore',
  deleted: 'Deleted',
  free_limits: 'Free limits',
  standart_limits: 'Standart limits',
  pro_limits: 'Pro limits',
  number_month_requests: 'Number month requests',
  number_month_documents: 'Number month documents',
  number_gpt_tokens: 'Number gpt tokens',

  usage_documents_label: 'usage documents',
  usage_query_tab_requests_label: 'usage query tab requests',
  usage_query_tab_input_tokens_label: 'usage query tab input tokens',
  usage_query_tab_output_tokens_label: 'usage query tab output tokens',
  usage_summary_tab_requests_label: 'usage summary tab requests',
  usage_summary_tab_input_tokens_label: 'usage summary tab input tokens',
  usage_summary_tab_output_tokens_label: 'usage summary tab output tokens',
  usage_summary_processing_input_tokens_label: 'usage summary processing input tokens',
  usage_summary_processing_output_tokens_label: 'usage summary processing output tokens',
  usage_overview_input_tokens_label: 'usage overview input tokens',
  usage_overview_output_tokens_label: 'usage overview output tokens',
  usage_docs_label: 'number of documents uploaded',
  usage_tokens_input_label: 'number of input tokens used',
  usage_tokens_output_label: 'number of output tokens used',
  usage_requests_label: 'number of requests made',
  month_pre_label: 'Month',
  total_pre_label: 'Total',

  month_usage_title: 'Calendar month usage of:',
  month_usage_docs_label: 'Number of documents uploaded',
  month_usage_tokens_input_label: 'Number of input tokens used',
  month_usage_tokens_output_label: 'Number of output tokens used',
  month_usage_requests_label: 'Number of requests made',
  'in processing': 'Processing',
  total_usage_title: 'Total Usage of',
  total_usage_docs_label: 'Number of documents uploaded',
  total_usage_tokens_input_label: 'Number of input tokens used',
  total_usage_tokens_output_label: 'Number of output tokens used',
  admin_overview_prompt_tab: 'Overview prompt',
  total_usage_requests_label: 'Number of requests made',
  limits: 'Limits',
  total_accounts: 'total accounts',
  total_documents: 'total documents',
  total_input_tokens: 'total input tokens',
  total_output_tokens: 'total output tokens',
  total_requests: 'total requests',
  export_users_csv: 'Export Users (CSV)',
  overview_tab: 'Overview',
  document_summary: 'Document summary',
  effective_date: 'Effective Date',
  document_type: 'Document Type',
  duration: 'Duration',
  jurisdiction: 'Jurisdiction',
  key_provisions: 'Key Provisions',
  obligations_and_conditions: 'Obligations and Conditions',
  payment_terms: 'Payment Terms',
  intellectual_property: 'Intellectual Property',
  terminations_and_remedies: 'Terminations and Remedies',
  data_privacy_terms: 'Data Privacy Terms',
  legal_implications_and_concerns: 'Legal Implications and Concerns',
}

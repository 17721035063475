import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUsersData } from '@/features/users/hooks'
import { useUsersQueryMutations } from '@/features/users/hooks'
import { UpdateUserActiveDTO, UpdateUserDTO } from '@/features/users/types'

export const useUsersDataQueryMutations = () => {
  const { t } = useTranslation()

  const { user, onSetUserData, setAvatar } = useUsersData({})

  const id = user?.id

  const { restore, onUpdate: onUserUpdate, avatar, active, remove } = useUsersQueryMutations()

  // === Update === //
  const onUpdate = async (params: UpdateUserDTO) => {
    const data = await onUserUpdate({ id, params, options: { invalidatesTagsById: false } })

    onSetUserData(data)
  }
  // === //

  // === Restore === //
  const onRestore = async () => {
    await restore.onRestore(id)
  }
  // === //

  // === Delete === //
  const onRemove = async () => {
    await remove.onRemove(id)
  }
  // === //

  // === Avatar === //
  const onAvatarUpload = async (file: File) => {
    const data = await avatar.onUpload({ id, params: { avatar: file } })

    const payload = {
      data,
    }

    await setAvatar(payload)
  }

  const onAvatarRemove = async () => {
    await avatar.onRemove(id)

    await setAvatar(null)
  }
  // === //

  // === Active === //
  const onActiveUpdate = async (params: UpdateUserActiveDTO) => {
    const data = await active.onActiveUpdate({
      id,
      params,
      options: { invalidatesTagsById: false },
    })

    if (data) {
      onSetUserData(data)
    }
  }
  // === //

  return {
    onUpdate,
    restore: {
      ...restore,
      onRestore,
    },
    avatar: {
      ...avatar,
      onUpload: onAvatarUpload,
      onRemove: onAvatarRemove,
    },
    active: {
      ...active,
      onActiveUpdate,
    },
    remove: {
      ...remove,
      onRemove,
    },
  }
}

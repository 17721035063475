import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { UserEditShell } from '@/features/users/components/User/Layouts'
import { PATH_ADMIN_USERS_EDIT } from '@/routes/consts'
import { Profile } from '../profile'
import { Security } from '../security'

const { PROFILE, SECURITY } = PATH_ADMIN_USERS_EDIT

export const UserEditRoot = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={PROFILE} replace={true} />} />

      <Route element={<UserEditShell />}>
        <Route path={PROFILE} element={<Profile />} />
        <Route path={SECURITY} element={<Security />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'../..'} replace={true} />} />
    </Routes>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralSection } from './GeneralSection'

export const UserProfileContainer = () => {
  const { t } = useTranslation()

  return (
    <>
      <GeneralSection />
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { PasswordForm } from '@/features/users/components/Forms'
import { useUsersData } from '@/features/users/hooks'
import { useUsersUpdatePasswordMutation } from '@/features/users/store'
import { useNotify } from '@/hooks'

export const PasswordContainer = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [updatePassword] = useUsersUpdatePasswordMutation()

  const { user } = useUsersData({})

  const onSubmit = async (values: any) => {
    await updatePassword({ id: user?.id, params: values }).unwrap()

    showNotification({
      type: 'success',
      message: t('password_changed_successfully'),
    })
  }

  return (
    <>
      <Title mb={'xs'} order={4}>
        {t('change_password')}
      </Title>

      <PasswordForm withCurrent={false} onSubmit={onSubmit} />
    </>
  )
}

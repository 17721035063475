import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box } from '@mantine/core'
import { Icon, NavLink } from '@/components/Elements'
import { PATH, PATH_ADMIN } from '@/routes/consts'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  const locationPathname = location.pathname

  const isRouteActive = useCallback(
    (toPathname: string) => {
      return (
        locationPathname === toPathname ||
        (locationPathname.startsWith(toPathname) &&
          locationPathname.charAt(toPathname.length) === '/')
      )
    },
    [locationPathname]
  )

  const menu = [
    {
      label: t('users'),
      to: `/${PATH.ADMIN}/${PATH_ADMIN.USERS}`,
      icon: <Icon name={'person'} />,
    },
    {
      label: t('prompts'),
      to: `/${PATH.ADMIN}/${PATH_ADMIN.PROMPTS}`,
      icon: <Icon name={'list-ul'} />,
    },
    {
      label: t('limits'),
      to: `/${PATH.ADMIN}/${PATH_ADMIN.LIMITS}`,
      icon: <Icon name={'list-ul'} />,
    },
  ]

  return (
    <>
      <Box>
        {menu.map(({ label, to, icon }, index) => (
          <NavLink
            active={isRouteActive(to)}
            key={index}
            label={label}
            component={Link}
            to={to}
            onClick={onLinkClick}
            icon={icon}
          />
        ))}
      </Box>
    </>
  )
}

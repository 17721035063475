import { createStyles, rem } from '@mantine/core'

export interface StylesParams {
  height: number
}

export default createStyles((theme, _params: StylesParams) => {
  const dark = theme.colorScheme === 'dark'

  return {
    main: {},

    container: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  }
})

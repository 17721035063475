import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { TableView } from '@/components/Elements'
import { DateUtils } from '@/utils'
import { DeletePromptsModal } from './DeletePromptModal'
import { UpdatePromptsModal } from './UpdatePromptModal'

type Key = 'name' | 'text' | 'created_at' | 'action'

interface IProps {
  keys: Key[]
  items: any[]
  loading?: boolean
  actionProps?: any
  refetch?: any
  canDelete?: boolean
}

export const PromptsTable = ({
  keys = [],
  canDelete,
  items,
  refetch,
  loading,
  actionProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    name: {
      accessorKey: 'name',
      id: 'name',
      header: t('prompt_name'),
      cell: (info: any) => info.getValue() || '-',
    },
    text: {
      accessorKey: 'text',
      id: 'text',
      header: t('prompt_text'),
      cell: (info: any) => info.getValue() || '-',
    },
    created_at: {
      accessorKey: 'created_at',
      id: 'created_at',
      header: t('created_at'),
      cell: (info: any) => (info.getValue() ? DateUtils.formatDate(info.getValue()) : '-'),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: (info: any) => (
        <div className={'flex justify-end'}>
          <UpdatePromptsModal prompt={info?.row?.original} refetch={refetch} />
          <Space w="md" />
          {canDelete && <DeletePromptsModal prompt={info?.row?.original} refetch={refetch} />}
        </div>
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentContainer } from '@/components/Layouts/Elements'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { Tabs } from './Tabs'

export const UserEditShell = () => {
  const { t } = useTranslation()

  return (
    <GeneralPanelPage
      header={
        <>
          <GeneralTopBar title={t('edit_user')} back />
          <Tabs />
        </>
      }
    >
      <div>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </div>
    </GeneralPanelPage>
  )
}

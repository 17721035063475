import { rootApi } from '@/store/api'
import { UpdateLimitDTO } from '@/features/limits/types'

export const limitsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    limitsUpdate: builder.mutation<any, UpdateLimitDTO>({
      query: (arg: any) => ({
        url: `billing/tariffs/${arg.id}`,
        method: 'PATCH',
        body: arg,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    limitsEntries: builder.query<any, any>({
      query: () => ({
        url: `billing/tariffs`,
      }),
    }),
    limitsAdminEntries: builder.query<any, any>({
      query: () => ({
        url: `billing/admin-tariffs`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useLimitsUpdateMutation, useLimitsEntriesQuery, useLimitsAdminEntriesQuery } =
  limitsApi

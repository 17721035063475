import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, SimpleGrid, Text } from '@mantine/core'
import {
  CancelControl,
  ResumeControl,
} from '@/features/billing/components/Subscription/ActionControls'
import { useSubscriptionStaticOptions, useTariffStaticOptions } from '@/features/billing/hooks'
import { useUserSubscription } from '@/features/user/hooks'
import { DateUtils } from '@/utils'

export const SubscriptionOverview = () => {
  const { t } = useTranslation()

  const { subscription, isOnGracePeriod } = useUserSubscription()

  const status = subscription?.stripe_status

  const { getPeriod } = useTariffStaticOptions()

  const { getStatus, isActiveStatus } = useSubscriptionStaticOptions()

  const items = [
    {
      label: t('subscription_plan'),
      value: subscription ? subscription?.name : t('free'),
    },
    {
      label: t('subscription_status'),
      value: status && getStatus(status)?.label,
      rightSection: isOnGracePeriod() ? (
        <ResumeControl />
      ) : status && isActiveStatus(status) ? (
        <CancelControl />
      ) : null,
      hidden: !subscription,
    },
    {
      label: t('billed_period'),
      value: subscription && getPeriod(subscription?.tariff?.period)?.label,
      hidden: !(subscription && subscription.tariff?.period),
    },
    {
      label: t('current_period_ends_at'),
      value: subscription && DateUtils.formatDate(subscription.current_period_ends_at),
      hidden: !(subscription && subscription.current_period_ends_at),
    },
    {
      label: t('ends_at'),
      value: subscription && DateUtils.formatDate(subscription.ends_at),
      hidden: !(subscription && subscription.ends_at),
    },
  ]

  return (
    <SimpleGrid verticalSpacing={'xl'}>
      {items
        .filter((item) => !item.hidden)
        .map(({ label, value, rightSection }, index) => (
          <div key={index}>
            <Box pb={'sm'} className={'flex grow gap-4 flex-wrap'}>
              <div className={'flex grow flex-col justify-center'}>
                <Text fw={700}>{label}</Text>
                <Text fz={'sm'}>{value}</Text>
              </div>

              {rightSection && <div className={'flex items-center'}>{rightSection}</div>}
            </Box>

            <Divider />
          </div>
        ))}
    </SimpleGrid>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Box, Space } from '@mantine/core'
import { ContentSection, MegaTagTitle } from '@/components/Elements'
import { ContentContainer } from '@/components/Layouts/Elements'
import { BillingAddressUpdate } from '@/features/billing/components/BillingAddress'
import { PaymentContainer, PaymentInfo } from '@/features/billing/components/Payment'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { StripeElementsProvider } from '@/features/stripe/components'

export const Payment = () => {
  const { t } = useTranslation()

  const location = useLocation()

  return (
    <>
      <MegaTagTitle title={'payment'} />

      <GeneralPanelPage header={<GeneralTopBar back title={t('payment')} />}>
        <div>
          <ContentContainer>
            <PaymentInfo data={location.state?.paymentInfo} />

            <Space h={'xl'} />

            <Box mt={'xl'}>
              <ContentSection title={t('billing_information')}>
                <BillingAddressUpdate disabledMode />
              </ContentSection>
            </Box>

            <Box mt={'lg'}>
              {/*<StripeElementsProvider>*/}
              {/*  <PaymentContainer paymentInfo={location.state?.paymentInfo} />*/}
              {/*</StripeElementsProvider>*/}
            </Box>
          </ContentContainer>
        </div>
      </GeneralPanelPage>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { GeneralLimitsContainer } from '../../components/GeneralLimits'

export const Limits = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'limits'} />

      <GeneralLimitsContainer />
    </>
  )
}

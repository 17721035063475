import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TextProps, useMantineTheme } from '@mantine/core'

export const DefaultPaymentMethodBadge = (props: TextProps) => {
  const { t } = useTranslation()

  const theme = useMantineTheme()

  return (
    <>
      <Text fw={600} color={theme.colors[theme.primaryColor][4]} {...props}>
        {t('default')}
      </Text>
    </>
  )
}

import React from 'react'
import { Box, Title } from '@mantine/core'
import useStyles from './ContentSection.styles'

interface IProps {
  children: React.ReactNode
  title?: React.ReactNode
  titleProps?: any
}

export const ContentSection: React.FC<IProps> = ({ children, title, titleProps, ...props }) => {
  const { classes, cx } = useStyles()

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Title mb={'md'} order={4} {...titleProps}>
          {title}
        </Title>
      )
    }

    return title
  }

  return (
    <Box className={cx(classes.root)} {...props}>
      {title && renderTitle()}

      {children}
    </Box>
  )
}

import { rootApi } from '@/store/api'
import {
  ForgotPasswordDTO,
  LoginDTO,
  RefreshTokenDTO,
  RegisterDTO,
  ResetPasswordDTO,
  VerificationEmailDTO,
  VerifyEmailDTO,
} from '@/features/auth/types'
import { RTKQueryUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, LoginDTO>({
      query: (arg) => ({
        url: 'clients/web/login',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: cacher.invalidatesUnauthorized(),
    }),
    register: builder.mutation<any, RegisterDTO>({
      query: (arg) => ({
        url: 'register',
        method: 'POST',
        body: arg,
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: 'api/logout',
        method: 'POST',
      }),
    }),
    sendVerificationEmail: builder.mutation<any, VerificationEmailDTO>({
      query: (arg) => ({
        url: 'email/verification-notification',
        method: 'POST',
        body: arg,
      }),
    }),
    forgotPassword: builder.mutation<any, ForgotPasswordDTO>({
      query: (arg) => ({
        url: 'password/forgot',
        method: 'POST',
        body: arg,
      }),
    }),
    resetPassword: builder.mutation<any, ResetPasswordDTO>({
      query: (arg) => ({
        url: 'password/reset',
        method: 'POST',
        body: arg,
      }),
    }),
    refreshToken: builder.mutation<any, RefreshTokenDTO>({
      query: (arg) => ({
        url: 'clients/web/refresh',
        method: 'POST',
        body: arg,
      }),
      extraOptions: {
        withMutex: false,
      },
    }),
    verifyEmail: builder.query<any, VerifyEmailDTO>({
      query: ({ url, signature }) => ({
        url,
        params: { signature },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useSendVerificationEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLazyVerifyEmailQuery,
} = authApi

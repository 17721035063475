export const PATH = {
  HOME: 'home',
  LOGIN: 'login',
  REGISTER: 'register',
  EMAIL_VERIFICATION: 'email-verification',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  SUBSCRIPTIONS: 'subscriptions',
  LEGAL: 'legal',
  DASHBOARD: 'dashboard',
  DOCUMENT_VIEW: 'document-view/:id',
  PROFILE: 'profile',
  BILLING: 'billing',
  SETTINGS: 'settings',
  ADMIN: 'admin',
  ULTRA_ADMIN: 'ultra-admin',
  THANK_PAGE: 'thank',
}

export const PATH_LEGAL = {
  TERMS: 'terms',
}

export const PATH_SETTINGS = {
  PROFILE: 'profile',
  SECURITY: 'security',
  USAGE: 'usage',
}

export const PATH_BILLING = {
  SUBSCRIPTIONS: 'subscriptions',
  BILLING_INFORMATION: 'billing-information',
  PAYMENT_METHODS: 'payment-methods',
  INVOICES: 'invoices',
  PAYMENT: 'payment',
}

export const PATH_ADMIN = {
  USERS: 'users',
  PROMPTS: 'prompts',
  LIMITS: 'limits',
}

export const PATH_ADMIN_USERS = {
  EDIT: 'edit',
}

export const PATH_ADMIN_USERS_EDIT = {
  PROFILE: 'profile',
  SECURITY: 'security',
}

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { UserDataLayout } from '@/features/users/components/User'
import { Prompts } from '../prompts/Prompts'

export const PromptsRoot = () => {
  return (
    <Routes>
      <Route index element={<Prompts />} />
      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    loaderBlock: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    overlayBlock: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 0,
    },
  }
})

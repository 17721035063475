import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { CheckboxFieldController } from '@/components/FormElements'
import { PasswordFormSection } from '@/features/auth/components/FormSections'
import { GeneralFormSection } from '@/features/profile/components/FormSections'
import { useFormSubmit } from '@/hooks'
import { PATH, PATH_LEGAL } from '@/routes/consts'
import { validationSchema } from './validation'

export type FormValues = {
  name: string
  last_name: string
  email: string
  company: string
  password: string
  password_confirmation: string
  terms: boolean
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [passScore, setPassScore] = useState(0)

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      last_name: '',
      email: '',
      company: '',
      password: '',
      password_confirmation: '',
      terms: false,
    },
    resolver: yupResolver(validationSchema as any),
    context: { passScore },
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const isLegalAccepted = watch('terms')

  const legalControlConfig: any[] = [
    {
      name: 'terms',
      label: (
        <>
          {t('accept')}{' '}
          <Anchor
            target={'_blank'}
            component={Link}
            to={`https://reviewlegal.framer.website/terms`}
            className={'font-bold'}
            state={{ from: `/${PATH.REGISTER}` }}
          >
            {t('terms_and_conditions')}
          </Anchor>
        </>
      ),
    },
  ]

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <GeneralFormSection />

        <PasswordFormSection passScore={passScore} onPassScoreChange={setPassScore} />

        <Space h={'xl'} />

        {legalControlConfig.map(({ name, label }) => (
          <CheckboxFieldController
            key={name}
            control={control}
            name={name}
            id={name}
            label={label}
            mb={'md'}
          />
        ))}

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} disabled={!isLegalAccepted} loading={isSubmitting}>
            {t('register')}
          </Button>
        </Group>
      </form>
    </FormProvider>
  )
}

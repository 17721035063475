import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar, ScrollArea, Space } from '@mantine/core'
import { GeneralLinks } from './GeneralLinks'

interface IProps {
  onNavLinkClick?: () => void
}

export const NavbarContent = ({ onNavLinkClick }: IProps) => {
  const { t } = useTranslation()

  const onLinkClick = () => {
    onNavLinkClick && onNavLinkClick()
  }

  return (
    <>
      <Navbar.Section grow component={ScrollArea}>
        <Space h={'xs'} />
        <GeneralLinks onLinkClick={onLinkClick} />
        <Space h={'xs'} />
      </Navbar.Section>
    </>
  )
}

import { rootApi } from '@/store/api'
import { TariffModel } from '@/features/billing/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'
import { tempData } from './tempData'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingTariffs'] })

export const billingTariffsApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingTariffsList: builder.query<TariffModel[], any>({
      query: () => ({
        url: `billing/tariffs`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingTariffsPayUrl: builder.query<any, any>({
      query: (arg) => ({
        url: `billing/tariffs/${arg.tariff_id}/url`,
      }),
      transformResponse: (response: any) => {
        return response?.url
      },
    }),
    billingOption: builder.query<any, any>({
      query: (params) => ({
        url: `billing/subscription`,
        params: RequestUtils.getMapRequestParams(params),
      }),
      transformResponse: (response: any) => {
        return response
      },
    }),
  }),
  overrideExisting: true,
})

export const {
  useBillingTariffsListQuery,
  useBillingOptionQuery,
  useLazyBillingTariffsPayUrlQuery,
  useBillingTariffsPayUrlQuery,
} = billingTariffsApi

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Elements'
import { useAppConfirmModal } from '@/features/app/hooks'
import { useBillingSubscriptionCancelMutation } from '@/features/billing/store'
import { useNotify } from '@/hooks'

export const CancelControl = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [cancel, { isLoading }] = useBillingSubscriptionCancelMutation()

  const onConfirm = async () => {
    try {
      await cancel().unwrap()

      showNotification({
        type: 'success',
        message: t('subscribed_canceled'),
      })
    } catch (error: any) {
      showNotification({
        type: 'error',
        message: error?.data?.message || t('error'),
      })
    }
  }

  const { open } = useAppConfirmModal({ onConfirm })

  return (
    <>
      <Button
        size={'xs'}
        variant={'outline'}
        color={'dark'}
        compact
        onClick={() => open()}
        loading={isLoading}
      >
        {t('cancel')}
      </Button>
    </>
  )
}

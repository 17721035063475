import classNames from 'classnames'
import Pusher from 'pusher-js'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { BsFiletypeDocx } from 'react-icons/bs'
import { MdOutlineArrowUpward } from 'react-icons/md'
import { Link } from 'react-router-dom'
import {
  Card,
  Group,
  Image,
  Input,
  Button as MantineButton,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Text,
  Title,
} from '@mantine/core'
import MSIcon from '@/assets/images/icons/microsoftWordIcon.png'
import { Icon } from '@/components/Elements'
import {
  useDeleteDocumentMutation,
  useLoadDocumentLangsQuery,
  useLoadDocumentsQuery,
  useUpdateDocumentDataMutation,
  useUploadDocumentMutation,
} from '@/features/dashboard/store/api/slice'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { useUser } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import styles from './styles.module.scss'

const KEY = process.env.REACT_APP_PUSHER_KEY || 'KEY'
const CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER || 'CLUSTER'
const AUTH_URL = process.env.REACT_APP_BASE_URL + 'api/broadcasting/auth'

const pusher = new Pusher(KEY, {
  cluster: CLUSTER,
  authEndpoint: AUTH_URL,
})

export const DashboardContainer = () => {
  const { user } = useUser()
  const [uploadDocument] = useUploadDocumentMutation()
  const [deleteDocument] = useDeleteDocumentMutation()
  const [updateDocumentData] = useUpdateDocumentDataMutation()

  const [text, setText] = useState('')
  const [page, setPage] = useState(1)
  const [deleteFile, setDeleteFile] = useState<any>(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const [updateFile, setUpdateFile] = useState<any>(null)
  const [updateModal, setUpdateModal] = useState(false)

  type ISortedBy = 'asc' | 'desc'
  type IOrderBy = 'viewed_at' | 'id' | 'created_at' | 'updated_at' | 'file_name' | 'uploaded_at'

  const [sortedBy, setSortedBy] = useState<ISortedBy>('desc')
  const [orderBy, setOrderBy] = useState<IOrderBy>('id')

  const { showNotification } = useNotify()

  const langData = useLoadDocumentLangsQuery({})

  const handleUpdateSorting = (orderKey: IOrderBy) => {
    if (orderBy === orderKey) {
      if (sortedBy === 'asc') {
        setSortedBy('desc')
        return
      }
      setSortedBy('asc')
      return
    }

    setOrderBy(orderKey)
    setPage(1)
  }

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } = useLoadDocumentsQuery(
    {
      params: { page, sortedBy, orderBy },
    }
  )

  useEffect(() => {
    const channel = pusher.subscribe(`user.${user.id}`)

    channel.bind('document-uploaded', (data: any) => {
      refetch()

      showNotification({
        type: 'success',
        message: t('document_processed'),
        autoClose: 60000,
      })
    })

    return () => {
      pusher.unsubscribe(`user.${user.id}`)
    }
  }, [])

  const handleDelete = (element: any) => {
    setDeleteFile(element)
    setDeleteModal(true)
  }

  const handleUpdate = (element: any) => {
    setUpdateFile(element)
    setUpdateModal(true)
  }

  const handleChangeUpdate = (value: string) => {
    setUpdateFile({ ...updateFile, name: value })
  }

  const onDelete = async () => {
    try {
      if (!deleteFile?.id) return
      await deleteDocument(deleteFile.id)
      showNotification({
        type: 'success',
        message: t('document_deleted'),
      })
    } catch (e) {
      console.log(e)
    } finally {
      setDeleteFile(null)
      setDeleteModal(false)
      refetch()
    }
  }

  const onUpdate = async () => {
    try {
      if (!updateFile?.id) return
      await updateDocumentData(updateFile)
      showNotification({
        type: 'success',
        message: t('document_updated'),
      })
    } catch (e) {
      console.log(e)
    } finally {
      setUpdateFile(null)
      setUpdateModal(false)
      refetch()
    }
  }

  const cancelDelete = () => {
    setDeleteFile(null)
    setDeleteModal(false)
  }

  const cancelUpdate = () => {
    setUpdateFile(null)
    setUpdateModal(false)
  }

  useEffect(() => {
    refetch()
  }, [])

  const { t } = useTranslation()

  const completedStatus = 'processed'

  const rows = data?.data.map((element: any, idx: any) => (
    <tr key={`${element.name}_${idx}_${element.uploaded_at}`}>
      <td>{element.name}</td>
      <td>{element.uploaded_at}</td>
      <td>{element.viewed_at}</td>
      <td>{element.language}</td>
      <td>
        <Group>
          <MantineButton
            disabled={element?.status !== completedStatus}
            component={Link}
            to={`/document-view/${element.id}`}
          >
            {element?.status === completedStatus ? t('view_document') : t(`${element?.status}`)}
          </MantineButton>
          <MantineButton onClick={() => handleUpdate(element)}>
            <Icon name={'pencil'} />
          </MantineButton>
          <MantineButton variant="outline" onClick={() => handleDelete(element)}>
            <Icon name={'trash'} />
          </MantineButton>
        </Group>
      </td>
    </tr>
  ))

  function Dropzone(props: any) {
    const [disableDropzone, setDisableDropzone] = useState(false)

    const [myFiles, setMyFiles] = useState<any>([])
    const [isOpen, setIsOpen] = useState(false)
    const [language, setLanguage] = useState<any>('NGD6wyR2kO0v32x7')

    const onDrop = useCallback(
      (acceptedFiles: any) => {
        return setMyFiles([...myFiles, ...acceptedFiles])
      },
      [myFiles]
    )

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      maxFiles: 1,
      disabled: disableDropzone,
      onDrop,
    })

    const removeAll = () => {
      setMyFiles([])
    }

    const files = acceptedFiles.map((file: any) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ))

    const getError = (res: any) => {
      return res?.error?.data?.message
    }

    const handleUplodaFile = async () => {
      const formData = new FormData()
      formData.append('file', myFiles[0])
      formData.append('language', language)
      formData.append('inputType', 'file')

      try {
        showNotification({
          type: 'success',
          message: t('document_uploading'),
        })

        const res = await uploadDocument(formData)

        const error = getError(res)

        if (error) {
          showNotification({
            type: 'error',
            message: error || t('document_rejected'),
          })
        } else {
          showNotification({
            type: 'success',
            message: t('document_uploaded'),
            autoClose: 60000,
          })
        }
      } catch (e: any) {
        console.log('document: ', e)

        showNotification({
          type: 'error',
          message: e?.error?.data?.message || t('document_rejected'),
        })
      } finally {
        refetch()
        setDisableDropzone(false)
      }
    }

    const handleUpload = () => {
      setIsOpen(false)
      setDisableDropzone(true)
      handleUplodaFile()
      removeAll()
    }

    const cancelUpload = () => {
      setIsOpen(false)
      removeAll()
    }

    useEffect(() => {
      if (myFiles?.length > 0) {
        setIsOpen(true)
      }
    }, [myFiles])

    const sortLanguages = () => {
      const languages = langData?.data?.data || []

      if (languages?.length === 0) return []

      const sortedLanguages = languages.slice()

      return sortedLanguages
        .sort(function (a: any, b: any) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        .map((item: any) => {
          return { label: item.name, value: item.id }
        })
    }

    return (
      <section className="container">
        <Modal opened={isOpen} onClose={() => setIsOpen(false)} title={t('select_language')}>
          <div style={{ padding: 10 }}>
            <Select
              label={t('select_language_info')}
              placeholder={t('select_language')}
              value={language}
              onChange={(e: any) => setLanguage(e)}
              data={sortLanguages()}
            />
            <Text my={60}>{t('rules_description')}</Text>
          </div>
          <Group>
            <MantineButton variant="outline" onClick={cancelUpload}>
              {t('cancel')}
            </MantineButton>
            <MantineButton disabled={!language} onClick={handleUpload}>
              {t('upload')}
            </MantineButton>
          </Group>
        </Modal>
        <div {...getRootProps({ className: 'dropzone' })} style={{ height: 300 }}>
          <input {...getInputProps()} />
          <img src={MSIcon} height={80} />
          <Space h="lg" />
          <p className={styles.text}>Upload a legal document here</p>
          <p className={styles.subtext}>
            Click <b style={{ color: '#F74F32', cursor: 'pointer' }}>'Search'</b> or drag and drop a
            Word file here
          </p>
          <Space h="lg" />
          <MantineButton>{t('Search')}</MantineButton>
        </div>
      </section>
    )
  }

  return (
    <>
      <GeneralPanelPage>
        <Space h="lg" />
        <Modal
          opened={deleteModal}
          onClose={() => setDeleteModal(false)}
          title={t('delete_document')}
        >
          <p>{deleteFile?.name}</p>
          <Group>
            <MantineButton onClick={cancelDelete}>{t('cancel')}</MantineButton>
            <MantineButton onClick={onDelete} variant="outline">
              {t('delete')}
            </MantineButton>
          </Group>
        </Modal>

        <Modal
          opened={updateModal}
          onClose={() => setUpdateModal(false)}
          title={t('update_document')}
        >
          <Input.Wrapper label={t('document_name')}>
            <Input
              name={'document_name'}
              id={'document_name'}
              placeholder={t('document_name')}
              mb={'md'}
              onChange={(e) => handleChangeUpdate(e.target.value)}
              value={updateFile?.name || ''}
            />
          </Input.Wrapper>
          <Group>
            <MantineButton onClick={cancelUpdate}>{t('cancel')}</MantineButton>
            <MantineButton onClick={onUpdate} variant="outline">
              {t('update')}
            </MantineButton>
          </Group>
        </Modal>

        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Group>
            <div style={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center' }}>
              <Dropzone />
            </div>
          </Group>
        </Card>

        <Space h="lg" />
        <Title mb={'xs'} order={4}>
          {t('your_library')}
        </Title>
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Table>
            <thead>
              <tr>
                <th
                  className={classNames(styles.sortedRow, {
                    [styles.activeRow]: orderBy === 'file_name',
                  })}
                  onClick={() => handleUpdateSorting('file_name')}
                >
                  <div className={styles.rowName}>
                    <span>Document name</span>
                    {orderBy === 'file_name' && (
                      <div
                        className={classNames(styles.orderIcon, {
                          [styles.rotate]: sortedBy === 'asc',
                        })}
                      >
                        <MdOutlineArrowUpward />
                      </div>
                    )}
                  </div>
                </th>
                <th
                  className={classNames(styles.sortedRow, {
                    [styles.activeRow]: orderBy === 'id',
                  })}
                  onClick={() => handleUpdateSorting('id')}
                >
                  <div className={styles.rowName}>
                    <span>Uploaded date</span>
                    {orderBy === 'id' && (
                      <div
                        className={classNames(styles.orderIcon, {
                          [styles.rotate]: sortedBy === 'asc',
                        })}
                      >
                        <MdOutlineArrowUpward />
                      </div>
                    )}
                  </div>
                </th>
                <th
                  className={classNames(styles.sortedRow, {
                    [styles.activeRow]: orderBy === 'viewed_at',
                  })}
                  onClick={() => handleUpdateSorting('viewed_at')}
                >
                  <div className={styles.rowName}>
                    <span>Last viewed</span>
                    {orderBy === 'viewed_at' && (
                      <div
                        className={classNames(styles.orderIcon, {
                          [styles.rotate]: sortedBy === 'asc',
                        })}
                      >
                        <MdOutlineArrowUpward />
                      </div>
                    )}
                  </div>
                </th>
                <th>Language</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          <Space h="lg" />
          <Pagination
            value={page}
            onChange={setPage}
            total={data?.meta?.pagination?.total_pages || 0}
          />
        </Card>
      </GeneralPanelPage>
    </>
  )
}

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box } from '@mantine/core'
import { NavLink } from '@/components/Elements'
import { NavLinkType } from '@/components/Layouts/Elements'

interface IProps {
  onLinkClick: () => void
  menu: Array<NavLinkType>
}

export const NavbarContent = ({ onLinkClick, menu }: IProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  const locationPathname = location.pathname

  const isRouteActive = useCallback(
    (toPathname: string) => {
      return (
        locationPathname === toPathname ||
        (locationPathname.startsWith(toPathname) &&
          locationPathname.charAt(toPathname.length) === '/')
      )
    },
    [locationPathname]
  )

  return (
    <>
      <Box>
        {menu.map(({ label, path }, index) => (
          <NavLink
            active={isRouteActive(path)}
            key={index}
            label={label}
            component={Link}
            to={path}
            onClick={onLinkClick}
          />
        ))}
      </Box>
    </>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'

interface IProps {
  value: boolean
}

export const UserActiveBadge = ({ value }: IProps) => {
  const { t } = useTranslation()

  const config = useMemo(() => {
    return { color: value ? 'secondary' : 'gray', label: value ? t('active') : t('deactivated') }
  }, [value])

  return (
    <Badge color={config?.color} variant={'filled'}>
      {config?.label}
    </Badge>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarManagerControl } from '@/components/Controls'
import { useUsersData, useUsersDataQueryMutations } from '@/features/users/hooks'

export const AvatarManager = () => {
  const { t } = useTranslation()

  const { user } = useUsersData({})

  const {
    avatar: { onUpload, onRemove, uploading, removing },
  } = useUsersDataQueryMutations()

  return (
    <AvatarManagerControl
      image={user?.avatar?.data?.full}
      onUpload={onUpload}
      onRemove={onRemove}
      uploading={uploading}
      removing={removing}
    />
  )
}

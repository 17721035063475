import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usersApi } from '@/features/users/store'
import { UserDTO } from '@/features/users/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { ItemDTO, ModelTypes } from '@/types'

interface IProps {
  id?: string
}

export const useUsersData = ({ id }: IProps) => {
  const { id: paramsId } = useParams()

  const userId = id || (paramsId as string)

  const dispatch = useAppDispatch()

  const selectUsersData: any = useMemo(() => usersApi.endpoints.usersData.select(userId), [userId])

  const state: { data: UserDTO; [x: string]: any } = useAppSelector(selectUsersData)

  const { data: user } = state

  const onSetUserData = (data: UserDTO) => {
    dispatch(usersApi.util.updateQueryData('usersData', userId, (draft) => data))
  }

  const setAvatar = async (data: ItemDTO<ModelTypes.AvatarType> | null) => {
    await dispatch(
      usersApi.util.updateQueryData('usersData', userId, (draft) => {
        return {
          ...draft,
          avatar: data,
        }
      })
    )
  }

  return {
    user,
    state,
    onSetUserData,
    setAvatar,
  }
}

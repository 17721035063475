import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Group, Space, Text, Title } from '@mantine/core'
import { useStatisticQuery } from '@/features/profile/store'

export const UsageContainer = () => {
  const { t } = useTranslation()

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } = useStatisticQuery()

  const statsSets = ['month', 'total']

  const statsKeys = [
    'documents',
    'requests',
    'tokens_input',
    'tokens_output',
    'query_tab_requests',
    'query_tab_input_tokens',
    'query_tab_output_tokens',
    'summary_tab_requests',
    'summary_tab_input_tokens',
    'summary_tab_output_tokens',
    'summary_processing_input_tokens',
    'summary_processing_output_tokens',
    'overview_input_tokens',
    'overview_output_tokens',
  ]

  return (
    <>
      <Title mb={'xs'} order={4}>
        {t('usage_info')}
      </Title>

      <Group w={'100%'}>
        {statsSets.map((set) => (
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={'lg'}>
            <Title mb={'xs'} order={5}>
              {t(`${set}_usage_info`)}
            </Title>
            {statsKeys.map((stat) => (
              <div style={{ display: 'flex' }}>
                {t(`${set}_pre_label`)} {t(`usage_${stat}_label`)}:
                <Space w="lg" />
                <Text fw={700}>{data?.[set]?.[stat]}</Text>
              </div>
            ))}
          </Card>
        ))}
      </Group>
    </>
  )
}

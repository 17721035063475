import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { GeneralPromptsContainer } from '@/features/prompts/components/GeneralPrompts/GeneralPromptsContainer'

export const Prompts = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'prompts'} />

      <GeneralPromptsContainer />
    </>
  )
}

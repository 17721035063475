import { rootApi } from '@/store/api'
import { UpdatePromptsDTO } from '@/features/prompts/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

export const promptsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    promptsUpdate: builder.mutation<any, UpdatePromptsDTO>({
      query: (arg: any) => ({
        url: `prompts/${arg.id}`,
        method: 'PATCH',
        body: arg,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    promptsCreate: builder.mutation<any, UpdatePromptsDTO>({
      query: (arg: any) => ({
        url: `prompts`,
        method: 'POST',
        body: arg,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    promptsDelete: builder.mutation<void, void>({
      query: (arg: any) => ({
        url: `prompts/${arg.id}`,
        method: 'DELETE',
      }),
    }),
    promptsEntries: builder.query<any, { params: any }>({
      query: (arg) => ({
        url: `prompts`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  usePromptsUpdateMutation,
  usePromptsCreateMutation,
  usePromptsDeleteMutation,
  usePromptsEntriesQuery,
} = promptsApi

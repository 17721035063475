import { rootApi } from '@/store/api'
import { LoginDTO } from '@/features/auth/types'
import { RTKQueryUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const adminAuthApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    adminAuthLogin: builder.mutation<any, LoginDTO>({
      query: (arg) => ({
        url: 'clients/web/admin/login',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: cacher.invalidatesUnauthorized(),
    }),
  }),
  overrideExisting: false,
})

export const { useAdminAuthLoginMutation } = adminAuthApi

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { UserDataLayout } from '@/features/users/components/User'
import { Limits } from '../limits/Limits'

export const LimitsRoot = () => {
  return (
    <Routes>
      <Route index element={<Limits />} />
      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LandingLayout } from '@/components/Layouts'
import { AuthLayout } from '@/features/auth/components/Layouts'
import { Login } from '@/features/auth/routes'
import { PATH } from '@/routes/consts'
import { Subscriptions } from '../Subscriptions'
import { Home } from '../home'

export const LandingRoot = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route index element={<Login />} />
      </Route>
      <Route element={<LandingLayout />}>
        <Route path={PATH.SUBSCRIPTIONS} element={<Subscriptions />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

import { createStyles } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    wrap: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing.md,
    },

    header: {
      paddingBottom: theme.spacing.md,
    },

    main: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }
})

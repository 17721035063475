import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Stack, Text, Title } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { LoginForm } from '@/features/auth/components/Forms'
import { LoginFormValues, useLoginForm } from '@/features/auth/hooks'
import { useLoginMutation, useSendVerificationEmailMutation } from '@/features/auth/store'
import { useNotify } from '@/hooks'
import { PATH } from '@/routes/consts'
import { FormTypes } from '@/types'

export const LoginContainer = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [login] = useLoginMutation()

  const [verifyEmail] = useSendVerificationEmailMutation()

  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null)

  const handleSubmit = async (values: LoginFormValues) => {
    setSubmittedEmail(values.email)

    await login(values).unwrap()
  }

  const { methods, onSubmit, submitError, submitResetState } = useLoginForm({
    onSubmit: handleSubmit,
  })

  const onVerifyEmail = async (email: string) => {
    await verifyEmail({ email })
  }

  const onSendVerifyEmail = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    const { clearErrors } = methods

    submitResetState()
    clearErrors()

    if (submittedEmail) {
      try {
        await onVerifyEmail(submittedEmail)
        showNotification({
          type: 'success',
          message: t('confirmation-email-sent'),
        })
      } catch (err) {
        const serverError = err as FormTypes.ValidationErrors
        const message = serverError?.message || t('error')
        showNotification({
          type: 'error',
          message,
        })
      }
    }
  }

  return (
    <AuthCard title={`${t('welcome')}!`}>
      <Stack spacing="xs" align={'flex-start'}>
        <Text>{t('dont_have_account_yet')}?</Text>

        <Anchor component={Link} to={`/${PATH.REGISTER}`} weight={600}>
          {t('create_an_account')}
        </Anchor>
      </Stack>

      <Space h={'xl'} />

      <FormProvider {...methods}>
        <LoginForm
          onSubmit={onSubmit}
          submitError={submitError}
          topBar={
            submitError &&
            submitError?.data?.errors?.unverified_email && (
              <Title order={6} my={'md'}>
                <Anchor
                  component={'a'}
                  href={'#'}
                  onClick={(e: React.MouseEvent<HTMLElement>) => onSendVerifyEmail(e)}
                >
                  {t('send_confirmation_email_to')}
                </Anchor>
                {` ${submittedEmail}`}
              </Title>
            )
          }
        />
      </FormProvider>
    </AuthCard>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PasswordContainer } from '@/features/users/components/User/UserProfileDataEntry'

export const Security = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'confidential_info'} />

      <PasswordContainer />
    </>
  )
}

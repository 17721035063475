import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/consts/layout'

export default createStyles((theme, _params, getRef) => ({
  wrap: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 0,
  },
}))

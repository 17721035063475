import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/Elements'

export const UserDeletedBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge color={'gray'} variant={'filled'}>
      {t('deleted')}
    </Badge>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Group } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { UserActiveBadge, UserDeletedBadge } from '@/features/users/components/Elements'
import { useUsersModel } from '@/features/users/hooks'
import { ExtendedUserType } from '@/features/users/types'
import { PATH, PATH_ADMIN } from '@/routes/consts'

interface IProps {
  user: ExtendedUserType
}

export const User = ({ user }: IProps) => {
  const { t } = useTranslation()

  const { id, active } = user

  const { getName, isActive, isDeleted } = useUsersModel()

  const config = {
    active: isActive(user),
    deleted: isDeleted(user),
  }

  return (
    <div style={{ minWidth: 200 }}>
      <div>
        <Anchor component={Link} to={`/${PATH.ADMIN}/${PATH_ADMIN.USERS}/${id}`} color={'dark'}>
          {getName(user) || '-'}
        </Anchor>
      </div>

      {(!config.active || config.deleted) && (
        <Group mt={'xs'}>
          {!config.active && <UserActiveBadge value={active} />}

          {config.deleted && <UserDeletedBadge />}
        </Group>
      )}
    </div>
  )
}

import qs from 'qs'
import { CommonUtils } from '@/utils'

const paramsSerializer = (params) => {
  return qs.stringify(params, { encode: false })
}

const stringifyParams = (params = {}) => {
  return qs
    .stringify(params, {
      allowDots: true,
      delimiter: ';',
      arrayFormat: 'comma',
      skipNulls: true,
    })
    .replaceAll('=', ':')
}

const getMapRequestParams = (params = {}, initialValues = {}) => {
  let requestParams = initialValues

  // eslint-disable-next-line no-prototype-builtins
  if (params.hasOwnProperty('pagination')) {
    requestParams = {
      ...requestParams,
      pagination: params.pagination,
    }
  }

  if (params.type) {
    requestParams = {
      ...requestParams,
      type: params.type,
    }
  }

  if (Number.isInteger(params.page)) {
    requestParams = {
      ...requestParams,
      page: params.page,
    }
  }

  if (params.tariff_id) {
    requestParams = {
      ...requestParams,
      tariff_id: params.tariff_id,
    }
  }

  if (params.search) {
    requestParams = {
      ...requestParams,
      search: params.search,
    }
  }

  if (params.orderBy) {
    requestParams = {
      ...requestParams,
      orderBy: params.orderBy,
    }
  }

  if (params.sortedBy) {
    requestParams = {
      ...requestParams,
      sortedBy: params.sortedBy,
    }
  }

  if (params.prompt_id) {
    requestParams = {
      ...requestParams,
      prompt_id: params.prompt_id,
    }
  }

  if (params.date_to) {
    requestParams = {
      ...requestParams,
      date_to: params.date_to,
    }
  }

  if (params.date_from) {
    requestParams = {
      ...requestParams,
      date_from: params.date_from,
    }
  }

  if (!CommonUtils.isNil(params.limit)) {
    requestParams = {
      ...requestParams,
      limit: params.limit,
    }
  }

  if (params.sort && Array.isArray(params.sort)) {
    let sort = {}

    params.sort.forEach((item) => {
      const key = item.id
      const value = item.desc ? 'desc' : 'asc'

      sort = {
        ...sort,
        [key]: value,
      }
    })

    requestParams = {
      ...requestParams,
      sort: {
        ...sort,
      },
    }
  }

  if (params.search) {
    const queryParams = stringifyParams(params.search)

    if (queryParams) {
      requestParams = {
        ...requestParams,
        search: queryParams,
      }

      if (params.searchJoin) {
        requestParams = {
          ...requestParams,
          searchJoin: params.searchJoin,
        }
      }
    }
  }

  if (params.searchFields) {
    const queryParams = stringifyParams(params.searchFields)
    requestParams = {
      ...requestParams,
      searchFields: queryParams,
    }
  }

  if (params.filters) {
    const mapFilterParams = (data) => {
      let filters = {}
      Object.entries(data).forEach((entry) => {
        const [key, value] = entry
        if (value !== null && value !== '') {
          // if value is object
          if (typeof value === 'object' && !Array.isArray(value)) {
            filters[key] = {
              ...mapFilterParams(value),
            }
          } else {
            filters[key] = value
          }
        }
      })

      return filters
    }

    requestParams = {
      ...requestParams,
      filters: {
        ...mapFilterParams(params.filters),
      },
    }
  }

  if (params.include && Array.isArray(params.include)) {
    requestParams = {
      ...requestParams,
      include: params.include.join(','),
    }
  }

  if (params.input) {
    requestParams = {
      ...requestParams,
      input: params.input,
    }
  }

  return requestParams
}

const buildFormData = (formData, data, rootName) => {
  let root = rootName || ''
  if (data instanceof File) {
    formData.append(root, data)
  } else if (Array.isArray(data)) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        buildFormData(formData, data[i], root + '[' + i + ']')
      }
    } else {
      formData.append(root, '')
    }
  } else if (typeof data === 'object' && data) {
    for (let key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        if (root === '') {
          buildFormData(formData, data[key], key)
        } else {
          buildFormData(formData, data[key], root + '[' + key + ']')
        }
      }
    }
  } else {
    if (data !== null && typeof data !== 'undefined') {
      formData.append(root, data)
    } else {
      formData.append(root, '')
    }
  }
}

export { paramsSerializer, getMapRequestParams, buildFormData }

import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      flexDirection: 'column',
      width: '100%',
    },
  },

  avatarWrap: {
    marginRight: '1.5rem',

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 0 1.5rem',
    },
  },

  infoItem: {
    display: 'flex',
    color: theme.colors.gray[7],
    fontWeight: 500,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Title } from '@mantine/core'
import { ContentSection, GeneralTabs, OverlayDataShower } from '@/components/Elements'
import { GeneralPaymentMethodsTable } from '@/features/billing/components/PaymentMethod/PaymentMethods/Tables'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { usePaymentMethodStaticOptions } from '@/features/billing/hooks'
import { useBillingPaymentMethodListQuery } from '@/features/billing/store'
import { PaymentMethodAddControl } from './PaymentMethodAddControl'

const { CARD } = BILLING_PAYMENT_METHOD_TYPE

export const GeneralPaymentMethodsContainer = () => {
  const { t } = useTranslation()

  const { data: list, isLoading, isFetching, error, isError } = useBillingPaymentMethodListQuery()

  const loading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  const paymentMethodsEntries = useCallback(
    (type: BILLING_PAYMENT_METHOD_TYPE) => (list ? list.filter((item) => item.type === type) : []),
    [list]
  )

  const { getTypes } = usePaymentMethodStaticOptions()

  const [tab, setTab] = useState(CARD)

  const tabs = getTypes().map(({ label, value }) => ({
    label,
    value,
    render: () => (
      <GeneralPaymentMethodsTable
        items={paymentMethodsEntries(value)}
        loading={loading}
        type={value}
      />
    ),
    panelProps: {
      pt: 'lg',
    },
  }))

  return (
    <ContentSection>
      <Group grow>
        <Title mb={'md'} order={4}>
          {t('payment_methods')}
        </Title>

        <div className={'flex items-center justify-end'}>
          <PaymentMethodAddControl type={tab} />
        </div>
      </Group>

      <OverlayDataShower isLoading={loading} isFailed={isError} error={error}>
        <GeneralTabs variant={'pills'} tabs={tabs} value={tab} onChange={setTab} />
      </OverlayDataShower>
    </ContentSection>
  )
}

import React from 'react'
import { Container, Group, Title, rem } from '@mantine/core'
import { BackButton } from '@/components/Elements'
import { HEADER_HEIGHT_MD } from '@/consts/layout'
import useStyles from './GeneralTopBar.styles'

export interface GeneralTopBarProps {
  height?: number
  children?: React.ReactNode
  title?: React.ReactNode | string
  leftSection?: React.ReactNode
  rightSection?: React.ReactNode
  className?: string
  back?: boolean
  backProps?: any
  fluid?: boolean
}

export const GeneralTopBar = ({
  height = HEADER_HEIGHT_MD,
  children,
  title,
  leftSection,
  rightSection,
  className,
  back,
  backProps,
  fluid = false,
}: GeneralTopBarProps) => {
  const { classes, cx } = useStyles({
    height,
  })

  const renderTitle = () => {
    if (typeof title === 'string') {
      return <Title order={4}>{title}</Title>
    }

    return title
  }

  return (
    <div className={cx(classes.main, className)}>
      <div className={classes.container}>
        <Group spacing={'xl'} className={'w-full h-full flex-nowrap'}>
          {back && <BackButton {...backProps} />}

          {(leftSection || title) && (
            <Group className={'flex-nowrap'}>
              {leftSection}

              {title && renderTitle()}
            </Group>
          )}

          {children && <>{children}</>}

          {rightSection && <div className={'flex grow justify-end'}>{rightSection}</div>}
        </Group>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Space, Stack, Text } from '@mantine/core'
import { Anchor, DataShower } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useLazyVerifyEmailQuery } from '@/features/auth/store'
import { useQuery } from '@/hooks'
import { useNotify } from '@/hooks'
import { PATH } from '@/routes/consts'

export const EmailVerificationContainer = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const query = useQuery()
  const { showNotification } = useNotify()

  const [verifyEmail, { isLoading, isFetching, error, isError, isSuccess }] =
    useLazyVerifyEmailQuery()

  const onVerify = async ({ url, signature }: { url: string; signature: string }) => {
    try {
      await verifyEmail({ url, signature }).unwrap()

      showNotification({
        type: 'success',
        message: t('email_verification.success'),
      })

      navigate(
        {
          pathname: `/${PATH.LOGIN}`,
          search: '',
        },
        { replace: true }
      )
    } catch (e: any) {}
  }

  useEffect(() => {
    const url = query.get('url')
    const signature = query.get('signature')

    if (url && signature) {
      onVerify({ url, signature })
    }
  }, [])

  return (
    <AuthCard title={t('email_verification')}>
      <DataShower
        isLoading={isLoading || isFetching}
        isFetched={isSuccess}
        isFailed={isError}
        error={error}
      >
        <></>
      </DataShower>

      <Space h={'xl'} />

      <Stack spacing="xs" align={'flex-start'}>
        <Anchor component={Link} to={`/${PATH.LOGIN}`}>
          <Text tt="capitalize" fw={'600'}>
            {t('go_to_login_page')}
          </Text>
        </Anchor>
      </Stack>
    </AuthCard>
  )
}

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from '@/features/user/hooks'
import { getPrivateInitialRoute } from '@/routes/helpers'

interface IProps {
  [x: string]: any
}

export const PanelSwitch: React.FC<IProps> = ({ children, roles, ...props }) => {
  const location = useLocation()

  const { isAdmin } = useUser()

  const initialRoute = getPrivateInitialRoute({ isAdmin: isAdmin() })

  return <Navigate to={initialRoute} state={{ from: location }} />
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { UserProfileContainer } from '@/features/users/components/User/UserProfile'

export const User = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'user'} />

      <GeneralPanelPage header={<GeneralTopBar title={t('user')} back />}>
        <UserProfileContainer />
      </GeneralPanelPage>
    </>
  )
}

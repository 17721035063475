import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LegalShell } from '@/features/legal/components/Layouts'
import { PATH_LEGAL } from '@/routes/consts'
import { Terms } from '../panel'

export const PanelRoot = () => {
  return (
    <Routes>
      <Route element={<LegalShell />}>
        <Route path={PATH_LEGAL.TERMS} element={<Terms />} />
      </Route>

      <Route path={'*'} element={<Navigate to={PATH_LEGAL.TERMS} replace={true} />} />
    </Routes>
  )
}

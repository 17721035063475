import _ from 'lodash'
import { GENDER } from '@/consts/features'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: GENDER
  prefix: string
}

export const getGenders = () => {
  return [
    {
      label: i18n.t('male'),
      value: GENDER.MALE,
      prefix: i18n.t('mr'),
    },
    {
      label: i18n.t('female'),
      value: GENDER.FEMALE,
      prefix: i18n.t('mrs'),
    },
  ]
}

export const getGender = (value: OptionType | string): OptionType | any => {
  const options = getGenders()

  return _.find(options, { value }) || { label: value }
}

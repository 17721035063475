import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { ButtonLink, ColorSchemeToggle } from '@/components/Elements'
import { PublicHeader } from '@/components/Layouts/Elements'
import { PATH } from '@/routes/consts'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <>
      <PublicHeader
        rightSection={
          <Group spacing={'xl'}>
            <ColorSchemeToggle />

            <ButtonLink to={`/${PATH.LOGIN}`}>{t('login')}</ButtonLink>
          </Group>
        }
      />
    </>
  )
}

import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/consts/layout'

export default createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

import React from 'react'
import { User } from '@/features/user'
import { selectUserData } from '@/features/user/store'
import { useUsersModel } from '@/features/users/hooks'
import { useAppSelector } from '@/store'

export const useUser = () => {
  const user = useAppSelector(selectUserData) as User

  const usersModel = useUsersModel()

  const isCustomer = () => {
    return usersModel.isCustomer(user)
  }

  const isAdmin = () => {
    return usersModel.isAdmin(user)
  }

  const getName = () => {
    return usersModel.getName(user)
  }

  return {
    user,
    isCustomer,
    isAdmin,
    getName,
  }
}

import React from 'react'
import { BasicLayoutPage } from '../BasicLayoutPage'

interface IProps {
  children?: React.ReactNode
}

export const PublicLayoutPage = ({ children }: IProps) => {
  return <BasicLayoutPage>{children}</BasicLayoutPage>
}

import React from 'react'
import { AdminPanelLayout } from '@/features/admin/components/Layouts'
import { PanelLayout } from '@/features/panel/components/Layouts'
import { useUser } from '@/features/user/hooks'

export const PanelProvider = () => {
  const { isAdmin } = useUser()

  if (isAdmin()) {
    return <AdminPanelLayout />
  }

  return <PanelLayout />
}

import { rootApi } from '@/store/api'
import { AddPaymentMethodDTO, UpdateDefaultPaymentMethodDTO } from '@/features/billing/api'
import { PaymentMethodModel } from '@/features/billing/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'
import { tempData } from './tempData'

const { cacher } = RTKQueryUtils

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingPaymentMethods'] })

export const billingPaymentMethodApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingPaymentMethodList: builder.query<PaymentMethodModel[], void>({
      queryFn: (): { data: any[] } => ({ data: tempData }),
      // query: () => ({
      //   url: `billing/payment-methods`,
      // }),
      // transformResponse: (response: any) => {
      //   return response?.data
      // },
      providesTags: cacher.providesList('BillingPaymentMethods'),
    }),
    billingPaymentMethodAdd: builder.mutation<PaymentMethodModel, { params: AddPaymentMethodDTO }>({
      query: (arg) => {
        const { params } = arg

        return {
          url: `billing/payment-methods`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: (result, error: any) =>
        error ? [] : cacher.invalidatesList('BillingPaymentMethods')(),
    }),
    billingPaymentMethodDelete: builder.mutation<null, string>({
      query: (id) => {
        return {
          url: `billing/payment-methods/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error: any) =>
        error ? [] : cacher.invalidatesList('BillingPaymentMethods')(),
    }),
    billingPaymentMethodUpdateDefault: builder.mutation<
      PaymentMethodModel,
      { params: UpdateDefaultPaymentMethodDTO }
    >({
      query: (arg) => {
        const { params } = arg

        return {
          url: `billing/payment-methods/default`,
          method: 'POST',
          body: params,
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { params } = arg

          const { data } = await queryFulfilled
          dispatch(
            billingPaymentMethodApi.util.updateQueryData(
              'billingPaymentMethodList',
              undefined,
              (draft) => {
                return draft.map((item) => ({
                  ...item,
                  default: item.id === params.payment_method,
                }))
              }
            )
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useBillingPaymentMethodListQuery,
  useBillingPaymentMethodAddMutation,
  useBillingPaymentMethodDeleteMutation,
  useBillingPaymentMethodUpdateDefaultMutation,
} = billingPaymentMethodApi

import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useFormSubmit } from '@/hooks'

export type LoginFormValues = {
  email: string
  password: string
}

interface IProps {
  onSubmit: (values: LoginFormValues) => Promise<void>
}

export const useLoginForm = ({ ...props }: IProps) => {
  const methods = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      })
    ),
  })

  const { setError } = methods

  const {
    error: submitError,
    onSubmit: onFormSubmit,
    resetState: submitResetState,
  } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  return {
    methods,
    onSubmit,
    submitError,
    submitResetState,
  }
}

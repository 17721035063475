import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { LoadingScreen } from '@/components/Elements'
import { useAppWindowFocus } from '@/features/app/hooks'
import { useUserDataQuery } from '@/features/user/store'
import { ErrorFallback } from './ErrorFallback'

export const PrivateLayout = () => {
  const { t } = useTranslation()

  // todo: move to App component
  useAppWindowFocus()

  const userDataQuery = useUserDataQuery()

  const loading = useMemo(() => {
    return userDataQuery.isLoading
  }, [userDataQuery.isLoading])

  const prepared = useMemo(() => {
    return userDataQuery.isSuccess
  }, [userDataQuery.isSuccess])

  const error = useMemo(() => {
    return userDataQuery.error
  }, [userDataQuery.error])

  return loading ? (
    <LoadingScreen />
  ) : prepared ? (
    <Outlet />
  ) : error && 'data' in error ? (
    <ErrorFallback error={error} />
  ) : null
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { DashboardContainer } from '@/features/dashboard/components/Dashboard'

export const Dashboard = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'dashboard'} />

      <DashboardContainer />
    </>
  )
}

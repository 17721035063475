import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Text } from '@mantine/core'
import { Anchor, TableView } from '@/components/Elements'
import { useBilling } from '@/features/billing/hooks'
import { InvoiceModel } from '@/features/billing/types'
import { PATH, PATH_BILLING } from '@/routes/consts'
import { DateUtils } from '@/utils'

const { BILLING } = PATH
const { INVOICES } = PATH_BILLING

type Key = 'number' | 'date' | 'amount'

interface IProps {
  keys: Key[]
  items: InvoiceModel[]
  loading: boolean
  actionProps?: any
  editControlProps?: any
  removeControlProps?: any
}

export const GeneralInvoicesTable = ({
  keys = [],
  items,
  loading,
  actionProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const { getCurrency } = useBilling()

  const columnConfig: any = {
    number: {
      accessorKey: 'number',
      id: 'number',
      header: t('invoice'),
      cell: (info: any) => (
        <Anchor fw={700} component={Link} to={`/${BILLING}/${INVOICES}/${info.row.original?.id}`}>
          {info.getValue()}
        </Anchor>
      ),
    },
    date: {
      accessorKey: 'created_at',
      id: 'created_at',
      header: t('date'),
      cell: (info: any) => (info.getValue() ? DateUtils.formatDate(info.getValue()) : '-'),
    },
    amount: {
      accessorKey: 'total',
      id: 'total',
      header: <Text ta="right">{t('amount')}</Text>,
      cell: (info: any) => (
        <Text ta="right">
          {info.getValue()} {getCurrency()?.symbol}
        </Text>
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}

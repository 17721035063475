import dayjs from 'dayjs'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, ScrollArea, Space } from '@mantine/core'
import { MetaPagination, QueryDataShell } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { GeneralUsersTable } from '@/features/users/components/Users/Tables'
import { UserStats } from '@/features/users/components/Users/UsersStats/UserStats'
import { useGeneralUsersQueryParams } from '@/features/users/hooks'
import { useUsersEntriesQuery } from '@/features/users/store'
import { ExtendedUserType } from '@/features/users/types'
import useStyles from './Style.styles'

export const GeneralUsersContainer = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const {
    page,
    onPageChange,
    query,
    setQuery,
    date_from,
    date_to,
    sortedBy,
    orderBy,
    onSortByChange,
    onOrderByChange,
  } = useGeneralUsersQueryParams()

  const [localSearch, setLocalSearch] = useState(query.search || '')

  const queryParams = useMemo(() => {
    const from = date_from ? dayjs(date_from).format('YYYY-MM-DD') : '2023-01-01'
    const to = date_to ? dayjs(date_to).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')

    return {
      page: page || 1,
      date_from: from,
      date_to: to,
      sortedBy: orderBy || 'asc',
      orderBy: sortedBy || 'created_at',
      search: encodeURIComponent(query.search?.toLocaleLowerCase() || ''),
    }
  }, [page, query.search, date_from, date_to, orderBy, orderBy])

  const { data, isLoading, isFetching, error, isError, isSuccess } = useUsersEntriesQuery({
    params: queryParams,
  })

  const debouncedSearch = debounce(async (searchValue) => {
    setQuery({ page, search: searchValue })
  }, 300)

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalSearch(e.target.value)
    debouncedSearch(e.target.value)
  }

  useEffect(() => {
    onSortByChange(sortedBy || 'created_at')
    onOrderByChange(orderBy || 'asc')
  }, [])

  const list = data?.data || []
  const meta = data?.meta

  return (
    <GeneralPanelPage>
      <UserStats />
      <GeneralTopBar title={t('users')} />
      <Space h={'lg'} />
      <QueryDataShell
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        isError={isError}
        isSuccess={isSuccess || !!list.length}
      >
        <>
          <Input
            name={'search'}
            id={'search'}
            placeholder={t('search')}
            mb={'md'}
            onChange={handleSearch}
            value={localSearch}
          />

          <ScrollArea w={'calc(100vw - 300px)'}>
            <GeneralUsersTable
              keys={[
                'user',
                'email',
                'company',
                'createdAt',
                'documentUploaded',
                'input_tokens',
                'output_tokens',
                'overview_input_tokens',
                'overview_output_tokens',
                'query_tab_input_tokens',
                'query_tab_output_tokens',
                'query_tab_requests',
                'requests',
                'summary_processing_input_tokens',
                'summary_processing_output_tokens',
                'summary_tab_input_tokens',
                'summary_tab_output_tokens',
                'summary_tab_requests',
                'action',
              ]}
              items={list}
            />
          </ScrollArea>
          <MetaPagination
            meta={meta?.pagination}
            page={page}
            onChange={onPageChange}
            className={'mt-4 mb-4'}
          />
        </>
      </QueryDataShell>
    </GeneralPanelPage>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Group, Title } from '@mantine/core'
import { ColorSchemeToggle } from '@/components/Elements'
import { AvatarManager } from '@/features/profile/components/Profile/Avatar'

export const TopBarFormSection = () => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={'md'}>
        <Group>
          <div className={'flex grow'}>
            <Title mb={'md'} order={4}>
              {t('profile')}
            </Title>
          </div>

          <ColorSchemeToggle />
        </Group>

        <Box mb={'md'}>
          <AvatarManager />
        </Box>
      </Box>
    </>
  )
}

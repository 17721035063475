import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { UsageContainer } from '@/features/profile/components/Profile'

export const Usage = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'usage_info'} />

      <UsageContainer />
    </>
  )
}

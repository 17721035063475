import React from 'react'
import { ExtendedUserType, ROLE, UserModel } from '@/features/users/types'

export const useUsersModel = () => {
  const isCustomer = (user: UserModel) => {
    return user?.role === ROLE.CUSTOMER
  }

  const isAdmin = (user: UserModel) => {
    return user?.role === ROLE.ADMIN
  }

  const getName = (user: UserModel | ExtendedUserType | null | undefined) => {
    if (user) {
      let result = user?.name

      if (user?.last_name) {
        result = result ? `${result} ${user?.last_name}` : user?.last_name
      }

      return result
    }
    return null
  }

  const isActive = (user: ExtendedUserType) => {
    return user?.active
  }

  const isDeleted = (user: ExtendedUserType) => {
    return !!user?.deleted_at
  }

  return {
    isCustomer,
    isAdmin,
    getName,
    isActive,
    isDeleted,
  }
}

import React from 'react'
import { CardProps, Card as MantineCard } from '@mantine/core'
import useStyles from './Card.styles'

interface IProps extends CardProps {
  children: React.ReactNode
  className?: string
  component?: any
}

export const Card: React.FC<IProps> = ({ children, className, ...props }) => {
  const { classes, cx } = useStyles()
  return (
    <MantineCard className={cx(className)} {...props}>
      {children}
    </MantineCard>
  )
}

export const CardSection = MantineCard.Section

import moment from 'moment'

const phoneFormat = (val: any) => {
  if (val && val !== '') {
    return val.toString() !== '+'
  }
  return true
}

const digitsBetween = (value: any, config: { min: number; max: number }) => {
  if (value && value !== '') {
    const digitsLength = value.replace(/[^0-9]/g, '').length
    return digitsLength >= config.min && digitsLength <= config.max
  }
  return true
}

const dateEqualOrAfter = (value: any, date: any) => {
  if (value && date) {
    return moment(value).diff(moment(date)) >= 0
  }

  return true
}

const dateAfter = (value: any, date: any, measurement?: any, rounded?: any) => {
  if (value && date) {
    return moment(value).diff(moment(date), measurement, rounded) > 0
  }

  return true
}

const setServerSideErrors = (errors: any, setError: any) => {
  if (errors) {
    Object.keys(errors).forEach((key) => {
      setError(key, {
        type: 'server',
        message: errors[key].join('. '),
      })
    })
  }
}

const fileMaxSize = (file: any, max: any) => {
  if (file?.size && max) {
    return file?.size <= max
  }

  return true
}

export { phoneFormat, setServerSideErrors, dateEqualOrAfter, fileMaxSize, dateAfter, digitsBetween }

import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, ActionIconProps, Menu } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { ExtendedUserType } from '@/features/users/types'
import { ActiveControl, EditControl, RemoveControl, RestoreControl } from './Controls'

interface IProps {
  config?: {
    edit?: boolean
    active?: boolean
    remove?: boolean
    restore?: boolean
  }
  data: ExtendedUserType
  menuProps?: any
  actionIconProps?: ActionIconProps
  editProps?: any
  activeProps?: {
    onActiveUpdate?: (arg: { active: boolean }, data: ExtendedUserType) => void
  }
  removeProps?: {
    onRemove?: (data: ExtendedUserType) => void
  }
  restoreProps?: {
    onRestore?: (data: ExtendedUserType) => void
  }
}

const defaultConfig = {
  edit: true,
  active: true,
  remove: true,
  restore: false,
}

export const GeneralActionMenu = ({
  config,
  data,
  menuProps,
  actionIconProps,
  editProps,
  activeProps,
  removeProps,
  restoreProps,
}: IProps) => {
  const { t } = useTranslation()

  config = { ...defaultConfig, ...config }

  const onEventPrevent = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  return (
    <div onClick={onEventPrevent}>
      <Menu width={150} {...menuProps}>
        <Menu.Target>
          <ActionIcon color={'gray.9'} variant={'subtle'} {...actionIconProps}>
            <Icon name={'dots'} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {config?.edit && <EditControl {...editProps} />}

          {config?.active && <ActiveControl data={data} {...activeProps} />}

          {config?.remove && <RemoveControl data={data} {...removeProps} />}

          {config?.restore && <RestoreControl data={data} {...restoreProps} />}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}

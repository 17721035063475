export const HEADER_HEIGHT = 80
export const HEADER_HEIGHT_MD = 60
export const HEADER_HEIGHT_SM = 48

export const NAVBAR_WIDTH = 200

export enum ENTRIES_VIEW_MODE {
  TABLE = 'table',
  GRID = 'grid',
}

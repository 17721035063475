import React from 'react'
import { Outlet } from 'react-router-dom'
import { useToggle } from '@mantine/hooks'
import { VerticalPanelShell } from '../PanelShells'
import { Header } from './Header'
import { Navbar } from './Navbar'
import useStyles from './PanelLayout.styles'

export const PanelLayout = () => {
  const { classes } = useStyles()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  return (
    <VerticalPanelShell
      header={<Header onDrawerOpen={() => toggleNavbarDrawerOpened(true)} />}
      navbar={
        <Navbar
          drawerOpened={isNavbarDrawerOpened}
          onDrawerClose={() => toggleNavbarDrawerOpened(false)}
        />
      }
    >
      <Outlet />
    </VerticalPanelShell>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { APP_NAME } from '@/consts/core'

interface IProps {
  title: string
}

export const MegaTagTitle = ({ title }: IProps) => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <title>{`${t(title)} | ${APP_NAME}`}</title>
    </Helmet>
  )
}

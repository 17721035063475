import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, List, Text, ThemeIcon, Title } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useBilling, useTariffStaticOptions } from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import { CommonUtils } from '@/utils'
import useStyles from './GeneralCard.styles'

const { isNil } = CommonUtils

interface IProps {
  data: TariffModel
  actionSections?: React.ReactNode
}

export const GeneralCard = ({ data, actionSections }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { getCurrency } = useBilling()
  const { isAnnual } = useTariffStaticOptions()

  const { period, name, price, description, includes } = data

  const currencySymbol = getCurrency()?.symbol
  const periodLabel = isAnnual(period) ? t('year') : t('month')

  return (
    <>
      <Card className={cx(classes.card, {})}>
        <div className={classes.header}>
          <Box>
            <Title className={classes.name} order={5}>
              {name}
            </Title>
          </Box>

          {!isNil(price) && (
            <Box>
              <Title order={1}>
                {price} {currencySymbol}{' '}
                <Text component={'span'} transform={'lowercase'} fz="sm" fw={400} c="dimmed">
                  {`${t('user')}/`}
                  {periodLabel}
                </Text>
              </Title>
            </Box>
          )}

          {description && (
            <Box mt={'md'} className={classes.descriptionBlock}>
              <Text>{description}</Text>
            </Box>
          )}
        </div>

        {actionSections && <Box mt={'lg'}>{actionSections}</Box>}

        <Box mt={32}>
          <Text fz={'sm'} fw={700} className={classes.listTitle} mb={'md'}>
            {t('what_included')}
          </Text>

          <List className={classes.list} spacing={'xl'}>
            {includes?.length &&
              includes.map(({ value, active }, index) => (
                <List.Item
                  key={index}
                  icon={
                    <ThemeIcon color={active ? 'green' : 'gray'} size={24} radius="xl">
                      <Icon name={active ? 'check' : 'x'} />
                    </ThemeIcon>
                  }
                >
                  <Text fw={600}>{value}</Text>
                </List.Item>
              ))}
          </List>
        </Box>
      </Card>
    </>
  )
}

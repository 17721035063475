import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  block: {
    width: '100%',
    maxWidth: '650px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      maxWidth: 'auto',
    },
  },
}))

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Container, Group, Header as MantineHeader } from '@mantine/core'
import { Anchor, Icon, LogoIcon } from '@/components/Elements'
import { HEADER_HEIGHT } from '@/consts/layout'
import useStyles from './MainHeader.styles'

export interface MainHeaderProps {
  children?: React.ReactNode
  leftSection?: React.ReactElement
  rightSection?: React.ReactElement
  fixed?: boolean
  fluid?: boolean
  className?: string
  indexRoutePath?: string
  zIndex?: number
}

export const MainHeader = ({
  children,
  leftSection,
  rightSection,
  fixed = true,
  fluid = false,
  className,
  indexRoutePath = '/',
  zIndex,
  ...props
}: MainHeaderProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const handleFeedback = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSeRuNF4l7fmVCbi7FomOdiwPsKkmJh-fdMUUFdVtGSZFSgSlA/viewform?usp=sf_link',
      '_blank'
    )
  }

  return (
    <>
      <MantineHeader
        className={cx(classes.root, className)}
        height={HEADER_HEIGHT}
        fixed={fixed}
        zIndex={zIndex}
        {...props}
      >
        <Container fluid={fluid} className={classes.header}>
          <Group className={'w-full flex-nowrap'}>
            <div>
              <Group className={'flex-nowrap'}>
                {leftSection && leftSection}

                <Anchor component={Link} to={'/'}>
                  <LogoIcon size={'sm'} />
                </Anchor>
              </Group>
            </div>

            {children}

            {rightSection && (
              <div className={'flex grow justify-end items-center'}>
                <Button mx={'md'} onClick={handleFeedback}>
                  {t('feedback')}
                </Button>
                {rightSection}
              </div>
            )}
          </Group>
        </Container>
      </MantineHeader>
    </>
  )
}

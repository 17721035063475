import { GENDER } from '@/consts/features'
import { ModelTypes } from '@/types'
import { ItemDTO } from '@/types'

export enum ROLE {
  CUSTOMER = 'customer',
  ADMIN = 'admin',
}

export type BaseUserType = {
  id: string
  name: string
  last_name: string
  email: string
  company: string
  role: ROLE
}

export type ExtendedUserType = BaseUserType & {
  real_id: number
  created_at: string
  updated_at: string
  deleted_at: string
  email_verified_at: string
  active: boolean
}

// eslint-disable-next-line
export interface UserModel extends BaseUserType {}

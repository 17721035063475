import React from 'react'
import { ModalsProvider } from '@mantine/modals'
import { APP_MODALS_ALIAS } from '@/features/app/consts/modal'
import useStyles from './Modal.styles'

const { CONFIRMATION } = APP_MODALS_ALIAS

const predefinedModals = {}

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof predefinedModals
  }
}

interface IProps {
  children: React.ReactNode
}

export const AppModalsManager = ({ children }: IProps) => {
  const { classes } = useStyles()

  return (
    <>
      <ModalsProvider modals={predefinedModals} modalProps={{ classNames: classes }}>
        {children}
      </ModalsProvider>
    </>
  )
}

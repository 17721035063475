import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  return {
    root: {},

    tabsList: {
      flexWrap: 'nowrap',
    },

    tab: {
      // fontSize: theme.fontSizes.sm,

      '&[data-active]': {
        // fontWeight: 600,
      },
    },
  }
})

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Modal } from '@/components/Elements'
import { usePromptsDeleteMutation } from '@/features/prompts/store'
import { useNotify } from '@/hooks'
import useStyles from './Style.styles'

export const DeletePromptsModal = ({ prompt, refetch }: { prompt: any; refetch: any }) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const [open, setOpen] = useState(false)

  const { classes } = useStyles()

  const [promptsDelete] = usePromptsDeleteMutation()

  const modalLabelType = () => {
    switch (prompt?.type?.id) {
      case 1:
        return 'Query'
      case 2:
        return 'Clause'
      case 3:
        return 'Main summary'
      case 4:
        return 'Main question'
      default:
        return ''
    }
  }
  const handleDelete = async () => {
    try {
      await promptsDelete({
        ...prompt,
      })

      showNotification({
        type: 'success',
        message: t('prompts_deleted_success'),
      })
    } catch (e) {
      console.log(e)
    } finally {
      if (refetch) refetch()
      setOpen(false)
    }
  }

  return (
    <div>
      <Button variant={'outline'} onClick={() => setOpen(true)}>
        <Icon name={'trash'} />
      </Button>
      <Modal
        title={`${t('delete')} ${modalLabelType()}`}
        opened={open}
        onClose={() => setOpen(false)}
      >
        <p>
          {t('approve_delete_text')} <b>{prompt?.text}</b> ?
        </p>
        <Button onClick={handleDelete}>{t('Delete')}</Button>
      </Modal>
    </div>
  )
}

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Group } from '@mantine/core'
import { GeneralActionMenu } from '@/features/users/components/UserActionControls'
import {
  useUsersData,
  useUsersDataQueryMutations,
  useUsersModel,
  useUsersPermissions,
} from '@/features/users/hooks'
import { PATH, PATH_ADMIN, PATH_ADMIN_USERS, PATH_ADMIN_USERS_EDIT } from '@/routes/consts'

export const ActionBar = () => {
  const { user } = useUsersData({})
  const { isDeleted, isAdmin } = useUsersModel()
  const { isEditable } = useUsersPermissions()

  const menu = !isAdmin(user)

  const config = {
    menu,
  }

  const menuConfig = {
    remove: !isDeleted(user),
    restore: isDeleted(user),
    active: isEditable(user),
    edit: isEditable(user),
  }

  const { restore, active, remove } = useUsersDataQueryMutations()

  const getActionLoading = (data: { isLoading: boolean }) => data?.isLoading

  const loading = useMemo(() => {
    return getActionLoading(active) || getActionLoading(remove) || getActionLoading(restore)
  }, [getActionLoading(active), getActionLoading(remove), getActionLoading(restore)])

  const onActiveUpdate = async (params: { active: boolean }) => {
    await active.onActiveUpdate(params)
  }

  const onRemove = async () => {
    await remove.onRemove()
  }

  const onRestore = async () => {
    await restore.onRestore()
  }

  return (
    <Group position={'right'}>
      {config.menu && (
        <GeneralActionMenu
          data={user}
          actionIconProps={{ variant: 'default', loading }}
          menuProps={{ withinPortal: true }}
          editProps={{
            component: Link,
            to: `/${PATH.ADMIN}/${PATH_ADMIN.USERS}/${user?.id}/${PATH_ADMIN_USERS.EDIT}/${PATH_ADMIN_USERS_EDIT.PROFILE}`,
          }}
          removeProps={{ onRemove }}
          activeProps={{ onActiveUpdate }}
          restoreProps={{ onRestore }}
          config={menuConfig}
        />
      )}
    </Group>
  )
}

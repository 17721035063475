import React from 'react'
import { useTranslation } from 'react-i18next'
import { LuPartyPopper } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import { Anchor, MegaTagTitle } from '@/components/Elements'
import styles from './Thanks.module.scss'

export const Thank = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'Thank You!'} />
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <LuPartyPopper size={90} />
        </div>
        <div className={styles.title}>Thank You!</div>
        <p className={styles.text}>
          A confirmation email has been sent to your email address, from there you will be able to
          login to your account.
        </p>
        <p className={styles.subtext}>
          Please check your spam / junk folder if you can’t see it, and if you’re still having
          issues{' '}
          <Anchor component={Link} to={`/forgot-password`} className={'font-bold'}>
            click here
          </Anchor>{' '}
          to resend.
        </p>
      </div>
    </>
  )
}

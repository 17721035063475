import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Flex, SimpleGrid, Title } from '@mantine/core'
import {
  ButtonLink,
  GeneralCard as ContentCard,
  DataShower,
  NoDataTextMessage,
} from '@/components/Elements'
import { TariffsPeriodSwitch } from '@/features/billing/components/Elements'
import { GeneralCard } from '@/features/billing/components/Tariff/Tariffs/Cards'
import {
  useTariffStaticOptions,
  useTariffsList,
  useTariffsPeriodGrid,
} from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import { PATH } from '@/routes/consts'
import useStyles from './Component.styles'

export const SubscriptionsContainer = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { list, fetched, loading, error, onFetch, onClean } = useTariffsList({
    options: { skip: false },
  })

  useEffect(() => {
    onFetch()

    return () => {
      onClean()
    }
  }, [])

  const { period, onPeriodChange, data: tariffs } = useTariffsPeriodGrid({ tariffs: list })

  const renderTariffAction = (tariff: TariffModel) => (
    <ButtonLink fullWidth to={`/${PATH.REGISTER}`}>
      {t('get_subscription')}
    </ButtonLink>
  )

  return (
    <div className={classes.section}>
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Container py={20} size={920}>
            <ContentCard>
              <Title mb={'md'} align={'center'}>
                {t('subscription_plans')}
              </Title>

              <DataShower isLoading={loading} isFetched={fetched} isFailed={!!error} error={error}>
                <Flex direction="column" align="center">
                  <Box mb={'xl'}>
                    <TariffsPeriodSwitch value={period} onChange={onPeriodChange} />
                  </Box>

                  {tariffs.length > 0 ? (
                    <SimpleGrid
                      className={'w-full'}
                      cols={tariffs.length}
                      spacing={120}
                      breakpoints={[
                        { maxWidth: 900, cols: tariffs.length, spacing: 40 },
                        { maxWidth: 860, cols: 1, verticalSpacing: 10 },
                      ]}
                    >
                      {tariffs.map((item) => (
                        <React.Fragment key={item.id}>
                          <GeneralCard data={item} actionSections={renderTariffAction(item)} />
                        </React.Fragment>
                      ))}
                    </SimpleGrid>
                  ) : (
                    <NoDataTextMessage variant={'data'} />
                  )}
                </Flex>
              </DataShower>
            </ContentCard>
          </Container>
        </div>
      </Container>
    </div>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { GENDER } from '@/consts/features'
import { staticOptionsHelper } from '@/helpers/staticOptions'

export const useStaticOptions = () => {
  const { t } = useTranslation()

  const getGenders = () => {
    return staticOptionsHelper.gender.getGenders()
  }

  const getGender = (value: GENDER) => {
    return staticOptionsHelper.gender.getGender(value)
  }

  return {
    getGenders,
    getGender,
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mantine/core'
import { GeneralFormSection } from '@/features/profile/components/FormSections'

interface IProps {
  simplified?: boolean
}

export const GeneralSection = ({ simplified }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={'md'}>
        <GeneralFormSection simplified={simplified} emailProps={{ readOnly: true }} />

        <Divider />
      </Box>
    </>
  )
}

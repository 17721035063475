import { debounce } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Input, Tabs } from '@mantine/core'
import { MetaPagination, QueryDataShell } from '@/components/Elements'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { useGeneralPromptsQueryParams } from '@/features/prompts/hooks'
import { usePromptsEntriesQuery } from '@/features/prompts/store'
import { CreatePromptsModal } from './CreatePromptModal'
import { PromptsTable } from './PromptsTable'
import useStyles from './Style.styles'

export type PromptTypes = '1' | '2' | '3' | '4' | '5' | '6'

export const GeneralPromptsContainer = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { page, onPageChange, query, setQuery } = useGeneralPromptsQueryParams()

  const [type, setType] = useState<PromptTypes | string | null>('1')

  const [localSearch, setLocalSearch] = useState(query.search || '')

  const queryParams = useMemo(() => {
    return {
      type: type,
      page: page || 1,
      search: query.search,
    }
  }, [page, query.search, type])

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } =
    usePromptsEntriesQuery({
      params: queryParams,
    })

  const debouncedSearch = debounce(async (searchValue) => {
    setQuery({ page, search: searchValue })
  }, 300)

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalSearch(e.target.value)
    debouncedSearch(e.target.value)
  }

  const list = data?.data || []
  const meta = data?.meta

  const handleChangeTab = (value: any) => {
    setType(value)
    onPageChange(1)
  }

  const cantCreateNewTypes = ['3', '4', '5', '6']

  return (
    <GeneralPanelPage
      header={
        <>
          <GeneralTopBar title={t('prompts')} />
        </>
      }
    >
      <QueryDataShell
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        isError={isError}
        isSuccess={isSuccess || !!list.length}
      >
        <>
          <Tabs value={type} onTabChange={handleChangeTab}>
            <Box mb={'lg'}>
              <Tabs.List>
                <Tabs.Tab value="1">{t('admin_query_tab')}</Tabs.Tab>
                <Tabs.Tab value="2">{t('admin_clause_tab')}</Tabs.Tab>
                <Tabs.Tab value="3">{t('admin_summary_tab')}</Tabs.Tab>
                <Tabs.Tab value="4">{t('admin_main_tab')}</Tabs.Tab>
                <Tabs.Tab value="5">{t('admin_summarization_prompt_tab')}</Tabs.Tab>
                <Tabs.Tab value="6">{t('admin_overview_prompt_tab')}</Tabs.Tab>
              </Tabs.List>
            </Box>

            <Tabs.Panel value="1">
              <PromptsTable
                keys={['name', 'text', 'created_at', 'action']}
                items={list}
                refetch={refetch}
                canDelete
              />

              <MetaPagination
                meta={meta?.pagination}
                page={page}
                onChange={onPageChange}
                className={'mt-4 mb-4'}
              />
            </Tabs.Panel>

            <Tabs.Panel value="2">
              <PromptsTable
                keys={['name', 'text', 'created_at', 'action']}
                items={list}
                refetch={refetch}
                canDelete
              />

              <MetaPagination
                meta={meta?.pagination}
                page={page}
                onChange={onPageChange}
                className={'mt-4 mb-4'}
              />
            </Tabs.Panel>

            <Tabs.Panel value="3">
              <PromptsTable
                keys={['name', 'text', 'created_at', 'action']}
                items={list}
                refetch={refetch}
              />
              <MetaPagination
                meta={meta?.pagination}
                page={page}
                onChange={onPageChange}
                className={'mt-4 mb-4'}
              />
            </Tabs.Panel>

            <Tabs.Panel value="4">
              <PromptsTable
                keys={['name', 'text', 'created_at', 'action']}
                items={list}
                refetch={refetch}
              />
              <MetaPagination
                meta={meta?.pagination}
                page={page}
                onChange={onPageChange}
                className={'mt-4 mb-4'}
              />
            </Tabs.Panel>

            <Tabs.Panel value="5">
              <PromptsTable
                keys={['name', 'text', 'created_at', 'action']}
                items={list}
                refetch={refetch}
              />
              <MetaPagination
                meta={meta?.pagination}
                page={page}
                onChange={onPageChange}
                className={'mt-4 mb-4'}
              />
            </Tabs.Panel>

            <Tabs.Panel value="6">
              <PromptsTable
                keys={['name', 'text', 'created_at', 'action']}
                items={list}
                refetch={refetch}
              />
              <MetaPagination
                meta={meta?.pagination}
                page={page}
                onChange={onPageChange}
                className={'mt-4 mb-4'}
              />
            </Tabs.Panel>
          </Tabs>
          {type && !cantCreateNewTypes.includes(type) ? (
            <Box mt={'lg'}>
              <CreatePromptsModal count={list.length} type={type} refetch={refetch} />
            </Box>
          ) : (
            <>
              {list.length === 0 && (
                <Box mt={'lg'}>
                  <CreatePromptsModal count={list.length} type={type} refetch={refetch} />
                </Box>
              )}
            </>
          )}
        </>
      </QueryDataShell>
    </GeneralPanelPage>
  )
}

import React from 'react'
import { AuthLayout } from '@/features/auth/components/Layouts'
import {
  EmailVerification,
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
  Thank,
} from '@/features/auth/routes'
import { LandingRoot } from '@/features/landing/routes'
import { AuthGuard } from '@/routes/components'
import { PATH } from '@/routes/consts'
import { lazyImport } from '@/utils/lazyImport'

const { AdminAuthRoot } = lazyImport(() => import('@/features/admin-auth/routes'), 'AdminAuthRoot')

export const getAuthRoutes = (isLoggedIn: boolean) => {
  return [
    {
      element: <AuthGuard isLoggedIn={isLoggedIn} />,
      children: [
        {
          index: !isLoggedIn,
          path: '/*',
          element: <LandingRoot />,
        },
        {
          element: <AuthLayout />,
          children: [
            {
              path: PATH.LOGIN,
              element: <Login />,
            },
            {
              path: PATH.THANK_PAGE,
              element: <Thank />,
            },
            {
              path: PATH.REGISTER,
              element: <Register />,
            },
            {
              path: PATH.EMAIL_VERIFICATION,
              element: <EmailVerification />,
            },
            {
              path: PATH.FORGOT_PASSWORD,
              element: <ForgotPassword />,
            },
            {
              path: PATH.RESET_PASSWORD,
              element: <ResetPassword />,
            },
          ],
        },
        {
          path: `${PATH.ULTRA_ADMIN}/*`,
          element: (
            <React.Suspense fallback={''}>
              <AdminAuthRoot />
            </React.Suspense>
          ),
        },
      ],
    },
  ]
}

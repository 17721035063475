import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentSection, MetaPagination, OverlayDataShower } from '@/components/Elements'
import { GeneralInvoicesTable } from '@/features/billing/components/Invoice/Invoices/Tables'
import { useGeneralInvoicesQueryParam } from '@/features/billing/hooks'
import { useBillingInvoiceListQuery } from '@/features/billing/store'

export const GeneralInvoicesContainer = () => {
  const { t } = useTranslation()

  const [syncCount, forceUpdate] = React.useReducer((x: number) => x + 1, 0)

  const { page, onPageChange } = useGeneralInvoicesQueryParam()

  const queryParams = useMemo(() => {
    return {
      page: page || 1,
    }
  }, [page, syncCount])

  const { data, isLoading, isFetching, error, isError } = useBillingInvoiceListQuery({
    params: queryParams,
  })

  const list = data?.data || []
  const meta = data?.meta

  const loading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  return (
    <ContentSection title={t('invoices')}>
      <OverlayDataShower isLoading={loading} isFailed={isError} error={error}>
        <GeneralInvoicesTable keys={['number', 'date', 'amount']} items={list} loading={loading} />

        <MetaPagination meta={meta} page={page} onChange={onPageChange} className={'mt-4 mb-4'} />
      </OverlayDataShower>
    </ContentSection>
  )
}

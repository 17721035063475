import React from 'react'
import { Group } from '@mantine/core'
import { ActionBar } from './ActionBar'
import { InfoBlock } from './InfoBlock'

export const GeneralSection = () => {
  return (
    <Group align="start">
      <InfoBlock />
      <ActionBar />
    </Group>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Flex, Grid, Footer as MantineFooter } from '@mantine/core'
import { Anchor, LogoIcon } from '@/components/Elements'
import { APP_NAME } from '@/consts/core'
import useStyles from './Footer.styles'

export const Footer = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <>
      <MantineFooter className={classes.root} height={'100%'} zIndex={1}>
        <Container className={classes.wrap}>
          <Grid py={'xl'}>
            <Grid.Col md={4} order={2} orderMd={1}>
              <Flex align={'flex-start'} direction="column" gap={'md'}>
                <Anchor component={Link} to={'/'}>
                  <LogoIcon size={'sm'} />
                </Anchor>

                <div className={'flex-nowrap'}>
                  © {new Date().getFullYear()} {APP_NAME}. {t('all_rights_reserved')}
                </div>
              </Flex>
            </Grid.Col>
          </Grid>
        </Container>
      </MantineFooter>
    </>
  )
}

export const FILE_MAX_SIZE = 2097152

export const PASSWORD_MIN_LENGTH = 8

export const CHAT_FILE_MAX_SIZE = 15728640

export const PHONE_DIGITS_LENGTH = {
  min: 9,
  max: 15,
}

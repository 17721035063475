import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, SimpleGrid, Text } from '@mantine/core'
import { ContentSection } from '@/components/Elements'
import { useBilling, useTariffStaticOptions } from '@/features/billing/hooks'
import { PaymentInfoType } from '@/features/billing/types/shared'
import { CommonUtils } from '@/utils'

interface IProps {
  data: PaymentInfoType
}

export const PaymentInfo = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { tariff, price } = data ?? {}

  const { isAnnual } = useTariffStaticOptions()

  const { getCurrency } = useBilling()

  const items = [
    {
      label: t('subscription_plan'),
      value: tariff?.name,
      hidden: !tariff,
    },
    {
      label: t('billed_period'),
      value: tariff?.period ? (isAnnual(tariff?.period) ? t('annually') : t('monthly')) : '',
      hidden: !tariff,
    },
    {
      label: t('price'),
      value: (
        <>
          {price} {getCurrency()?.symbol}
        </>
      ),
      hidden: CommonUtils.isNil(price),
    },
  ]

  return (
    <>
      <ContentSection title={t('payment_information')}>
        <SimpleGrid verticalSpacing={'xs'}>
          {items
            .filter((item) => !item.hidden)
            .map(({ label, value }, index) => (
              <div key={index}>
                <Box pb={'sm'} className={'flex grow gap-4 flex-wrap'}>
                  <div className={'flex grow flex-col justify-center'}>
                    <Text fw={700}>{label}</Text>
                    <Text fz={'sm'}>{value}</Text>
                  </div>
                </Box>

                <Divider />
              </div>
            ))}
        </SimpleGrid>
      </ContentSection>
    </>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Text } from '@mantine/core'
import { TableView } from '@/components/Elements'
import { DefaultPaymentMethodBadge } from '@/features/billing/components/Elements'
import { BILLING_PAYMENT_METHOD_TYPE } from '@/features/billing/consts/payment-method'
import { usePaymentMethodStaticOptions } from '@/features/billing/hooks'
import { PaymentMethodModel } from '@/features/billing/types'
import { PaymentUtils } from '@/utils'
import { Action } from './Action'
import { CardNumberCell, IbanCell } from './Cells'

interface IProps {
  items?: PaymentMethodModel[]
  loading: boolean
  actionProps?: any
  defaultControlProps?: any
  removeControlProps?: any
  type: BILLING_PAYMENT_METHOD_TYPE
}

export const GeneralPaymentMethodsTable = ({
  items = [],
  loading,
  actionProps,
  type,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { isSepaType, isCardType } = usePaymentMethodStaticOptions()

  const isCard = useMemo(() => {
    return isCardType(type)
  }, [type])

  const isSepa = useMemo(() => {
    return isSepaType(type)
  }, [type])

  const columns = [
    {
      accessorKey: 'iban',
      id: 'iban',
      header: t('iban'),
      cell: (info: any) => {
        const { original } = info.row

        return (
          <Group noWrap>
            <IbanCell data={original} />

            {original.default && <DefaultPaymentMethodBadge />}
          </Group>
        )
      },
    },
    {
      accessorKey: 'card_number',
      id: 'card_number',
      header: t('card_number'),
      cell: (info: any) => {
        const { original } = info.row

        return (
          <>
            <CardNumberCell data={original} />

            {original.default && <DefaultPaymentMethodBadge ml={'md'} />}
          </>
        )
      },
    },
    {
      accessorKey: 'expiry_date',
      id: 'expiry_date',
      header: t('expiry_date'),
      cell: (info: any) => {
        const { original } = info.row
        return PaymentUtils.paymentCardFormatExpiryDate(original.exp_month, original.exp_year)
      },
    },
    {
      accessorKey: 'brand',
      id: 'brand',
      header: t('payment_system'),
      cell: (info: any) => <Text tt="capitalize">{info.getValue()}</Text>,
    },
    {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: (info: any) => (
        <div className={'flex justify-end'}>
          <Action
            {...actionProps}
            data={info.row.original}
            defaultControlProps={props.defaultControlProps}
            removeControlProps={props.removeControlProps}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <TableView
        data={items}
        columns={columns}
        loading={loading}
        state={{
          columnVisibility: {
            iban: isSepa,
            card_number: isCard,
            expiry_date: isCard,
            brand: isCard,
          },
        }}
      />
    </>
  )
}

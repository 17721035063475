import React from 'react'
import { BillingRoot } from '@/features/billing/routes'
import { Dashboard, DocumentView } from '@/features/dashboard/routes'
import { SettingsRoot } from '@/features/settings/routes'
import { ROLE } from '@/features/users/types'
import { PATH } from '@/routes/consts'
import { lazyImport } from '@/utils/lazyImport'
import { GUARD } from '../../guards'

const { AdminRoot } = lazyImport(() => import('@/features/admin/routes'), 'AdminRoot')

const { CUSTOMER, ADMIN } = ROLE

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: `${PATH.DASHBOARD}`,
    element: <Dashboard />,
    roles: [CUSTOMER],
    guards: [],
  },
  {
    path: `${PATH.DOCUMENT_VIEW}`,
    element: <DocumentView />,
    roles: [CUSTOMER],
    guards: [],
  },
  {
    path: `${PATH.SETTINGS}/*`,
    element: <SettingsRoot />,
    roles: [CUSTOMER, ADMIN],
    guards: [],
  },
  {
    path: `${PATH.BILLING}/*`,
    element: <BillingRoot />,
    roles: [CUSTOMER],
    guards: [],
  },
  {
    path: `${PATH.ADMIN}/*`,
    element: (
      <React.Suspense fallback={''}>
        <AdminRoot />
      </React.Suspense>
    ),
    roles: [ADMIN],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return [...generalRoutes]
}

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Group, Input, Tabs } from '@mantine/core'
import { MetaPagination, QueryDataShell } from '@/components/Elements'
import { useTariffsList } from '@/features/billing/hooks'
import { useLimitsAdminEntriesQuery, useLimitsUpdateMutation } from '@/features/limits/store'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { useNotify } from '@/hooks'
import useStyles from './Style.styles'

export const GeneralLimitsContainer = () => {
  const { showNotification } = useNotify()

  const [limitsUpdate] = useLimitsUpdateMutation()

  const [limits, setLimits] = useState({
    number_month_requests: 0,
    number_month_documents: 0,
    number_gpt_tokens: 0,
  })
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const { classes } = useStyles()

  const [type, setType] = useState<number | null>(null)

  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } =
    useLimitsAdminEntriesQuery({})

  const handleChangeTab = (value: any) => {
    setType(value)
  }

  const handleMonthRequests = (value: any) => {
    setLimits({
      ...limits,
      number_month_requests: value?.target?.value,
    })
  }

  const handleMonthDocuments = (value: any) => {
    setLimits({
      ...limits,
      number_month_documents: value?.target?.value,
    })
  }

  const handleMonthTokens = (value: any) => {
    setLimits({
      ...limits,
      number_gpt_tokens: value?.target?.value,
    })
  }

  useEffect(() => {
    if (!data?.data?.length) return

    const findLimit = data.data.find((item: any) => Number(item?.id) === Number(type))

    if (findLimit) {
      setLimits({
        number_month_requests: findLimit?.number_month_requests || 0,
        number_month_documents: findLimit?.number_month_documents || 0,
        number_gpt_tokens: findLimit?.number_gpt_tokens || 0,
      })
    }

    if (type === null && data?.data?.[0]?.id !== null) {
      setType(data?.data?.[0]?.id)
    }
  }, [data, type])

  const handleUpdate = async () => {
    try {
      if (type === null) return
      setLoading(true)

      const data = {
        ...limits,
        id: Number(type),
      }

      await limitsUpdate(data)

      showNotification({
        type: 'success',
        message: t('limits_updated_success'),
      })
    } catch (e) {
      console.log(e)
    } finally {
      if (refetch) refetch()
      setLoading(false)
    }
  }

  return (
    <GeneralPanelPage
      header={
        <>
          <GeneralTopBar title={t('limits')} />
        </>
      }
    >
      {type && (
        <Tabs value={`${type}`} onTabChange={handleChangeTab}>
          <Box mb={'lg'}>
            <Tabs.List>
              {data?.data?.map((item: any) => (
                <Tabs.Tab key={item?.id} disabled={loading} value={`${item?.id}`}>
                  {item?.name} {t('limits')}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Box>
          {data?.data?.map((item: any) => (
            <Tabs.Panel key={item?.id} value={`${item?.id}`}>
              <Input.Wrapper label={t('number_month_requests')}>
                <Input
                  disabled={loading}
                  type="number"
                  name={'number_month_requests'}
                  id={'number_month_requests'}
                  placeholder={t('number_month_requests')}
                  mb={'md'}
                  onChange={handleMonthRequests}
                  value={limits.number_month_requests}
                />
              </Input.Wrapper>

              <Input.Wrapper label={t('number_month_documents')}>
                <Input
                  disabled={loading}
                  type="number"
                  name={'number_month_documents'}
                  id={'number_month_documents'}
                  placeholder={t('number_month_documents')}
                  mb={'md'}
                  onChange={handleMonthDocuments}
                  value={limits.number_month_documents}
                />
              </Input.Wrapper>

              <Input.Wrapper label={t('number_gpt_tokens')}>
                <Input
                  disabled={loading}
                  type="number"
                  name={'number_gpt_tokens'}
                  id={'number_gpt_tokens'}
                  placeholder={t('number_gpt_tokens')}
                  mb={'md'}
                  onChange={handleMonthTokens}
                  value={limits.number_gpt_tokens}
                />
              </Input.Wrapper>
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
      <Group mt={'xl'}>
        <Button disabled={loading} onClick={handleUpdate} fullWidth={false}>
          {t('save')}
        </Button>
      </Group>
    </GeneralPanelPage>
  )
}

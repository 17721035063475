import React from 'react'
import { AppModalsManager } from '@/features/app/components/Modal'

type Props = {
  children: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  return <AppModalsManager>{children}</AppModalsManager>
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Burger, MediaQuery } from '@mantine/core'
import { GeneralHeader } from '@/features/panel/components/Layouts/Headers'

interface IProps {
  onDrawerOpen: () => void
}

export const Header = ({ onDrawerOpen, ...props }: IProps) => {
  const { t } = useTranslation()

  return (
    <GeneralHeader
      leftSection={
        <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
          <Burger opened={false} onClick={() => onDrawerOpen()} size="sm" mr={'lg'} />
        </MediaQuery>
      }
      profileMenuProps={{ config: { billing: true } }}
    />
  )
}

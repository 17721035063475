import React from 'react'
import { Outlet } from 'react-router-dom'
import { PublicLayoutPage } from '@/components/Layouts'

export const LegalShell = () => {
  return (
    <PublicLayoutPage>
      <Outlet />
    </PublicLayoutPage>
  )
}
